import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { clr_byStageGet, clr_byUnitGet } from "../../actions";
import { bindActionCreators } from "redux";
import _ from "lodash";
import {
  Header,
  Grid,
  Divider,
  Loader,
  Breadcrumb,
  Container,
  Table
} from "semantic-ui-react";

class Clr_byStageGet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unitInfo: [],
      errors: {},
      isLoading: true,
      hasErrored: false,
      active: 0
    };
  }

  componentDidMount() {
    this.props
      .clr_byStageGet(
        this.props.match.params.stage,
        this.props.match.params.blockId
      )
      .then(
        res => {
          // console.log(res);
          this.setState.isLoading = false;
        },
        err => this.setState({ errors: err.response, hasErrored: true })
      );
  }

  byUnitLink(unit) {
    this.props.history.push(
      `/reports/checklistRejected/${unit.checklist_id}/${unit.block_id}/${
        unit.checkpoint
      }`
    );
  }

  render() {
    const {
      unitInfo,
      isLoading,
      hasErrored,
      errors,
      propertySelected
    } = this.props;
    const paths = this.props.location.pathname.split("/").map((p, i, arr) => {
      if (p === "ChecklistRejected") {
        p = "Rejected Checklist";
      }
      if (i === 0)
        return {
          key: i,
          content: <p>{propertySelected.name}</p>,
          active: i === arr.length - 1,
          link: i < arr.length - 1
        };

      if (i === arr.length - 1)
        return {
          key: i,
          content: p,
          active: i === arr.length - 1
        };
      return {
        key: i,
        content: (
          <Link to={`${arr.slice(0, i + 1).join("/")}`}>
            {p.charAt(0).toUpperCase() + p.slice(1)}
          </Link>
        ),
        active: i === arr.length - 1,
        link: i < arr.length - 1
      };
    });

    if (isLoading) {
      return (
        <Container textAlign="center">
          <Loader active indeterminate className="centered">
            Getting activity info...
          </Loader>
        </Container>
      );
    }

    if (_.isEmpty(unitInfo) && !isLoading) {
      return (
        <Container fluid textAlign="center">
          <p>No Block/s found</p>
        </Container>
      );
    }

    if (hasErrored) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again
            {errors}
          </div>
        </div>
      );
    }

    return (
      <div>
        <Breadcrumb icon="chevron right" sections={paths} size="big" />
        <Header as="h2">Rejected Checkpoints</Header>
        <Divider />
        <Grid columns="equal" padded>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Checkpoint</Table.HeaderCell>
                <Table.HeaderCell>No of units</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {unitInfo.map(function(unit, index) {
                return (
                  <Table.Row key={index}>
                    <Table.Cell>{unit.checkpoint}</Table.Cell>
                    <Table.Cell>
                      <p
                        className="asLink"
                        as="a"
                        type="submit"
                        size="tiny"
                        onClick={() => this.byUnitLink(unit)}
                      >
                        {unit.count}
                      </p>
                    </Table.Cell>
                  </Table.Row>
                );
              }, this)}
            </Table.Body>
          </Table>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  unitInfo: state.unitInfo.unitInfo,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
  propertySelected: state.property.propertySelected
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clr_byStageGet,
      clr_byUnitGet
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Clr_byStageGet)
);
