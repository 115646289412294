/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import SelectionBox from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import CheckBox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import _ from "lodash";
import DoneAll from "@material-ui/icons/DoneAll";
import styles from "./multiSelectionBox.module.css";
import Icon from "../portal-widgets-core/Icon";
// import sub-components
import Sort from "./sort/Sort";
import Filter from "./filter/Filter";
import Apply from "./apply/Apply";
import Search from "./search/Search";
import DatePicker from "./datePicker/DatePicker";

export default function({
  main,
  apply = null,
  search = null,
  filter = null,
  sort = null,
  datePicker = null
}) {
  return {
    [apply]: Apply,
    [search]: Search,
    [sort]: Sort,
    [filter]: Filter,
    [datePicker]: DatePicker,
    [main]: class extends Component {
      state = {
        selected: this.props.value || [],
        filterFn: () => true,
        open: false
        // filterFlag: '',
      };

      constructor(props) {
        super(props);
        this.searchRef = React.createRef();
        // this.check = this.check.bind(this);
      }

      componentDidUpdate(prevProps, prevState) {
        if (!prevProps.reset && this.props.reset) this.reset();
      }
      componentDidUpdate(prevProps, prevState) {
        if (!prevProps.reset && this.props.reset) this.reset();
      }

      static getDerivedStateFromProps(nextProps, prevState) {
        if (!!nextProps.value && !prevState.open)
          return { ...prevState, selected: nextProps.value };
        return prevState;
      }

      handleSelectionChange = event => {
        if (!!this.props.callback && !apply)
          this.props.callback(event.target.value);
        this.setState({ selected: event.target.value });
      };

      handleSearchFilter = event => {
        event.stopPropagation();
        this.setState({
          filterFn: opt =>
            this.searchRef.current
              ? !!opt[this.props.optionsName].match(
                  new RegExp(
                    this.searchRef.current.value
                      .toLowerCase()
                      .replace(/\\/g, "\\\\"),
                    "gi"
                  )
                )
              : true
        });
      };

      handleSearchClear = event => {
        event.stopPropagation();
        this.searchRef.current.value = "";
        this.handleSearchFilter(event);
      };

      toggleDropdown = event => {
        event.stopPropagation();
        const { open } = this.state;
        if (!event.target.value) this.setState({ open: !open });
      };

      reset = () =>
        this.setState({
          selected: [],
          filterFn: () => true,
          open: false
        });

      componentFilter = (childrenArr, ...filterVals) =>
        filterVals.reduce(
          (acc, filterVal) => [
            ...acc,
            childrenArr.filter(
              child => !!child.props.type && child.props.type === filterVal
            ).length > 0
              ? React.cloneElement(
                  ...childrenArr.filter(
                    child => child.props.type === filterVal
                  ),
                  {
                    style: {
                      color: "#2f3e6c",
                      backgroundColor: "inherit",
                      height: "inherit"
                    },
                    handlers: this.handlers,
                    selection: this.state.selected,
                    sortValue: this.props.sortValue
                  }
                )
              : null
          ],
          []
        );

      handlers = {
        filterChange: e => {
          e.stopPropagation();
          const { optionsId } = this.props;
          switch (e.target.value) {
            case "filterAll":
              const toSelect = React.Children.toArray(
                this.props.children
              ).reduce(
                (acc, child) =>
                  child.type === "li" && !child.props.children.disabled
                    ? [...acc, child.props.children[optionsId]]
                    : acc,
                []
              );
              this.setState({
                selected: toSelect
              });
              break;
            case "filterBy":
              this.setState({ selected: [] });
              break;
            default:
          }
        },
        sortChange: e => {
          e.stopPropagation();
          this.props.sortCallBack(e.target.value);
        },
        dateCallBack: dates => {
          this.setState({ selected: dates });
        },
        searchChange: this.handleSearchFilter,
        searchClear: this.handleSearchClear,
        applyChange: () => {
          this.props.callback(this.state.selected);
          if (this.props.value) this.setState({ selected: [] });
        }
      };

      render() {
        const {
          labelText,
          id,
          value,
          multiple,
          optionsId,
          optionsName,
          required,
          onOpen,
          onClose,
          name,
          callback,
          placeholder,
          loading
        } = this.props;
        // SelectDisplayProps,
        // error,
        const { open } = this.state;
        const selected = !!value && !apply ? value : this.state.selected;
        const childrenArray = React.Children.toArray(this.props.children);
        const [
          Sort,
          Filter,
          Search,
          Apply,
          DatePicker
        ] = this.componentFilter.apply(this, [
          childrenArray,
          "Sort",
          "Filter",
          "Search",
          "Apply",
          "DatePicker"
        ]);
        return (
          <FormControl fullWidth required={this.props.required}>
            {labelText ? (
              <InputLabel
                htmlFor="select-multiple-checkbox"
                className={styles.boxHeader}
              >
                {labelText}
              </InputLabel>
            ) : null}
            <SelectionBox
              multiple={multiple === undefined ? true : multiple}
              className={styles.overrideSelectionBox}
              value={selected}
              onClick={this.toggleDropdown}
              open={open}
              name={name || "Selection"}
              onChange={this.handleSelectionChange}
              onOpen={onOpen || (() => {})}
              onClose={onClose || (() => {})}
              input={<Input id="select-multiple-checkbox" />}
              renderValue={sel => <DoneAll className={styles.selectedFilter} />}
              /* renderValue={sel =>
                DatePicker
                  ? `${selected[0].from.toString()}`
                  : childrenArray
                  .filter(child => child.type === 'li' && sel.includes(child.props.children[optionsId]))
                  .map(thisChild => thisChild.props.children[optionsName])
                  .map(tag => `${tag.toString().substring(0, 4)}...`)
                  .join(', ')
                  .substring(0, 15)
                  } */
              /* renderValue={ids => ids.join(', ')} */
              id={id}
            >
              <div className={styles.fixedheader}>
                {" "}
                {labelText ? (
                  <p
                    className={styles.labelItem}
                  >{`Select ${labelText[0].toUpperCase()}${labelText.slice(
                    1
                  )}`}</p>
                ) : null}
                <p className={styles.divider}>&nbsp;</p>
                {Sort || null}
                <p className={styles.divider}>&nbsp;</p>
                {multiple === undefined || !!multiple ? Filter || null : null}
                {DatePicker || null}
              </div>

              {loading ? (
                <Icon name="LOADING_SPINNER" />
              ) : (
                /* <div style={{ maxHeight: 250, overflow: 'auto', marginBottom: 30 }}> */
                [
                  Search
                    ? React.cloneElement(Search, {
                        name: "enter search term here",
                        disabled: loading,
                        refs: { search: this.searchRef }
                      })
                    : null,
                  childrenArray
                    .filter(child => child.type === "li")
                    .map(child => child.props.children)
                    // why was this done?
                    .sort((a, b) => {
                      if (!!a.disabled && !!!b.disabled) return 1;
                      else if (!!!a.disabled && !!b.disabled) return -1;
                      else
                        return (
                          !!a[optionsName] &&
                          a[optionsName].localeCompare(b[optionsName])
                        );
                    })
                    .filter(this.state.filterFn)
                    .map(opt => (
                      <MenuItem
                        disabled={opt.disabled}
                        key={opt[optionsId]}
                        value={opt[optionsId]}
                      >
                        <CheckBox
                          key={`check_${opt[optionsId]}`}
                          style={{ color: "#2f3e6c", "&$checked": "#2f3e6c" }}
                          checked={!!selected.includes(opt[optionsId])}
                        />
                        <ListItemText
                          disableTypography
                          style={{ color: "#2f3e6c" }}
                          key={`list_${opt[optionsId]}`}
                          primary={opt[optionsName]}
                        />
                      </MenuItem>
                    ))
                ]
              )
              /* </div>  */
              }

              {Apply ? React.cloneElement(Apply, { selected }) : null}
            </SelectionBox>
          </FormControl>
        );
      }
    }
  };
}
