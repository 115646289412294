import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./input.module.css";

class Input extends Component {
  render() {
    const {
      inputVal,
      onInputValChanged,
      placeholder,
      className,
      readonly,
      onKeyPress,
      type,
      name
    } = this.props;
    const inputFinalClass = className
      ? classnames(className, styles.inputClass)
      : styles.inputClass;
    const placeholderFinal = readonly ? "" : placeholder;
    return (
      <input
        placeholder={placeholderFinal}
        className={inputFinalClass}
        value={inputVal}
        onChange={onInputValChanged}
        readOnly={readonly}
        onKeyPress={onKeyPress}
        type={type}
        name={name}
      />
    );
  }
}

Input.propTypes = {
  inputVal: PropTypes.string,
  onInputValChanged: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  readonly: PropTypes.bool,
  onKeyPress: PropTypes.func,
  type: PropTypes.string
};

export default Input;
