import React, { Component } from "react";
import { connect } from "react-redux";
import "whatwg-fetch";
import { bindActionCreators } from "redux";
import { Container, Loader } from "semantic-ui-react";
import { models } from "powerbi-client";
import Embed from "../../utils/powerbi";
import { dashboardInfoGet } from "../../actions";

class PowerBi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportInfo: {},
      errors: {},
      isLoading: true,
      hasErrored: false,
      embedConfig: {}
    };
  }

  componentDidMount() {
    const requestData = {
      id: this.props.match.params.id
    };
    // let info = {};
    this.props.dashboardInfoGet(requestData).then(
      res => {
        // console.log(res);
        this.setState.isLoading = false;
      },
      err => this.setState({ errors: err.response })
    );
  }

  onEmbedded(embed) {
    console.log(`Report embedded: `, embed, this);
  }

  render() {
    const { isLoading, hasErrored, errors, dashboardInfo } = this.props;
    // let url = '';
    let embedConfig = {};
    let getUrlParameter = function getUrlParameter(sParam) {
      let sPageURL = decodeURIComponent(window.location.search.substring(1));
      let sURLVariables = sPageURL.split("&");
      let sParameterName;
      let i;

      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split("=");

        if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined ? true : sParameterName[1];
        }
      }
    };

    if (isLoading || Object.keys(dashboardInfo).length === 0) {
      return (
        <Container textAlign="center">
          <Loader active indeterminate className="centered">
            Generating Report...
          </Loader>
        </Container>
      );
    }
    if (hasErrored) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again
            {errors}
          </div>
        </div>
      );
    }
    const ReportStyle = {
      height: "650px;"
    };

    const tabName = getUrlParameter("tab_name");
    if (tabName) {
      embedConfig = {
        type: "report",
        tokenType: models.TokenType.Embed,
        accessToken: dashboardInfo.url.token,
        embedUrl: dashboardInfo.url.embedUrl,
        id: dashboardInfo.url.reportId,
        permissions: models.Permissions.All,
        pageName: tabName,
        settings: { filterPaneEnabled: false, navContentPaneEnabled: false }
      };
    } else {
      embedConfig = {
        type: "report",
        tokenType: models.TokenType.Embed,
        accessToken: dashboardInfo.url.token,
        embedUrl: dashboardInfo.url.embedUrl,
        id: dashboardInfo.url.reportId,
        permissions: models.Permissions.All,
        settings: { filterPaneEnabled: true, navContentPaneEnabled: true }
      };
    }

    return (
      <div className="col-md-12">
        <Embed style={ReportStyle} options={embedConfig} />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  profile: state.authManager.user,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
  dashboardInfo: state.dashboardInfo.dashboardInfo
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dashboardInfoGet
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PowerBi);
