import { Notify } from '@falconbrick/fbt-web-components';
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { notify, closeNotifcation } from "../../../actions/Notify";
import { bindActionCreators } from "redux";

export class NotifierContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  closeHandler = e => {
    this.props.dispatch(closeNotifcation());
  };
  render() {
    const { messageContent, className, open } = this.props;
    return (<Notify
      messageContent={messageContent}
      className={className}
      open={open}
      closeHandler={this.closeHandler}
    />);
  }
}
const mapStateToProps = state => {
  return {
    open: state.notify.open,
    className: state.notify.className,
    messageContent: state.notify.messageContent
  };
}
// const mapDispatchToProps = dispatch =>
//   bindActionCreators({ notify, closeNotification }, dispatch);

export default withRouter(connect(mapStateToProps, null)(NotifierContainer));
