import * as types from "../Constants/ActivityConstants";
import * as utils from "./utils";

const initialState = {
    loadingMsg: "Loading Activity Manager Logs",
    unitTypes: [],
    activeUnitType: "",
    gridSize: 6,
    totalPages: 1,
    currentPage: 1,
    grouping: null,
    units: [],
    loading: true,
    error: null,
    noResult: null,
    sortFunction: (a, b) => (a.localeCompare(b))
};

const activityManagerLog = (state = initialState, action = {}) => {
    let newState = {};
    switch (action.type) {
        case types.GET_ACTIVITY_INFO_FETCH: {
            newState["loading"] = true;
            newState["error"] = false;
            newState['units'] = []
            return Object.assign({}, state, newState);
        }

        case types.GET_ACTIVITY_INFO_ERROR: {
            newState["loading"] = false;
            newState["error"] = action.error.message;
            return Object.assign({}, state, newState);
        }
        case types.GET_ACTIVITY_LOG_SUCCESS: {
            newState = {}
            newState["loading"] = false;
            newState['units'] = action.units
            return Object.assign({}, state, newState);
        }
        default:
            return state;
    }
};

export default activityManagerLog;
