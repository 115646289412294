import React, { Component } from "react";
import { connect } from "react-redux";
import "whatwg-fetch";
import { stage2UpdateUnitDataDetail } from "../../../actions";
import lAndT from "../../../semanticUi/images/Lnt_realty_logo.png";
import { downloadPdf } from "../../../actions";
import { bindActionCreators } from "redux";
import Select from "react-super-select";
import _ from "lodash";

import {
  Icon,
  Table,
  Grid,
  Divider,
  Image,
  Loader,
  Container,
  List,
  Form,
  Label
} from "semantic-ui-react";

class UnitInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unitInfoDetail: [],
      errors: {},
      isLoading: true,
      hasErrored: false
    };
    this.updateUnitDataDetail = this.updateUnitDataDetail.bind(this);
  }

  updateUnitDataDetail = function (options) {
    localStorage.setItem("stage2_unitSelectedId", options.id);
    localStorage.setItem("stage2_unitSelectedName", options.title);
    this.props.stage2UpdateUnitDataDetail(options.id).then(
      res => {
        // console.log(res);
        this.setState.isLoading = false;
        this.setState.hasErrored = false;
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
  };

  downloadPdf(pageURL, authInfo) {
    const requestData = {
      pageURL,
      authInfo
    };
    const downloadLink = document.getElementById("pdf");
    downloadLink.className = "ui right floated loading disabled button medium";
    downloadLink.innerHTML = "Loading";
    this.props.downloadPdf(requestData).then(
      res => {
        const a = document.createElement("a");
        a.href = res.reXportPath;
        a.innerHTML = "Download pdf file";
        a.download = "true";
        a.className = "ui right floated button positive";
        document.body.appendChild(a);
        const downloadLink = document.getElementById("pdf");
        downloadLink.className = "ui right floated button positive";
        downloadLink.innerHTML = "";
        downloadLink.replaceWith(a);
      },
      err => this.setState({ errors: err.response })
    );
  }

  render() {
    const {
      unitInfoDetail,
      unitDataDetail,
      isLoading,
      hasErrored,
      errors
    } = this.props;
    // console.log(this.props);
    // let customer_id = profile.customer_id;
    // let pageURL = config.SiteUrl + this.props.location.pathname;
    // let authInfo = localStorage.getItem("jwtToken");
    let selectedBlock;
    if (localStorage.getItem("stage1_unitSelectedName")) {
      selectedBlock = localStorage.getItem("stage1_unitSelectedName");
    }

    if (isLoading) {
      return (
        <div>
          <Loader active indeterminate className="centered">
            Updating units...
          </Loader>
        </div>
      );
    }

    if (_.isEmpty(unitInfoDetail) && !isLoading) {
      return (
        <Container fluid textAlign="center">
          <Divider className="print-hide" />
          <p>No units found!</p>
          <Divider hidden section />
          <Divider hidden section />
          <Divider hidden section />
        </Container>
      );
    }

    if (hasErrored) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again
            {errors}
          </div>
        </div>
      );
    }

    let unit_title = "";
    if (_.isEmpty(unitDataDetail)) {
      if (
        localStorage.getItem("stage2_unitSelectedId") &&
        localStorage.getItem("stage2_unitSelectedId") !== "undefined"
      ) {
        const unitElements = this.props.unitInfoDetail;
        const units = [];
        for (let i = 0; i < unitElements.length; i++) {
          units[i] = unitElements[i].id;
        }
        unit_title =
          unitElements[
            units.indexOf(localStorage.getItem("stage2_unitSelectedId"))
          ].title;
      }
    }
    return (
      <Grid columns="equals">
        <Grid.Row>
          <Form className="dropDownForm print-hide">
            <Form.Field>
              <Label>Unit</Label>
              <Select
                placeholder={
                  selectedBlock || "Please Select A Unit"
                }
                dataSource={unitInfoDetail}
                onChange={this.updateUnitDataDetail}
                clearSearchOnSelection
                deselectOnSelectedOptionClick={false}
                searchable
                clearable={false}
                optionLabelKey="title"
              />
            </Form.Field>
          </Form>
        </Grid.Row>
        <List size="medium" className="printCard">
          <Grid>
            <Grid.Column textAlign="left" width={6}>
              <List.Item>
                <Image src={lAndT} size="medium" />
              </List.Item>
            </Grid.Column>
            <Grid.Column textAlign="left" width={10}>
              <List.Item>
                <h2 className="header">STAGE - CERTIFICATE</h2>
                <h3 className="header">STAGE - 2</h3>
              </List.Item>
            </Grid.Column>
            {/* <Grid.Column textAlign="left" width={4}> */}
            {/* <List.Item> */}
            {/* <p>Doc No : LTR/STAGE CERTIFICATE-2/34</p> */}
            {/* <p>Rev No : 0</p> */}
            {/* <p>Rev Date : 31/03/2016</p> */}
            {/* </List.Item> */}
            {/* </Grid.Column> */}
          </Grid>
          <Divider />
        </List>
        <List size="medium">
          <Grid>
            <Grid.Column textAlign="left" width={6}>
              <List.Item>
                <List.Item>
                  <h3>
                    <b>Floor/Slab/RL - {unit_title}</b>
                  </h3>
                </List.Item>
              </List.Item>
            </Grid.Column>
          </Grid>
          <Divider />
        </List>
        <Grid.Row fluid>
          {unitDataDetail.length ? (
            <Container fluid>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Sn.</Table.HeaderCell>
                    <Table.HeaderCell>Activities</Table.HeaderCell>
                    <Table.HeaderCell>YES</Table.HeaderCell>
                    <Table.HeaderCell>No</Table.HeaderCell>
                    <Table.HeaderCell>N/A</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {unitDataDetail.map((data, index) => (
                    <Table.Row>
                      <Table.Cell>
                        <p>{index + 1}</p>
                      </Table.Cell>
                      <Table.Cell>
                        <p>{data.inspection_item_title}</p>
                      </Table.Cell>
                      <Table.Cell>
                        {data.status === "CLOSE" ? (
                          <Icon name="checkmark" />
                          ) : (
                            <p />
                            )}
                      </Table.Cell>
                      <Table.Cell>
                        <p>
                          {data.status === "OPEN" ? (
                            <Icon name="checkmark" />
                            ) : (
                              <p />
                              )}
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <p>
                          {data.status === "" ? (
                            <Icon name="checkmark" />
                            ) : (
                              <p />
                              )}
                        </p>
                      </Table.Cell>
                    </Table.Row>
                    ))}
                </Table.Body>
              </Table>
              <Table celled className="printCard">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Observations/Remarks</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <Divider hidden />
                      <Divider hidden />
                      <Divider hidden />
                      <Divider hidden />
                      <Divider hidden />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <List size="medium" className="printCard">
                <Grid>
                  <Grid.Column floated="left" width={4}>
                    <List.Item>
                      <h3 className="sub-header">Contr: Tower In-charge</h3>
                    </List.Item>
                    <List.Item>
                      <h4>Sig </h4>
                    </List.Item>
                    <List.Item>
                      <h4>Name</h4>
                    </List.Item>
                  </Grid.Column>
                  <Grid.Column floated="left" width={4}>
                    <List.Item>
                      <h3 className="sub-header">Contr: Safety</h3>
                    </List.Item>
                    <List.Item>
                      <h4>Sig </h4>
                    </List.Item>
                    <List.Item>
                      <h4>Name</h4>
                    </List.Item>
                  </Grid.Column>
                  <Grid.Column floated="left" width={4}>
                    <List.Item>
                      <h3 className="sub-header">LTR: Tower In-charge</h3>
                    </List.Item>
                    <List.Item>
                      <h4>Sig </h4>
                    </List.Item>
                    <List.Item>
                      <h4>Name</h4>
                    </List.Item>
                  </Grid.Column>
                  <Grid.Column floated="left" width={4}>
                    <List.Item>
                      <h3 className="sub-header">LTR: Cluster Head</h3>
                    </List.Item>
                    <List.Item>
                      <h4>Sig </h4>
                    </List.Item>
                    <List.Item>
                      <h4>Name</h4>
                    </List.Item>
                  </Grid.Column>
                </Grid>
              </List>
              <Divider hidden />
              <List size="medium" className="printCard">
                <Grid>
                  <Grid.Column floated="left" width={4}>
                    <List.Item>
                      <h3 className="sub-header">LtR: Safety</h3>
                    </List.Item>
                    <List.Item>
                      <h4>Sig </h4>
                    </List.Item>
                    <List.Item>
                      <h4>Name</h4>
                    </List.Item>
                  </Grid.Column>
                  <Grid.Column floated="left" width={4}>
                    <List.Item>
                      <h3 className="sub-header">LTR: MEP</h3>
                    </List.Item>
                    <List.Item>
                      <h4>Sig </h4>
                    </List.Item>
                    <List.Item>
                      <h4>Name</h4>
                    </List.Item>
                  </Grid.Column>
                  <Grid.Column floated="left" width={4}>
                    <List.Item>
                      <h3 className="sub-header">LTR: Quality</h3>
                    </List.Item>
                    <List.Item>
                      <h4>Sig </h4>
                    </List.Item>
                    <List.Item>
                      <h4>Name</h4>
                    </List.Item>
                  </Grid.Column>
                  <Grid.Column floated="left" width={4}>
                    <List.Item>
                      <h3 className="sub-header">LTR: Project Head</h3>
                    </List.Item>
                    <List.Item>
                      <h4>Sig </h4>
                    </List.Item>
                    <List.Item>
                      <h4>Name</h4>
                    </List.Item>
                  </Grid.Column>
                </Grid>
              </List>
            </Container>
          ) : (
            <Container fluid textAlign="center">
              <Divider className="print-hide" />
              <p>No data found!</p>
              <Divider hidden section />
              <Divider hidden section />
              <Divider hidden section />
            </Container>
            )}
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.auth.user,
  unitInfoDetail: state.unitInfoDetail.unitInfoDetail,
  unitDataDetail: state.unitDataDetail.unitDataDetail,
  towerInfoDetail: state.towerInfoDetail.towerInfoDetail,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
  projectSelected: state.projectSelected.projectSelected
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      stage2UpdateUnitDataDetail,
      downloadPdf
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UnitInfo);
