import { SET_DELTA_UNIT_INFO, UPDATE_DELTA_UNIT_INFO } from '../constants';

const initialState = {
  deltaUnitInfo: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_DELTA_UNIT_INFO:
      return {
        deltaUnitInfo: action.deltaUnitInfo,
      };
    case UPDATE_DELTA_UNIT_INFO:
      return { ...state, deltaUnitInfo: action.deltaUnitInfo };
    default:
      return state;
  }
};
