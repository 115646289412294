const headerStyle = {
  appBar: {
    display: 'flex',
    border: '0',
    width: '100%',
    background: 'linear-gradient(125deg, #273271, #1f63a7)',
    boxShadow: '0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)',
    transition: 'all 150ms ease 0s',
    zIndex: '100',
  },
  logo: {
    flex: 1,
    textAlign: 'center',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  '@media print': {
    // eslint-disable-line no-useless-computed-key
    appBar: {
      display: 'none',
    },
  },

  newDashboard: {
    "&:hover": {
      color: "#fff"
    }
  }
};

export default headerStyle;
