import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { unitInfoGet, updateUnitInfo } from "../../actions";
import { bindActionCreators } from "redux";
import _ from "lodash";
import ReactTable from "react-table";
import matchSorter from "match-sorter";
// import "../blockInfo/unitInfo.css";

import {
  Button,
  Table,
  Grid,
  Loader,
  Container,
  Icon
} from "semantic-ui-react";

class UnitInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unitInfo: [],
      errors: {},
      isLoading: true,
      hasErrored: false
    };
  }

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    if (localStorage.getItem("unitSelectedName")) {
      localStorage.removeItem("unitSelectedName");
    }
    if (localStorage.getItem("unitSelectedAction")) {
      localStorage.removeItem("unitSelectedAction");
    }
    if (localStorage.getItem("unitSelectedId")) {
      localStorage.removeItem("unitSelectedId");
    }
  }

  snagLinkClick(unit) {
    localStorage.setItem("unitSelectedName", unit.title);
    localStorage.setItem("unitSelectedId", unit.action);
    this.props.history.push(`/reports/blockInfo${unit}/`);
  }

  unitInfoLinkClicked(unit) {
    const towerSelectedId = localStorage.getItem("blockSelectedId");
    const towerSelectedIndex = localStorage.getItem("blockSelectedIndex");
    localStorage.setItem("unitInfo_unitSelectedId", unit.title);
    localStorage.setItem("unitInfo_blockSelectedId", towerSelectedId);
    localStorage.setItem("unitInfo_blockSelectedIndex", towerSelectedIndex);
    localStorage.removeItem("unitInfo_unitSelectedIndex");
    localStorage.removeItem("unitInfo_blockSelectedName");
    // window.location.pathname = 'project/blockInfo';
    // window.location = '/project/unitInfo' ;
    this.props.history.push("/reports/unitInfo");
  }

  render() {
    const {
      unitInfo,
      isLoading,
      hasErrored,
      errors,
      propertySelected
    } = this.props;

    if (isLoading) {
      return (
        <div>
          <Loader active indeterminate className="centered">
            Updating Units
          </Loader>
        </div>
      );
    }

    if (_.isEmpty(unitInfo) && !isLoading) {
      return (
        <Container fluid>
          <Grid.Row>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Unit</Table.HeaderCell>
                  <Table.HeaderCell>Currently Responsible</Table.HeaderCell>
                  <Table.HeaderCell>Activity</Table.HeaderCell>
                  <Table.HeaderCell>Date Updated</Table.HeaderCell>
                  <Table.HeaderCell>Report</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <p className="noDataFound">No Data Found!</p>
            </Table>
          </Grid.Row>
        </Container>
      );
    }

    if (hasErrored) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again
            {errors}
          </div>
        </div>
      );
    }

    const pageOptions = [10, 20, 25, 50, 100, unitInfo.length];

    return (
      <Grid columns="16">
        <Grid.Row >
          {/* <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Unit</Table.HeaderCell>
                <Table.HeaderCell>Currently Responsible</Table.HeaderCell>
                <Table.HeaderCell>Activity</Table.HeaderCell>
                <Table.HeaderCell>Date Updated</Table.HeaderCell>
                <Table.HeaderCell>Report</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {unitInfo.map(function(unit, index) {
                return (
                  <Table.Row key={index}>
                    <Table.Cell>
                      <p
                        className="asLink"
                        as="a"
                        type="submit"
                        size="tiny"
                        onClick={() => this.unitInfoLinkClicked(unit)}
                      >
                        {unit.title}
                      </p>
                    </Table.Cell>
                    <Table.Cell>{unit.fullname}</Table.Cell>
                    <Table.Cell>{unit.action}</Table.Cell>
                    <Table.Cell>
                      {moment(unit.sync_ut).format("DD-MM-YYYY")}
                    </Table.Cell>

                    <Table.Cell>
                      {unit.snagLink ? (
                        <Button
                          type="submit"
                          secondary
                          size="tiny"
                          onClick={() => this.snagLinkClick(unit)}
                        >
                          View Report
                        </Button>
                      ) : (
                        <p />
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              }, this)}
            </Table.Body>
          </Table> */}

          <ReactTable
            data={unitInfo}
            pageSizeOptions={pageOptions}
            columns={[
              {
                columns: [
                  {
                    Header: () => (
                      <span className="table-header-font">
                        Unit
                        <Icon name="sort" />
                      </span>
                    ),
                    headerStyle: {
                    backgroundColor: '#273271',
                    color: '#fff',
                  },
                  style: {
                    backgroundColor: '#fff',
                  },
                    id: "title",
                    accessor: d => d.title,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["title"]
                      }),
                    Filter: ({ filter, onChange, rows }) => (
                      <input
                        type="text"
                        placeholder="Search for Unit"
                        style={{ width: "100%", border: '1px solid #fff' }}
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                      />
                    ),
                    filterAll: true,
                    filterable: true
                  },
                  {
                    Header: () => (
                      <span>
                        Currently Responsible
                        <Icon name="sort" className="print-hide" />
                      </span>
                    ),
                    id: "fullname",
                    accessor: d => d.fullname,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["fullname"]
                      }),
                    Filter: ({ filter, onChange, rows }) => (
                      <input
                        type="text"
                        placeholder="Search for Person"
                        style={{ width: "100%", border: '1px solid #fff' }}
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                      />
                    ),
                    headerStyle: {
                    backgroundColor: '#273271',
                    color: '#fff',
                  },
                  style: {
                    backgroundColor: '#fff',
                  },
                    filterAll: true,
                    filterable: true
                  },
                  {
                    Header: () => (
                      <span>
                        Activity Name
                        <Icon name="sort" className="print-hide" />
                      </span>
                    ),
                    id: "description",
                    accessor: d => d.description,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["description"]
                      }),
                    Filter: ({ filter, onChange, rows }) => (
                      <input
                        type="text"
                        placeholder="Search for Activity name"
                        style={{ width: "100%",  border: '1px solid #fff'}}
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                      />
                    ),
                    headerStyle: {
                    backgroundColor: '#273271',
                    color: '#fff',
                  },
                  style: {
                    backgroundColor: '#fff',
                  },
                    filterAll: true,
                    filterable: true
                  },
                  {
                    Header: () => (
                      <span>
                        Activity Step Name
                        <Icon name="sort" className="print-hide" />
                      </span>
                    ),
                    id: "action",
                    accessor: d => d.action,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["action"]
                      }),
                    Filter: ({ filter, onChange, rows }) => (
                      <input
                        type="text"
                        placeholder="Search for Activity Step name"
                        style={{ width: "100%",  border: '1px solid #fff'}}
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                      />
                    ),
                    headerStyle: {
                    backgroundColor: '#273271',
                    color: '#fff',
                  },
                  style: {
                    backgroundColor: '#fff',
                  },
                    filterAll: true,
                    filterable: true
                  },
                  {
                    Header: () => (
                      <span>
                        Unit Type
                        <Icon name="sort" className="print-hide" />
                      </span>
                    ),
                    id: "unit_type",
                    accessor: d => d.unit_type,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["unit_type"]
                      }),
                    Filter: ({ filter, onChange, rows }) => (
                      <input
                        type="text"
                        placeholder="Search for Unit Type"
                        style={{ width: "100%",  border: '1px solid #fff'}}
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                      />
                    ),
                    headerStyle: {
                    backgroundColor: '#273271',
                    color: '#fff',
                  },
                  style: {
                    backgroundColor: '#fff',
                  },
                    filterAll: true,
                    filterable: true
                  },
                  {
                    Header: () => (
                      <span>
                        Date Updated
                        <Icon name="sort" className="print-hide" />
                      </span>
                    ),
                    id: "sync_ut",
                    accessor: d => moment(d.sync_ut).format("DD-MM-YYYY"),
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["sync_ut"] }),
                    Filter: ({ filter, onChange, rows }) => (
                      <input
                        type="text"
                        placeholder="Search for Date"
                        style={{ width: "100%", border: '1px solid #fff'}}
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                      />
                    ),
                    headerStyle: {
                    backgroundColor: '#273271',
                    color: '#fff',
                  },
                  style: {
                    backgroundColor: '#fff',
                  },
                    filterAll: true,
                    filterable: false
                  },
                  {
                    Header: () => (
                      <span>
                        Report
                        <Icon name="sort" className="print-hide" />
                      </span>
                    ),
                    id: "snagLink",
                    accessor: d => d.snagLink,
                    Cell: row => (
                      <span>
                        {row.value ? (
                          <Button
                            type="submit"
                            secondary
                            size="tiny"
                            onClick={() => this.snagLinkClick(row.value)}
                          >
                            View Report
                          </Button>
                        ) : (
                          <p />
                        )}
                      </span>
                    ),
                    headerStyle: {
                    backgroundColor: '#273271',
                    color: '#fff',
                  },
                  style: {
                    backgroundColor: '#fff',
                  },
                    filterAll: true,
                    filterable: false
                  }
                ]
              }
            ]}
            defaultPageSize={10}
            className={'-highlights'}
            noDataText="No Data Found"
            style={{
              width: "100%",
              height: "59vh",
              transition: "all .2s ease-out"
            }}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  unitInfo: state.unitInfo.unitInfo,
  towerInfo: state.towerInfo.towerInfo,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
  propertySelected: state.property.propertySelected.id
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      unitInfoGet,
      updateUnitInfo
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UnitInfo)
);
