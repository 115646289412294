import React, { Component } from "react";
import { connect } from "react-redux";
import "whatwg-fetch";
import { bindActionCreators } from "redux";
import { dashboardInfoGet } from "../../actions";
import { Container, Loader } from "semantic-ui-react";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardInfo: {},
      errors: {},
      isLoading: true,
      hasErrored: false
    };
  }

  componentDidMount() {
    const requestData = {
      id: this.props.match.params.id
    };
    this.props.dashboardInfoGet(requestData).then(
      res => {
        this.setState.isLoading = false;
      },
      err => this.setState({ errors: err.response })
    );
  }

  render() {
    const { isLoading, hasErrored, errors, dashboardInfo } = this.props;

    if (isLoading || Object.keys(dashboardInfo).length === 0) {
      return (
        <Container textAlign="center">
          <Loader active indeterminate className="centered">
            Generating Report...
          </Loader>
        </Container>
      );
    }
    if (hasErrored) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again
            {errors}
          </div>
        </div>
      );
    }

    return (
      <div style={{ width: "100%", height: "1000px" }}>
        <iframe
          title="Dashboard loading"
          style={{ width: "100%", height: "100%", border: "0" }}
          src={dashboardInfo.url.url}
          frameBorder="0"
          allowFullScreen
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
  dashboardInfo: state.dashboardInfo.dashboardInfo
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dashboardInfoGet
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
