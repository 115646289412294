import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import 'whatwg-fetch';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import 'react-table/react-table.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { Button, Header, Divider } from 'semantic-ui-react';
import DeltaUnitInfoSub from './DeltaUnitInfoSub';

import {
  deltaUnitInfoGet,
  deltaUnitInfoUpdate,
  downloadExcel,
} from '../../actions';

class deltaInfoUnit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: '',
      to: '',
      propertySelected: '',
    };
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.updateDeltaUnitInfo = this.updateDeltaUnitInfo.bind(this);
  }

  componentDidMount() {
    const from = moment()
      .startOf('day')
      .toISOString();
    const to = moment().toISOString();
    const { propertySelected, deltaUnitInfoGet } = this.props;
    // if (this.getParameterByName('from')) {
    //   from = this.getParameterByName('from');
    // }
    // if (this.getParameterByName('to')) {
    //   to = this.getParameterByName('to');
    // }
    // const blockId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1);
    const requestData = {
      fromDate: from,
      toDate: to,
      propertySelected,
    };
    this.setState({ from });
    this.setState({ to });
    // console.log(propertySelected);
    this.setState(
      {
        from,
        to,
        propertySelected,
      },
      () => {
        deltaUnitInfoGet(requestData);
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { propertySelected, deltaUnitInfoGet } = this.props;

    const { from, to } = this.state;
    if (propertySelected !== prevProps.propertySelected) {
      this.setState(
        {
          propertySelected,
        },
        () => {
          // const blockId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1);
          const requestData = {
            fromDate: from,
            toDate: to,
            propertySelected,
          };
          deltaUnitInfoGet(requestData);
        }
      );
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.propertySelected !== prevState.propertySelected) {
      return { propertySelected: nextProps.propertySelected };
    }

    return null;
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  toggleClass(index) {
    this.setState({ active: index });
  }

  focusTo() {
    this.timeout = setTimeout(() => this.to.getInput().focus(), 0);
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from }, () => {
      if (!this.state.to) {
        this.focusTo();
      }
    });
  }

  handleToChange(to) {
    this.setState({ to });
    // const blockId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1);
    const requestData = {
      fromDate: this.state.from,
      toDate: this.state.to,
      propertySelected: this.state.propertySelected,
    };
    this.props.deltaUnitInfoUpdate(requestData);
  }

  updateDeltaUnitInfo(from) {
    let requestData = {};
    if (from) {
      requestData = {
        fromDate: from,
        toDate: moment().toISOString(),
        propertySelected: this.state.propertySelected,
      };
      this.setState(
        {
          from,
          to: moment().toISOString(),
        },
        () => {
          this.props.deltaUnitInfoUpdate(requestData);
        }
      );
    } else {
      requestData = {
        fromDate: this.state.from,
        toDate: this.state.to,
        propertySelected: this.state.propertySelected,
      };
      this.props.deltaUnitInfoUpdate(requestData);
    }
  }

  // getParameterByName(name, url) {
  //   if (!url) url = window.location.href;
  //   name = name.replace(/[\]]/g, '\\$&');
  //   let regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`),
  //     results = regex.exec(url);
  //   if (!results) return null;
  //   if (!results[2]) return '';
  //   return decodeURIComponent(results[2].replace(/\+/g, ' '));
  // }

  render() {
    const {
      active,
      propertySelected,
      deltaUnitInfo,
      isLoading,
      hasErrored,
    } = this.props;
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    // console.log(this.state);
    // let customer_id = profile.customer_id;
    // const paths = this.props.location.pathname.split('/').map((p, i, arr) => {
    //   if (p === 'deltaUnitInfo') {
    //     p = 'Delta Report';
    //   }
    //   if (i === 0)
    //     return {
    //       key: i,
    //       content: <Link to="/project">{propertySelected}</Link>,
    //       active: i === arr.length - 1,
    //       link: i < arr.length - 1,
    //     };
    //   if (i === arr.length - 1)
    //     return {
    //       key: i,
    //       content: p,
    //       active: i === arr.length - 1,
    //     };

    //   return {
    //     key: i,
    //     content: (
    //       <Link to={`${arr.slice(0, i + 1).join('/')}`}>
    //         {p.charAt(0).toUpperCase() + p.slice(1)}
    //       </Link>
    //     ),
    //     active: i === arr.length - 1,
    //     link: i < arr.length - 1,
    //   };
    // });
    // let pageOptions = [10, 20, 25, 50, 100];

    return (
      <Fragment>
        <Header as="h2"> Delta Report (unit) </Header>
        <div className="InputFromTo">
          <DayPickerInput
            value={new Date(from)}
            placeholder="From Date"
            format="DD-MM-YYYY"
            formatDate={formatDate}
            parseDate={parseDate}
            // dayPickerProps={{
            //   disabledDays: { after: to },
            //   toMonth: to,
            //   modifiers,
            //   numberOfMonths: 1,
            //   showOutsideDays: 'false',
            // }}
            onDayChange={this.handleFromChange}
          />{' '}
          —{' '}
          <span className="InputFromTo-to">
            <DayPickerInput
              value={new Date(to)}
              ref={el => (this.to = el)}
              placeholder="To Date"
              format="DD-MM-YYYY"
              formatDate={formatDate}
              parseDate={parseDate}
              // dayPickerProps={{
              //   disabledDays: { before: from },
              //   todayButton: 'Till Now',
              //   modifiers,
              //   month: from,
              //   fromMonth: from,
              //   numberOfMonths: 1,
              //   showOutsideDays: 'false',
              // }}
              onDayChange={this.handleToChange}
            />
          </span>
          <span>
            <Button
              size="small"
              toggle
              basic
              active={active}
              onClick={() => {
                this.updateDeltaUnitInfo();
              }}
              className="updateInfoDeltaButton"
            >
              Update
            </Button>
          </span>
          <Divider hidden />
          <span>
            <Button
              size="small"
              toggle
              basic
              active={active}
              onClick={() => {
                const fromDay = moment()
                  .startOf('day')
                  .toISOString();
                this.updateDeltaUnitInfo(fromDay);
              }}
              className="updateInfoDelta"
            >
              Today
            </Button>
          </span>
          <span>
            <Button
              size="small"
              toggle
              basic
              active={active}
              onClick={() => {
                const fromWeek = moment()
                  .startOf('isoWeek')
                  .toISOString();
                this.updateDeltaUnitInfo(fromWeek);
              }}
              className="updateInfoDelta"
            >
              This Week
            </Button>
          </span>
          <span>
            <Button
              size="small"
              basic
              onClick={() => {
                const fromMonth = moment()
                  .startOf('month')
                  .toISOString();
                this.updateDeltaUnitInfo(fromMonth);
              }}
              className="updateInfoDelta"
            >
              This Month
            </Button>
          </span>
        </div>
        <Divider />
        <DeltaUnitInfoSub
          deltaUnitInfo={deltaUnitInfo}
          isLoading={isLoading}
          errors={hasErrored}
          from={from}
          to={to}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  deltaUnitInfo: state.deltaUnitInfo.deltaUnitInfo,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
  propertySelected: state.property.propertySelected.id,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deltaUnitInfoGet,
      deltaUnitInfoUpdate,
      downloadExcel,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(deltaInfoUnit);
