import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
// @material-ui/icons
import AccountCircle from '@material-ui/icons/AccountCircle';
// core components
import headerStyle from './HeaderStyle';
import { logout } from '../../../../actions/Auth';
import SliderMenu from '../SliderMenu';

import PropertySelection from '../PropertySelection';
import Logo from '../../../../resources/images/fbtLogoNew.svg';
import Cookie from '../../../../configs/cookie';

class Header extends React.Component {
  state = {
    anchorEl: null,
  };

  logoutClick = () => {
    this.props.dispatch(logout());
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  setCookie = () => {
    const token = localStorage.getItem('hasuraJwtToken');
    Cookie.set("jwtAuth", token, 7);
    window.open(process.env.PORTAL_V2_URL, '_blank' );
  }

  render() {
    const { classes, profile } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <SliderMenu />
            <PropertySelection />
            <Typography variant="title" color="inherit" className={classes.logo}>
              <img alt="Logo" src={Logo} />
            </Typography>
            <Button className={classes.newDashboard} color="inherit" onClick={this.setCookie}>
              <span style={{ paddingLeft: 5, fontFamily: 'Roboto' }}>{"New Dashboard"}</span>
            </Button>
            <Button
              aria-owns={open ? 'menu-appbar' : null}
              aria-haspopup="true"
              onClick={this.handleMenu}
              color="inherit"
            >
              <AccountCircle />
              <span style={{ paddingLeft: 5, fontFamily: 'Roboto' }}>{profile.username}</span>
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={open}
              onClose={this.handleClose}
            >
              <MenuItem onClick={this.handleClose}>{profile.customer_name}</MenuItem>
              <MenuItem onClick={e => this.props.history.push('/changePassword')}>Change Password</MenuItem>
              <MenuItem onClick={this.logoutClick}>Logout</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
});

export default withStyles(headerStyle)(
  withRouter(
    connect(
      mapStateToProps,
      null
    )(Header)
  )
);
