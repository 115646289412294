import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'whatwg-fetch';
import { bindActionCreators } from 'redux';

import Select from 'react-super-select';
import _ from 'lodash';

import {
  Grid,
  Header,
  Divider,
  Loader,
  Breadcrumb,
  Container,
  Label,
  Form,
} from 'semantic-ui-react';
import UnitInfoDetail from './UnitInfoDetail';
import { towerInfoGetDetail, updateTowerInfoDetail } from '../../actions';

class TowerInfoDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      towerInfoDetail: [],
      errors: {},
      isLoading: true,
      hasErrored: false,
      active: 0,
    };
    this.towerInfoDetailUpdate = this.towerInfoDetailUpdate.bind(this);
  }

  componentDidMount() {
    // console.log(this.props);
    const { propertySelected } = this.props;
    let blockSelected = '';
    let unitSelected = '';
    let requestData = {};
    if (localStorage.getItem('unitInfo_blockSelectedId')) {
      blockSelected = localStorage.getItem('unitInfo_blockSelectedId');
    }
    if (localStorage.getItem('unitInfo_unitSelectedId')) {
      unitSelected = localStorage.getItem('unitInfo_unitSelectedId');
    }
    requestData = {
      project: propertySelected,
      blockSelected,
      unitSelected,
    };
    // console.log(propertySelected + blockSelected);
    this.props.towerInfoGetDetail(requestData).then(
      res => {
        // console.log(res);
        this.setState.isLoading = false;
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.props.propertySelected) !==
      JSON.stringify(nextProps.propertySelected)
    ) {
      const blockSelected = '';
      const unitSelected = '';
      let requestData = {};

      requestData = {
        project: nextProps.propertySelected,
        blockSelected,
        unitSelected,
      };
      // console.log(propertySelected + blockSelected);
      this.props.towerInfoGetDetail(requestData).then(
        res => {
          // console.log(res);
          this.setState.isLoading = false;
        },
        err => this.setState({ errors: err.response, hasErrored: true })
      );
    }
  }

  towerInfoDetailUpdate = function(options) {
    localStorage.setItem('unitInfo_blockSelectedName', options.name);
    localStorage.setItem('unitInfo_blockSelectedId', options.id);
    if (localStorage.getItem('unitInfo_unitSelectedId')) {
      localStorage.removeItem('unitInfo_unitSelectedId');
    }
    this.props.updateTowerInfoDetail(options.id).then(
      res => {
        // console.log(res);
        this.setState.isLoading = false;
        this.setState.hasErrored = false;
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
  };

  render() {
    const {
      // profile,
      towerInfoDetail,
      isLoading,
      hasErrored,
      errors,
      // active
    } = this.props;
    const paths = this.props.location.pathname.split('/').map((p, i, arr) => {
      if (p === 'unitInfo') {
        if (localStorage.getItem('unitInfo_blockSelectedName')) {
          p = localStorage.getItem('unitInfo_blockSelectedName');
        } else {
          p = 'Unit info';
        }
      }
      if (i === 0)
        return {
          key: i,
          content: <p>Home</p>,
          active: i === arr.length - 1,
          link: i < arr.length - 1,
        };
      if (i === 3)
        return {
          key: i,
          content: '',
          active: i === arr.length - 1,
          link: i < arr.length - 1,
        };

      if (i === arr.length - 1)
        return {
          key: i,
          content: p,
          active: i === arr.length - 1,
        };
      return {
        key: i,
        content: (
          <Link to={`${arr.slice(0, i + 1).join('/')}`}>
            {p.charAt(0).toUpperCase() + p.slice(1)}
          </Link>
        ),
        active: i === arr.length - 1,
        link: i < arr.length - 1,
      };
    });
    let selctedBlock;
    if (localStorage.getItem('unitInfo_blockSelectedName')) {
      selctedBlock = localStorage.getItem('unitInfo_blockSelectedName');
    }

    if (isLoading) {
      return (
        <Container textAlign="center">
          <Loader active indeterminate className="centered">
            Getting block and unit info...
          </Loader>
        </Container>
      );
    }

    if (_.isEmpty(towerInfoDetail) && !isLoading) {
      return (
        <Container fluid textAlign="center">
          <p>No data found!</p>
          <Divider hidden section />
          <Divider hidden section />
          <Divider hidden section />
        </Container>
      );
    }

    if (hasErrored) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again
            {errors}
          </div>
        </div>
      );
    }

    if (towerInfoDetail.length) {
      return (
        <div>
          <Divider hidden />
          <Breadcrumb icon="chevron right" sections={paths} size="big" />
          <Header as="h2">Unit Status</Header>
          <Divider />
          <Divider hidden />
          <Grid columns="16" padded>
            <Grid.Row>
              <Form className="dropDownForm">
                <Form.Field>
                  <Label>Block</Label>
                  <Select
                    placeholder={selctedBlock || 'Please Select A Block'}
                    dataSource={towerInfoDetail}
                    onChange={this.towerInfoDetailUpdate}
                    clearSearchOnSelection
                    deselectOnSelectedOptionClick={false}
                    searchable
                    clearable={false}
                  />
                </Form.Field>
              </Form>
            </Grid.Row>
          </Grid>
          <UnitInfoDetail />
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  towerInfoDetail: state.towerInfoDetail.towerInfoDetail,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
  propertySelected: state.property.propertySelected.id,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      towerInfoGetDetail,
      updateTowerInfoDetail,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TowerInfoDetails);
