import React, { Component, PropTypes } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styles from "./Dashboard.module.css";

export default class Dashboard extends Component {
  render() {
    return <h3>Dashboard</h3>;
  }
}
