import axios from 'axios';
import _ from 'lodash';
import setAuthorizationToken from '../container/utils/setAuthorizationToken';
import config from '../configs/clientConfig';

import * as property from '../constants/Property';

// property get
export const getPropertyList = () => ({
  type: property.PROPERTY_INFO_FETCH
});

export const getPropertyListSuccess = propertyList => ({
  type: property.PROPERTY_INFO_SUCCESS,
  payload: { propertyList }
});

export const getPropertyListError = error => ({
  type: property.PROPERTY_INFO_ERROR,
  payload: { error }
});

export function propertyGet(projectId) {
  return dispatch => {
    dispatch(getPropertyList());
    return axios
      .get(`${config.ApiUrl}/api/projectList/${projectId}`)
      .then(res => {
        dispatch(getPropertyListSuccess(res.data.data));
        localStorage.setItem('propertyList', JSON.stringify(res.data.data));
      })
      .catch(error => dispatch(getPropertyListError(error.response.data)));
  };
}

// property set
export const setPropertySuccess = propertySelected => ({
  type: property.PROPERTY_SET_SUCCESS,
  payload: { propertySelected }
});

export function propertySet(propertySelected) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch(setPropertySuccess(propertySelected));
      localStorage.setItem('propertySelected', JSON.stringify(propertySelected));
      resolve();
    });
}
