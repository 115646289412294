import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import MenuIcon from '@material-ui/icons/Menu';

import _ from 'lodash';
import { navInfoGet } from '../../../../actions/Nav';

import menuStyle from './SliderMenuStyle';

class SliderMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      left: false,
    };
    this.navLinkClicked = this.navLinkClicked.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  componentWillMount() {
    if (!localStorage.getItem('navInfo')) {
      this.props.dispatch(navInfoGet());
    }
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  navLinkClicked = (link, e) => {
    e.preventDefault();
    // console.log(link);
    this.props.history.push(link.link);
  };

  render() {
    const { classes, navInfo, navList } = this.props;

    if (navInfo.loading) {
      return (
        <div>
          <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" id="sliderMenu">
            <MenuIcon onClick={this.toggleDrawer('left', true)} />
          </IconButton>
          <Drawer open={this.state.left} onClose={this.toggleDrawer('left', false)}>
            <div
              tabIndex={0}
              role="button"
              onClick={this.toggleDrawer('left', false)}
              onKeyDown={this.toggleDrawer('left', false)}
            >
              Loading...
            </div>
          </Drawer>
        </div>
      );
    }
    if (_.isEmpty(navList)) {
      return (
        <div>
          <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" id="sliderMenu">
            <MenuIcon onClick={this.toggleDrawer('left', true)} />
          </IconButton>
          <Drawer open={this.state.left} onClose={this.toggleDrawer('left', false)}>
            <div
              tabIndex={0}
              role="button"
              onClick={this.toggleDrawer('left', false)}
              onKeyDown={this.toggleDrawer('left', false)}
            >
              Please refresh!
            </div>
          </Drawer>
        </div>
      );
    }

    const links = navList.map((link, i) => (
      <ListItem key={link.id} button className={classes.listItem} onClick={e => this.navLinkClicked(link, e)}>
        <ListItemText inset={false} disableTypography primary={link.dashboard_name} className={classes.listItemText} />
      </ListItem>
    ));

    return (
      <div>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
          id="sliderMenu"
          onClick={this.toggleDrawer('left', true)}
        >
          <MenuIcon onClick={this.toggleDrawer('left', true)} />
        </IconButton>
        <Drawer open={this.state.left} onClose={this.toggleDrawer('left', false)} classes={{ paper: classes.list }}>
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('left', false)}
            onKeyDown={this.toggleDrawer('left', false)}
          >
            <List>{links}</List>
          </div>
        </Drawer>
      </div>
    );
  }
}

SliderMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  profile: state.authManager.user,
  navInfo: state.navInfo,
  navList: state.navInfo.navInfo,
});

export default withStyles(menuStyle)(
  withRouter(
    connect(
      mapStateToProps,
      null
    )(SliderMenu)
  )
);
