/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import 'whatwg-fetch';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { SelectionBox } from '@falconbrick/fbt-web-components';
import { Header, Grid, Divider, Button, Icon } from 'semantic-ui-react';
import TowerSnagTable from './TowerSnagInfoTable';
import 'react-table/react-table.css';
import { downloadExcel } from '../../actions';
import styles from './TowerSnagInfo.module.css';
import Logo from '../../resources/images/fullLogoWhit.svg';
import printPdf from '../../resources/images/pdf-icon-white.svg';
import printxlsx from '../../resources/images/excel-white.svg';

import {
  getBlocks,
  getTowerSnagInfo,
  getInitalData,
  updateData,
} from '../../actions/towerSnagInfo';

const initialState = () => ({
  propertySelected: '',
  block_id: '',
  unit_type: '',
  snag: 1,
  task: 0,
  pagination: {
    page_no: 1,
    no_items: 15000,
  },
});

class towerSnagInfo extends Component {
  constructor(props) {
    super(props);
    this.state = initialState();
    this.updateUnitTypeSelected = this.updateUnitTypeSelected.bind(this);
    this.updateBlockSelected = this.updateBlockSelected.bind(this);
    this.updateSnagTypeSelected = this.updateSnagTypeSelected.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  componentDidMount() {
    const that = this.props;
    this.setState(
      {
        propertySelected: that.propertySelected,
      },
      () => {
        this.props.getInitalData(this.state);
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.propertySelected !== prevProps.propertySelected) {
      this.setState(
        {
          propertySelected: this.props.propertySelected,
        },
        () => {
          this.props.getInitalData(this.state);
        }
      );
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.propertySelected !== prevState.propertySelected) {
      return { propertySelected: nextProps.propertySelected };
    }
    if (
      nextProps.blockSelected &&
      nextProps.blockSelected.id !== prevState.block_id
    ) {
      return {
        block_id: nextProps.blockSelected.id,
      };
    }
    if (
      nextProps.unitTypeSelected &&
      nextProps.unitTypeSelected.id !== prevState.unit_type
    ) {
      return {
        unit_type: nextProps.unitTypeSelected.id,
      };
    }

    return null;
  }

  resetState() {
    this.setState(makeDefaultState());
  }

  updateUnitTypeSelected(options) {
    // console.log(options);
    if (options !== undefined) {
      const reqData = this.state;
      reqData.unit_type = options;
      this.props.updateData(reqData);
    }
  }

  updateBlockSelected(options) {
    // console.log(options);
    if (options !== undefined) {
      const reqData = this.state;
      reqData.block_id = options;
      this.props.updateData(reqData);
    }
  }

  updateSnagTypeSelected(options) {
    // console.log(options);
    let snags = { snag: 1, task: 0 };
    if (options !== undefined) {
      if (options === 'snag/task') {
        snags = { snag: 1, task: 1 };
      }
      if (options === 'task') {
        snags = { snag: 0, task: 1 };
      }
      this.setState(
        {
          snag: snags.snag,
          task: snags.task,
        },
        () => {
          this.props.updateData(this.state);
        }
      );
    }
  }

  downloadExcel(towerSnagInfo, customer_id) {
    const filterData = {
      towerSnagInfo: towerSnagInfo.map(
        ({
          unit_title,
          activity_name,
          step_name,
          category_title,
          raised_by_username,
          sync_ct,
          sync_ut,
          room_name,
          inspection_item_title,
          description,
          status,
        }) => ({
          unit_title,
          activity_name,
          step_name,
          category_title,
          raised_by_username,
          sync_ct: moment(sync_ct,'DD-MM-YYYY').format('DD-MM-YYYY'),
          sync_ut: moment(sync_ut,'DD-MM-YYYY').format('DD-MM-YYYY'),
          room_name,
          inspection_item_title,
          description,
          status,
        })
      ),
    };
    const data = filterData.towerSnagInfo;
    for (let i = 0; i < data.length; i++) {
      data[i].Unit_Title = data[i].unit_title;
      data[i].Step_Name = data[i].step_name;
      data[i].Activity = data[i].activity_name;
      data[i].Raised_By = data[i].raised_by_username;
      data[i].Step = data[i].step_name;
      data[i].Area = data[i].room_name;
      data[i].Date_Updated = data[i].sync_ut;
      data[i].Date_Raised = data[i].sync_ct;
      data[i].Category = data[i].category_title;
      data[i].Description = data[i].inspection_item_title;
      data[i].Detail = data[i].description;
      data[i].Status = data[i].status;
      delete data[i].unit_title;
      delete data[i].activity_name;
      delete data[i].sync_ut;
      delete data[i].sync_ct;
      delete data[i].room_name;
      delete data[i].category_title;
      delete data[i].inspection_item_title;
      delete data[i].description;
      delete data[i].status;
      delete data[i].step_name;
      delete data[i].raised_by_username;
    }
    const requestData = {
      wSheetData: data,
      wSheetName: customer_id,
    };
    const downloadLink = document.getElementById('xlsx');
    downloadLink.className = 'ui right floated loading disabled button medium';
    downloadLink.innerHTML = 'Loading';
    this.props.downloadExcel(requestData).then(
      res => {
        const a = document.createElement('a');
        a.href = res.reXportPath; // Set the file name.
        a.innerHTML = 'Download xlsx file';
        a.download = 'true';
        a.className = 'ui right floated button positive';
        document.body.appendChild(a);
        const downloadLink = document.getElementById('xlsx');
        downloadLink.className = 'ui right floated button positive';
        downloadLink.innerHTML = '';
        downloadLink.replaceWith(a);
      },
      err => this.setState({ errors: err.response })
    );
  }

  render() {
    const {
      profile,
      isLoading,
      error,
      blockInfo,
      towerSnagInfo,
      unitTypeInfo,
      propertySelectedName,
      projectName,
    } = this.props;
    const { customer_id } = profile;
    // console.log(unit_type_name);
    // const childrenArray = React.Children.toArray(this.props.children);
    const pageOptions = (towerSnagInfo.length < 500 && towerSnagInfo.length > 100) ? [10, 20, 25, 50, 100, towerSnagInfo.length] : [10, 20, 25, 50, 100, 500]
    // const pageOptions = [10, 20, 25, 50, 100, (towerSnagInfo.length < 500) ? towerSnagInfo.length <=0 ? null : towerSnagInfo.length : 500];
    let snagType = 'snag';
    if (this.state.snag && this.state.task) {
      snagType = 'snag/task';
    }
    if (this.state.snag && !this.state.task) {
      snagType = 'snag';
    }
    if (!this.state.snag && this.state.task) {
      snagType = 'task';
    }
    return (
      <Fragment>
        <Divider hidden />

        <div className="Top_Nav_BG">
          <div className="propertyMargin printCard">
            {/* <img
              src={propertyImage}
              width="35"
              height="35"
              style={{ verticalAlign: "middle" }}
            /> */}
            <div style={{ display: 'inline-block', float: 'left' }}>
              <span style={{ display: 'inline-block' }}>
                {profile.customer_name}{' '}
              </span>
              <br />
              <span style={{ display: 'inline-block', fontSize: '12px' }}>
                <b> {propertySelectedName} </b>
              </span>
            </div>
            <span>
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  marginRight: '20px',
                  marginTop: '17px',
                }}
              >
                <img alt="logo" src={Logo} width="96" height="13" />
              </div>
            </span>
            <div style={{ clear: 'both' }} />
          </div>
        </div>

        <Header as="h2" className="print-hide">
          {' '}
          Export Block Tasks/Snags
        </Header>
        <Header as="h2" className="subPropertyAppearance1 printCard">
          {' '}
          Export Block Tasks/Snags: {propertySelectedName} | Unit Type A | Tasks
          & Snags
          <p className="subPropertyAppearance2 printCard">
            Company: {profile.customer_name} - Project: {projectName} -
            Property: {propertySelectedName} - User: {profile.username}
          </p>
        </Header>
        <Header as="h5" className="subPropertyAppearance3 printCard">
          {' '}
          {propertySelectedName} | Unit Type - {this.state.unit_type} |{' '}
          {snagType}
        </Header>
        <div className="divFooter printCard">
          {' '}
          <b>FALCONBRICK - {new Date().getFullYear()} </b> | Export Block
          Tasks/Snags
        </div>
        <Divider />
        <Grid columns="equal" padded style={{ width: '100%', padding: '0' }}>
          <div className={styles.towerBox}>
            <SelectionBox
              id="towerSelection"
              labelText="Selected Block"
              optionId="id"
              optionValue="name"
              callBack={this.updateBlockSelected}
              options={blockInfo}
              // placeholder={block_id_name}
              value={this.state.block_id}
              SelectionBoxCustomClasses={styles.selectionBox}
              LabelTextCustomClasses={styles.labelText}
            />
            <SelectionBox
              id="unitTypeSelection"
              labelText="Unit Type"
              optionId="id"
              optionValue="name"
              callBack={this.updateUnitTypeSelected}
              options={unitTypeInfo}
              // placeholder={unit_type_name}
              value={this.state.unit_type}
              SelectionBoxCustomClasses={styles.selectionBox}
              LabelTextCustomClasses={styles.labelText}
            />
            <SelectionBox
              id="snagSelection"
              labelText="Show"
              optionId="id"
              optionValue="name"
              callBack={this.updateSnagTypeSelected}
              options={[
                { name: 'Task', id: 'task' },
                { name: 'Snag', id: 'snag' },
                { name: 'Task/Snag', id: 'snag/task' },
              ]}
              // placeholder={snagType}
              value={snagType}
              SelectionBoxCustomClasses={styles.selectionBox}
              LabelTextCustomClasses={styles.labelText}
            />
          </div>
          <div className={styles.towerBoxRight}>
            <Button
              floated="right"
              type="submit"
              id="xlsx"
              onClick={() =>
                this.downloadExcel(
                  this.refs.TowerSnagInfoTable.reactTable.getResolvedState()
                    .sortedData,
                  customer_id
                )
              }
            >
              <img
                alt="printxlsx"
                className={styles.inlineImage}
                src={printxlsx}
                width="20"
                height="20"
              />
              <span className={styles.inlineText}>Export As xlsx</span>
            </Button>
            <Button
              floated="right"
              type="submit"
              id="pfd"
              onClick={() => window.print()}
            >
              <img
                alt="printPdf"
                className={styles.inlineImage}
                src={printPdf}
                width="20"
                height="20"
              />
              <span className={styles.inlineText}>Print Page</span>
            </Button>
            {/* <Button
              floated="right"
              type="submit"
              id="pfdAll"
              onClick={() => {
                this.refs.TowerSnagInfoTable.handleClickOpen();
              }}
            >
              <img
                alt="printPdf"
                className={styles.inlineImage}
                src={printPdf}
                width="20"
                height="20"
              />
              <span className={styles.inlineText}>
                Print All ({towerSnagInfo.length})
              </span>
            </Button> */}
          </div>
          <TowerSnagTable
            ref="TowerSnagInfoTable"
            towerSnagInfo={towerSnagInfo}
            pageOptions={pageOptions}
            isLoading={isLoading}
            error={error}
            applyFilter={this.applyFilter}
            screenName={(this.state.task && this.state.snag) ? 'Tasks/Snags' : this.state.snag ? 'Snags' : 'Tasks'}
          />
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  propertySelected: state.property.propertySelected.id,
  propertySelectedName: state.property.propertySelected.name,
  projectName: state.project.projectSelected.name,
  towerInfo: state.towerInfo.towerInfo,
  towerSnagInfo: state.towerSnagInfo.towerSnagInfo,
  isLoading: state.towerSnagInfo.loading,
  error: state.towerSnagInfo.error,
  blockSelected: state.blockInfoReports.blockSelected,
  blockInfo: state.blockInfoReports.blockInfo,
  unitTypeSelected: state.towerSnagInfo.unitTypeSelected,
  unitTypeInfo: state.towerSnagInfo.unitTypeInfo,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getBlocks,
      getTowerSnagInfo,
      getInitalData,
      updateData,
      downloadExcel,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(towerSnagInfo);
