import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "whatwg-fetch";
import { bindActionCreators } from "redux";
import moment from "moment";
import Select from "react-super-select";
import _ from "lodash";

import {
  Button,
  Table,
  Grid,
  Divider,
  Loader,
  Container,
  Form,
  Label
} from "semantic-ui-react";
import { updateUnitDataDetail } from "../../actions";

class UnitInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unitInfoDetail: [],
      errors: {},
      isLoading: true,
      hasErrored: false
    };
    this.updateUnitDataDetail = this.updateUnitDataDetail.bind(this);
  }

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  updateUnitDataDetail = function(options) {
    if (localStorage.getItem("unitInfo_blockSelectedId")) {
      var selctedBlock = localStorage.getItem("unitInfo_blockSelectedId");
    }
    console.log(selctedBlock);
    localStorage.setItem("unitInfo_unitSelectedId", options.title);
    this.props.updateUnitDataDetail(options.title, selctedBlock).then(
      res => {
        // console.log(res);
        this.setState.isLoading = false;
        this.setState.hasErrored = false;
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
  };

  snagLinkClick(unit) {
    localStorage.setItem("unitSelectedId", unit.activity_status);
    this.props.history.push(
      `/reports/unitInfo/${unit.unit_id}/${unit.node_id}/`
    );
  }

  activitySnagLinkClick(unit) {
    localStorage.setItem("unitSelectedId", unit.activity_status);
    this.props.history.push(`/reports/unitInfo/${unit.unit_id}`);
  }

  render() {
    const {
      unitInfoDetail,
      unitDataDetail,
      isLoading,
      hasErrored,
      errors
    } = this.props;

    let selctedBlock;
    if (localStorage.getItem("unitInfo_unitSelectedId")) {
      selctedBlock = localStorage.getItem("unitInfo_unitSelectedId");
    }

    if (isLoading) {
      return (
        <div>
          <Loader active indeterminate className="centered">
            Updating Units
          </Loader>
        </div>
      );
    }

    if (_.isEmpty(unitInfoDetail) && !isLoading) {
      return (
        <Container fluid textAlign="center">
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Activity Name</Table.HeaderCell>
                <Table.HeaderCell>Step Name</Table.HeaderCell>
                <Table.HeaderCell>Activity Status</Table.HeaderCell>
                <Table.HeaderCell>Date Updated</Table.HeaderCell>
                <Table.HeaderCell>User name</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <div>
              <p className="noDataFound">No Data Found!</p>
            </div>
          </Table>
        </Container>
      );
    }

    if (hasErrored) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again
            {errors}
          </div>
        </div>
      );
    }

    return (
      <Grid columns="16" padded>
        <Grid.Row>
          <Form className="dropDownForm">
            <Form.Field>
              <Label>Unit</Label>
              <Select
                placeholder={selctedBlock || "Please Select A Block"}
                dataSource={unitInfoDetail}
                onChange={this.updateUnitDataDetail}
                clearSearchOnSelection
                deselectOnSelectedOptionClick={false}
                optionValueKey="title"
                optionLabelKey="title"
                searchable
                clearable={false}
              />
            </Form.Field>
          </Form>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          {unitDataDetail.length ? (
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Activity Name</Table.HeaderCell>
                  <Table.HeaderCell>Step Name</Table.HeaderCell>
                  <Table.HeaderCell>Activity Status</Table.HeaderCell>
                  <Table.HeaderCell>Last Updated</Table.HeaderCell>
                  <Table.HeaderCell>User name</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {unitDataDetail.map(function(unitData, index) {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>
                        {unitData.activity_name}
                        {unitData.node_id != 0 ? (
                          <Button
                            className="asLink"
                            as="a"
                            type="submit"
                            size="tiny"
                            secondary
                            onClick={() => this.activitySnagLinkClick(unitData)}
                          >
                            [view history]
                          </Button>
                        ) : (
                          <p />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {unitData.activity_status}
                        {!!unitData.current_action_map &&
                        unitData.current_action_map.substring(0, 4) !=
                          "Insp" ? (
                          <Button
                            className="asLink"
                            as="a"
                            type="submit"
                            secondary
                            size="tiny"
                            onClick={() => this.snagLinkClick(unitData)}
                          >
                            [view report]
                          </Button>
                        ) : (
                          <p />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {unitData.status == "complete"
                          ? "Complete"
                          : unitData.status}
                      </Table.Cell>
                      <Table.Cell>
                        {moment(unitData.last_updated).format("DD-MM-YYYY")}
                      </Table.Cell>
                      <Table.Cell>{unitData.user_name}</Table.Cell>
                    </Table.Row>
                  );
                }, this)}
              </Table.Body>
            </Table>
          ) : (
            <Container fluid textAlign="center">
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Activity Name</Table.HeaderCell>
                    <Table.HeaderCell>Step Name</Table.HeaderCell>
                    <Table.HeaderCell>Date Updated</Table.HeaderCell>
                    <Table.HeaderCell>User name</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <div>
                  <p className="noDataFound">No Data Found!</p>
                </div>
              </Table>
            </Container>
          )}
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  unitInfoDetail: state.unitInfoDetail.unitInfoDetail,
  unitDataDetail: state.unitDataDetail.unitDataDetail,
  towerInfoDetail: state.towerInfoDetail.towerInfoDetail,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateUnitDataDetail
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UnitInfo)
);
