const ICON_CONSTANTS = {
  SEARCH: "search-icon.svg",
  SEARCH_SELECTED: "search-selected.svg",
  SEARCH_DEFAULT: "search-default.svg",
  CLEAR_SEARCH: "search-clear.svg",
  CLEAR_SEARCH_HOVER: "clear-search-hover.svg",
  CHECKBOX_LARGE_SELECTED: "checkbox-large-selected.svg",
  CHECKBOX_LARGE_UNSELECTED: "checkbox-large-unselected.svg",
  ONGOING: "ongoing.svg",
  COMPLETED: "completed.svg",
  NOT_STARTED: "not-started.svg",
  NOT_STARTED_SELECTED: "not-started-selected.svg",
  NEXT_PAGE_ACTIVE: "next-page-active.svg",
  NEXT_PAGE_INACTIVE: "next-page-inactive.svg",
  PAGE_START_ACTIVE: "page-jump-active.svg",
  PAGE_START_INACTIVE: "page-jump-inactive.svg",
  PREV_PAGE_ACTIVE: "prev-page-active.svg",
  PREV_PAGE_INACTIVE: "prev-page-inactive.svg",
  NEXT_PAGE_ACTIVE: "next-page-active.svg",
  NEXT_PAGE_INACTIVE: "next-page-inactive.svg",
  DROPDOWN_ARROW: "dropdown-arrow.svg",
  NOT_CONFIGURED: "not-configured.png",
  START_ACTIVITY_BUTTON_DISABLED: "start-activity-button-disabled.svg",
  START_ACTIVITY_BUTTON_ENABLED: "start-activity-button-active.svg",
  LOADING_SPINNER: "loading-spinner.gif"
};

export { ICON_CONSTANTS as default };
