import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'whatwg-fetch';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import Select from 'react-super-select';
import _ from 'lodash';

import {
  Icon,
  Table,
  Grid,
  Divider,
  Image,
  Loader,
  Container,
  List,
  Form,
  Label,
} from 'semantic-ui-react';
import lAndT from '../../../semanticUi/images/Lnt_realty_logo.png';
import { stage1UpdateUnitDataDetail } from '../../../actions';

class UnitInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unitInfoDetail: [],
      errors: {},
      isLoading: true,
      hasErrored: false,
    };
    this.updateUnitDataDetail = this.updateUnitDataDetail.bind(this);
  }

  updateUnitDataDetail = function(options) {
    localStorage.setItem('stage1_unitSelectedId', options.id);
    localStorage.setItem('stage1_unitSelectedName', options.title);
    this.props.stage1UpdateUnitDataDetail(options.id).then(
      res => {
        // console.log(res);
        this.setState.isLoading = false;
        this.setState.hasErrored = false;
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
  };

  render() {
    const {
      unitInfoDetail,
      unitDataDetail,
      isLoading,
      hasErrored,
      errors,
    } = this.props;
    let selectedBlock;
    if (localStorage.getItem('stage1_unitSelectedName')) {
      selectedBlock = localStorage.getItem('stage1_unitSelectedName');
    }

    if (isLoading) {
      return (
        <div>
          <Loader active indeterminate className="centered">
            Updating units...
          </Loader>
        </div>
      );
    }
    if (_.isEmpty(unitInfoDetail) && _.isEmpty(unitDataDetail) && !isLoading) {
      return (
        <Container fluid textAlign="center">
          <Divider className="print-hide" />
          <p>No units found!</p>
          <Divider hidden section />
          <Divider hidden section />
          <Divider hidden section />
        </Container>
      );
    }
    if (hasErrored) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again
            {errors}
          </div>
        </div>
      );
    }
    let unit_title = '';
    if (!_.isEmpty(unitInfoDetail)) {
      if (
        localStorage.getItem('stage1_unitSelectedId') &&
        localStorage.getItem('stage1_unitSelectedId') !== 'undefined'
      ) {
        const unitElements = this.props.unitInfoDetail;
        const units = [];
        for (let i = 0; i < unitElements.length; i++) {
          units[i] = unitElements[i].id;
        }
        unit_title =
          unitElements[
            units.indexOf(localStorage.getItem('stage1_unitSelectedId'))
          ].title;
      }
    }
    let info_LTR_Tower_In_Charge = '';
    let info_LTC_Tower_In_Charge = '';
    let info_date = '';
    if (!_.isEmpty(unitInfoDetail)) {
      unitInfoDetail.map(data => {
        if (data.id === localStorage.getItem('stage1_unitSelectedId')) {
          info_LTR_Tower_In_Charge = data.LTR_Tower_In_Charge;
          info_LTC_Tower_In_Charge = data.LTC_Tower_In_Charge;
          info_date = data.Date;
        }
        return true;
      });
    }
    return (
      <Grid columns="equals">
        <Grid.Row className="print-hide">
          <Form className="dropDownForm print-hide">
            <Form.Field>
              <Label>Unit</Label>
              <Select
                placeholder={selectedBlock || 'Please Select A Unit'}
                dataSource={unitInfoDetail}
                onChange={this.updateUnitDataDetail}
                clearSearchOnSelection
                deselectOnSelectedOptionClick={false}
                searchable
                clearable={false}
                optionLabelKey="title"
              />
            </Form.Field>
          </Form>
        </Grid.Row>
        <List size="medium" className="printCard">
          <Grid>
            <Grid.Column textAlign="left" width={4}>
              <List.Item>
                <Image src={lAndT} size="medium" />
              </List.Item>
            </Grid.Column>
            <Grid.Column textAlign="left" width={12}>
              <List.Item>
                <h2 className="header">STAGE 1 - CERTIFICATE</h2>
              </List.Item>
            </Grid.Column>
            {/* <Grid.Column textAlign="left" width={4}> */}
            {/* <List.Item> */}
            {/* <p>Doc No : LTR/STAGE CERTIFICATE-2/34</p> */}
            {/* <p>Rev No : 0</p> */}
            {/* <p>Rev Date : 31/03/2016</p> */}
            {/* </List.Item> */}
            {/* </Grid.Column> */}
          </Grid>
          <Divider />
        </List>
        <List size="medium">
          <Grid>
            <Grid.Column textAlign="left" width={10}>
              <List.Item>
                <h3>
                  <b>Floor- {unit_title}</b>
                </h3>
              </List.Item>
            </Grid.Column>
            <Grid.Column textAlign="right" width={6}>
              <List.Item>
                <h3>
                  <b>
                    Date -{' '}
                    {moment(info_date)
                      .tz('Asia/Calcutta')
                      .format('DD-MM-YYYY')}
                  </b>
                </h3>
              </List.Item>
            </Grid.Column>
          </Grid>
          <Divider />
        </List>
        <Grid.Row fluid>
          {unitDataDetail.length ? (
            <Container fluid>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Sn.</Table.HeaderCell>
                    <Table.HeaderCell>Activities</Table.HeaderCell>
                    <Table.HeaderCell>YES</Table.HeaderCell>
                    <Table.HeaderCell>No</Table.HeaderCell>
                    <Table.HeaderCell>N/A</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {unitDataDetail.map((data, index) => (
                    <Table.Row>
                      <Table.Cell>
                        <p>{index + 1}</p>
                      </Table.Cell>
                      <Table.Cell>
                        <p>{data.inspection_item_title}</p>
                      </Table.Cell>
                      <Table.Cell>
                        {data.status === 'CLOSE' ? (
                          <Icon name="checkmark" />
                        ) : (
                          <p />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <p>
                          {data.status === 'OPEN' ? (
                            <Icon name="checkmark" />
                          ) : (
                            <p />
                          )}
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <p>
                          {data.status === '' ? (
                            <Icon name="checkmark" />
                          ) : (
                            <p />
                          )}
                        </p>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <Table celled className="printCard">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Observations/Remarks</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <Divider hidden />
                      <Divider hidden />
                      <Divider hidden />
                      <Divider hidden />
                      <Divider hidden />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <List size="medium" className="printCard">
                <Grid>
                  <Grid.Column floated="left" width={4}>
                    <List.Item>
                      <h3 className="sub-header">LTR Tower In-charge</h3>
                    </List.Item>
                    <List.Item>
                      <h4>Sig </h4>
                    </List.Item>
                    <br />
                    <List.Item>
                      <h4>{info_LTR_Tower_In_Charge}</h4>
                    </List.Item>
                  </Grid.Column>
                  <Grid.Column floated="left" width={4}>
                    <List.Item>
                      <h3 className="sub-header">LTC Tower In-charge</h3>
                    </List.Item>
                    <List.Item>
                      <h4>Sig </h4>
                    </List.Item>
                    <br />
                    <List.Item>
                      <h4>{info_LTC_Tower_In_Charge}</h4>
                    </List.Item>
                  </Grid.Column>
                </Grid>
              </List>
            </Container>
          ) : (
            <Container fluid textAlign="center">
              <Divider className="print-hide" />
              <p>No data found!</p>
              <Divider hidden section />
              <Divider hidden section />
              <Divider hidden section />
            </Container>
          )}
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  unitDataDetail: state.unitInfoStage.unitInfoStage,
  unitInfoDetail: state.unitDataDetailStage.unitDataDetailStage,
  towerInfoDetail: state.towerInfoDetail.towerInfoDetail,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
  propertySelected: state.property.propertySelected,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      stage1UpdateUnitDataDetail,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnitInfo);
