import React from 'react';
import DayPicker, { DateUtils, ModifierUtils } from 'react-day-picker';
import PropTypes from 'prop-types';
import 'react-day-picker/lib/style.css';
import styles from './DatePicker.module.css';

export default class DatePicker extends React.Component {
  static defaultProps = {
    numberOfMonths: 1,
  };

  state = {
    ...this.props.selection[0],
  };

  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    // this.state = this.getInitialState();
  }

  handleDayClick(day, mod = null, event) {
    event.stopPropagation();
    event.preventDefault();
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
    if (this.state.from) this.props.handlers.dateCallBack([range]);
  }

  handleResetClick(event) {
    event.stopPropagation();
    this.setState({ from: undefined, to: undefined });
    this.props.handlers.dateCallBack([]);
  }

  render() {
    const { numberOfMonths, handlers } = this.props;
    // console.log(this.state);
    // console.log(this.props.selection);
    const { from, to } = this.state;
    // const modifiers = { start: from, end: to };
    return (
      <div className={styles.datePicker} onClick={e => e.stopPropagation()}>
        <p>
          {!from && !to && 'Please select the first day.'}
          {from && !to && 'Please select the last day.'}
          {from &&
            to &&
            `Selected from ${from.toLocaleDateString()} to
                ${to.toLocaleDateString()}`}{' '}
          {from &&
            to && (
              <button type="button" className="link" onClick={this.handleResetClick}>
                Reset
              </button>
            )}
        </p>
        <DayPicker
          className={styles.datePicker}
          numberOfMonths={numberOfMonths}
          selectedDays={[from, { from, to }]}
          //   modifiers={modifiers}
          onDayClick={this.handleDayClick}
          onWeekClick={this.handleMonthChange}
          // canChangeMonth={false}
        />
      </div>
    );
  }
}

DatePicker.propTypes = {
  numberOfMonths: PropTypes.number,
};

DatePicker.defaultProps = {
  type: 'DatePicker',
};
