import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { PortalCoreIcon as Icon, Title } from "@falconbrick/fbt-web-components";
import styles from "./ActivityManagerLog.module.css";
import * as activityActions from "../../Actions/ActivityManagerLogActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import activityManagerLog from "../../Reducers/activityManagerLog";

class ActivityManagerLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startActivityModal: false,
      unitsForDisplay: ""
    };
    this.layout = false;
    this.extVH = 0;
    this.startActivity = this.startActivity.bind(this);
  }

  componentWillMount() {
    const { propertySelected, dispatch } = this.props;
    const requestData = { propertySelected: propertySelected.id };
    dispatch(activityActions.getInitalData(requestData));
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch } = this.props;
    if (
      JSON.stringify(this.props.propertySelected) !==
      JSON.stringify(nextProps.propertySelected)
    ) {
      const { propertySelected } = nextProps;
      const requestData = { propertySelected: propertySelected.id };
      dispatch(activityActions.getInitalData(requestData));
    }
    if (
      this.container &&
      this.container.scrollHeight &&
      nextProps.activeUnitType !== this.props.activeUnitType
    ) {
      this.container.scrollTop = 0;
    }
  }

  modalCancel() {
    this.setState({
      startActivityModal: false
    });
  }

  startActivity(activity) {
    let unitsForDisplay = activity.map(row => {
      return row.title;
    });
    // unitsForDisplay = unitsForDisplay.join(",");
    this.setState({
      startActivityModal: true,
      unitsForDisplay
    });
  }

  render() {
    const { units, loading, error } = this.props;
    const { startActivityModal, unitsForDisplay } = this.state;
    const startActivity = this.startActivity;
    let dates = units;

    const CustomTableCell = withStyles(theme => ({
      head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
      },
      body: {
        fontSize: 14
      }
    }))(TableCell);
    const styles2 = theme => ({
      root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
        overflowX: "auto"
      },
      table: {
        minWidth: 700
      },
      row: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.background.default
        }
      }
    });
    let tableDisplay = []
    Object.keys(dates).map(function(datekey) {
      let activities = dates[datekey];
      Object.keys(activities).map(function(key) {
        let unit = activities[key];
        let date = ""        
        if(datekey != 1){
          date = moment(unit[0].sync_ct).format("Do MMMM YYYY")
        } 
        datekey = 1;
        tableDisplay.push({
          id: unit[0].unit_id, date: date, userName: unit[0].name, activity: unit[0].description,
          count: unit.length, unit: unit
        })
      });
    })
    console.log(tableDisplay)

    return (
      <div className={styles.activity}>
        <div className={styles.titleHeader}>
          <Title titleName="Activity Manager Log" />
        </div>
        
          <div className={styles.logDiv}>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <CustomTableCell>Date</CustomTableCell>
                    <CustomTableCell numeric>User</CustomTableCell>
                    <CustomTableCell numeric>Activities</CustomTableCell>
                    <CustomTableCell numeric>No Of Units</CustomTableCell>
                    <CustomTableCell numeric />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    tableDisplay.map(function(unit){
                      return (
                        <TableRow key={unit.id}>
                          <TableCell>
                            {unit.date}
                          </TableCell>
                          <TableCell numeric>{unit.userName}</TableCell>
                          <TableCell numeric>{unit.activity}</TableCell>
                          <TableCell numeric>{unit.count}</TableCell>
                          <TableCell>
                            <a onClick={() => startActivity(unit.unit)}>Unit List</a>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  }
                  {/* Object.keys(dates).map(function(datekey) {
                    let activities = dates[datekey];
                    return Object.keys(activities).map(function(key) {
                      let unit = activities[key];
                      return (
                        <TableRow key={unit[0].unit_id}>
                          <TableCell>
                            {moment(unit[0].sync_ct).format("Do MMMM YYYY")}
                          </TableCell>
                          <TableCell numeric>{unit[0].name}</TableCell>
                          <TableCell numeric>{unit[0].description}</TableCell>
                          <TableCell numeric>{unit.length}</TableCell>
                          <TableCell>
                            <a onClick={() => startActivity(unit)}>Unit List</a>
                            {
                              //   activity.map(row => {
                              //   return "    ======== " + row.title;
                              // })
                            }
                          </TableCell>
                        </TableRow>
                      );
                    });
                  }) */}
                </TableBody>
              </Table>
            </Paper>
          </div>
          {error && (
            <div>
              <p className={styles.loadingMsg}> {error} </p>
              <p style={{ textAlign: "center" }}>
                Please check your Internet connection, and then try refreshing the
                browser.{" "}
              </p>
            </div>
          )}
          {loading && 
            <p className={styles.loadingMsg}> {this.props.loadingMsg}</p>
          }

        {startActivityModal && (
          <div className={styles.overlay}>
            <div className={styles.overlayMain}>
              <div
                className={styles.overlayCancel}
                onClick={() => this.modalCancel()}
                role="button"
                tabIndex={0}
              >
                Back
              </div>
              <div className={styles.overlayContent}>
                {startActivityModal ? unitsForDisplay.map(function(unit){
                  return (<p> {unit} </p>) 
                }
                ) : <p />}
              </div>
              <div
                className={styles.overlayAction}
                onClick={() => this.modalCancel()}
                role="button"
                tabIndex={0}
              >
                Back
              </div>
            </div>
          </div>
        )}
        <div
          ref={c => {
            this.container = c;
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { activityManagerLog, property, blockInfo } = state;
  return {
    propertySelected: property.propertySelected,
    blockInfo,
    units: activityManagerLog.units,
    blockSelected: blockInfo.blockSelected,
    blockList: blockInfo.blockList,
    loading: activityManagerLog.loading,
    loadingMsg: activityManagerLog.loadingMsg,
    error: activityManagerLog.error || blockInfo.error,
    noResult: activityManagerLog.noResult
  };
};
export default connect(mapStateToProps)(ActivityManagerLog);
