import cx from 'classnames';
import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import styles from './SelectionBox.module.css';

class SelectionBox extends Component {
  constructor() {
    super();
    this.state = {
      value: 0,
      hide: true,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  showSelection() {
    this.setState({
      hide: false,
    });
  }

  hideSelection() {
    this.setState({
      hide: true,
    });
  }

  changeSelection(e, i) {
    e.stopPropagation();
    this.state.value !== i ? this.props.callBack(i) : null;
    this.setState({
      value: i,
      hide: true,
    });
  }

  handleChange = event => {
    // event.stopPropagation();
    const value = event.target.value;
    if (this.state.value !== value) {
      this.props.callBack(value);
      this.setState({ [event.target.name]: value, value: value });
    }
    // this.props.activeUnitType !== i ? this.props.callBack(i) : null;
    // this.setState({
    //   value: i,
    //   hide: true,
    // });
  };

  render() {
    const { data, label } = this.props;
    const { value, hide } = this.state;
    const selectionStyle = hide ? cx(styles.selection, styles.hide) : cx(styles.selection, styles.show);
    return (
      <div
        className={styles.selectionBox}
        // onMouseOver={() => this.showSelection()}
        // onMouseOut={() => this.hideSelection()}
        ref={node => {
          this.node = node;
        }}
      >
        <FormControl className={styles.formControl}>
          <InputLabel shrink htmlFor="age-label-placeholder">
            {label}
          </InputLabel>

          <Select
            className={styles.selectControl}
            value={value}
            onChange={this.handleChange}
            name={label}
          >
            {data.length > 0 ? (
              data.map((n, i) => <MenuItem key={n.id} value={n.id}>{n.name}</MenuItem>)
            ) : (
                <MenuItem value="loading">Loading</MenuItem>
              )}
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default SelectionBox;
