import Settings from './Settings';
import Portal from './Portal';
import LandingLayout from './LandingLayout';

const Layouts = {
  Settings,
  Portal,
  LandingLayout,
};

export default Layouts;
