import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { unitInfoGet, updateUnitInfo } from "../../actions";

import Select from "react-super-select";
import _ from "lodash";
import {
  Label,
  List,
  Header,
  Grid,
  Divider,
  Loader,
  Breadcrumb,
  Container,
  Form
} from "semantic-ui-react";
import UnitInfo from "./UnitInfo";

class TowerInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      towerInfo: [],
      errors: {},
      isLoading: true,
      hasErrored: false,
      active: 0,
      selectedOption: ""
    };
    this.unitInfoUpdate = this.unitInfoUpdate.bind(this);
  }

  componentDidMount() {
    const { propertySelected } = this.props;
    // console.log(this.props);
    // console.log(this.state);
    let blockSelected = "";
    if (localStorage.getItem("blockSelectedId")) {
      blockSelected = localStorage.getItem("blockSelectedId");
    }
    const requestData = {
      project: propertySelected,
      blockSelected
    };
    // console.log(projectSelected + blockSelected);
    this.props.unitInfoGet(requestData).then(
      res => {
        // console.log(res);
        this.setState.isLoading = false;
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.props.propertySelected) !==
      JSON.stringify(nextProps.propertySelected)
    ) {
      // const { propertySelected } = this.props;
      // console.log(this.props);
      // console.log(this.state);
      const blockSelected = "";
      const requestData = {
        project: nextProps.propertySelected,
        blockSelected
      };
      // console.log(projectSelected + blockSelected);
      this.props.unitInfoGet(requestData).then(
        res => {
          // console.log(res);
          this.setState.isLoading = false;
        },
        err =>
          this.setState({
            errors: err.response,
            hasErrored: true
          })
      );
    }
  }

  unitInfoUpdate = function(options) {
    if (options) {
      localStorage.setItem("blockSelectedName", options.name);
      localStorage.setItem("blockSelectedId", options.id);
    }
    this.props.updateUnitInfo(options.id).then(
      res => {
        this.setState.isLoading = false;
        this.setState.hasErrored = false;
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
  };

  render() {
    const {
      profile,
      towerInfo,
      isLoading,
      hasErrored,
      errors,
      projectSelected
    } = this.props;
    const paths = this.props.location.pathname.split("/").map((p, i, arr) => {
      if (p === "blockInfo") {
        if (localStorage.getItem("blockSelectedName")) {
          p = localStorage.getItem("blockSelectedName");
        } else {
          p = "Block info";
        }
      }
      if (i === 0)
        return {
          key: i,
          content: <p>{projectSelected.name}</p>,
          active: i === arr.length - 1,
          link: i < arr.length - 1
        };
      if (i === 3)
        return {
          key: i,
          content: "",
          active: i === arr.length - 1,
          link: i < arr.length - 1
        };
      if (i === arr.length - 1)
        return {
          key: i,
          content: p,
          active: i === arr.length - 1
        };
      return {
        key: i,
        content: (
          <Link to={`${arr.slice(0, i + 1).join("/")}`}>
            {p.charAt(0).toUpperCase() + p.slice(1)}
          </Link>
        ),
        active: i === arr.length - 1,
        link: i < arr.length - 1
      };
    });
    let selctedBlock;
    if (localStorage.getItem("blockSelectedName")) {
      selctedBlock = localStorage.getItem("blockSelectedName");
    }
    if (isLoading) {
      return (
        <Container textAlign="center">
          <Loader active indeterminate className="centered">
            Getting block info...
          </Loader>
        </Container>
      );
    }
    if (_.isEmpty(towerInfo) && !isLoading) {
      return (
        <Container fluid textAlign="center">
          <p>No Block/s found</p>
        </Container>
      );
    }
    if (hasErrored) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again
            {errors}
          </div>
        </div>
      );
    }

    return (
      <div>
        <Divider hidden />
        <List size="medium" className="printCard">
          <List.Item>
            <b>Company - {profile.customer_id}</b>
          </List.Item>
          <br />
          <List.Item>
            <b>User - {profile.username}</b>
          </List.Item>
        </List>
        <Breadcrumb icon="chevron right" sections={paths} size="big" />
        <Header as="h2">Block Status </Header>
        <Divider />
        <Grid columns="equal" padded>
          <Grid.Row>
            <Form className="dropDownForm">
              <Form.Field>
                <Label>Block</Label>
                <Select
                  placeholder={selctedBlock || "Please Select A Block"}
                  dataSource={towerInfo}
                  onChange={this.unitInfoUpdate}
                  clearSearchOnSelection
                  deselectOnSelectedOptionClick={false}
                  searchable
                  clearable={false}
                />
              </Form.Field>
            </Form>
          </Grid.Row>
          <Grid.Row>
            <UnitInfo />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  profile: state.authManager.user,
  towerInfo: state.towerInfo.towerInfo,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
  projectSelected: state.project.projectSelected,
  propertySelected: state.property.propertySelected.id
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      unitInfoGet,
      updateUnitInfo
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TowerInfo);
