import React, { Component } from "react";
import {
  PortalCoreIcon as Icon,
  GridContainer,
  GridItem
} from "@falconbrick/fbt-web-components";
import styles from "./LegendTable.module.css";

const statusIconMap = {
  Complete: "COMPLETED",
  Active: "ONGOING",
  Inactive: "NOT_STARTED",
  NotConfigured: "NOT_CONFIGURED"
};

class IconLegend extends React.Component {
  render() {
    return <Icon name={this.props.name} />;
  }
}

function Legend(obj) {
    return Object.keys(obj).map(k => (
    <GridContainer className={styles.legend} direction={"row"}>
      <div Item className={styles.legendChild}>
                <IconLegend name={obj[k]} />
      </div>
      <GridItem className={styles.legendChild}>
        <p>
                    {obj[k] === "NOT_CONFIGURED"
            ? "N/A"
                        : obj[k]
                .split(/_/)
                .map(
                  word => word[0].toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" ")}
        </p>
      </GridItem>
    </GridContainer>
  ));
}

export default (legend => {
  return class extends React.Component {
    render() {
      return (
        <GridContainer
          className={styles.legend}
          direction={"row"}
          align={"flex-end"}
        >
          {legend(statusIconMap)}
        </GridContainer>
      );
    }
  };
})(Legend);
