import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import styles from './DialogStyle.module.css';


export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open || true
    };
  }
  // static getDerivedStateFromProps(nextProps, prevState){
  //     if(nextProps.open && nextProps.open ==)
  //         }
  toggleShow(flg = false) {
    return e => {
      !!flg ? this.setState({ open: false }) : this.setState({ open: !this.state.open });
    };
  }
  render() {
    const { children, classes, className, opacity, textContent, title, ...rest } = this.props;
    const { open } = this.state;
    return (
      <Dialog
        open={open}
        onClose={this.toggleShow(true)}
        style={{ opacity: opacity || 0.96 }}
        {...rest}
      >
        <DialogTitle
          disableTypography={true}
          className={styles.dialogTitle}
        >
          {title}
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          {!!textContent ?
            (
              <DialogContentText
                classes={styles.dialogContentText}
                className={styles.dialogContentText}>
                {textContent}
              </DialogContentText>
            ) : ' '
          }
          {React.Children.map(children, child => React.cloneElement(child, {
            onClick: !!child.props.toggle ? this.toggleShow(true) : e => {
              this.toggleShow(true)(e);
              if (!!child.props.onClick)
                child.props.onClick(e);
            }
          }))
          }
        </DialogContent>
      </Dialog>

    );
  }
}
