import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-super-select';
import _ from 'lodash';

import {
  Grid,
  Header,
  Divider,
  Loader,
  Container,
  Form,
  Label,
} from 'semantic-ui-react';
import UnitInfoDetail from './UnitInfoDetail';
import {
  stage1TowerInfoGetDetail,
  stage1UpdateTowerInfoDetail,
  stageClear,
} from '../../../actions';

class TowerInfoDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      towerInfoDetail: [],
      errors: {},
      isLoading: true,
      hasErrored: false,
    };
    this.towerInfoDetailUpdate = this.towerInfoDetailUpdate.bind(this);
  }

  componentWillMount() {
    this.props.stageClear();
  }

  componentDidMount() {
    const { propertySelected } = this.props;
    const blockSelected = '';
    const unitSelected = '';
    let requestData = {};
    // if (localStorage.getItem('stage1_blockSelectedId')) {
    //   blockSelected = localStorage.getItem('stage1_blockSelectedId');
    // }
    // if (localStorage.getItem('stage1_unitSelectedId')) {
    //   unitSelected = localStorage.getItem('stage1_unitSelectedId');
    // }
    // if (this.getParameterByName('blockId')) {
    //   blockSelected = this.getParameterByName('blockId');
    //   localStorage.setItem('stageInfo_blockSelectedId', blockSelected);
    // }
    // if (this.getParameterByName('unitId')) {
    //   unitSelected = this.getParameterByName('unitId');
    //   localStorage.setItem('stageInfo_unitSelectedId', unitSelected);
    // }
    requestData = {
      project: propertySelected,
      blockSelected,
      unitSelected,
    };

    this.props.stage1TowerInfoGetDetail(requestData).then(
      res => {
        // console.log(res);
        this.setState.isLoading = false;
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.props.propertySelected) !==
      JSON.stringify(nextProps.propertySelected)
    ) {
      const { propertySelected } = nextProps;
      const blockSelected = '';
      const unitSelected = '';
      let requestData = {};
      requestData = {
        project: propertySelected,
        blockSelected,
        unitSelected,
      };

      this.props.stage1TowerInfoGetDetail(requestData).then(
        res => {
          this.setState.isLoading = false;
        },
        err => this.setState({ errors: err.response, hasErrored: true })
      );
    }
  }

  towerInfoDetailUpdate = function(options) {
    if (options) {
      localStorage.setItem('stage1_blockSelectedName', options.name);
      localStorage.setItem('stage1_blockSelectedId', options.id);
    }

    this.props.stage1UpdateTowerInfoDetail(options.id).then(
      res => {
        // console.log(res);
        this.setState.isLoading = false;
        this.setState.hasErrored = false;
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
  };

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\]]/g, '\\$&');
    let regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  render() {
    const { towerInfoDetail, isLoading, hasErrored, errors } = this.props;
    let selectedBlock;
    if (localStorage.getItem('stage1_blockSelectedName')) {
      selectedBlock = localStorage.getItem('stage1_blockSelectedName');
    }

    if (isLoading) {
      return (
        <div>
          <Loader active indeterminate className="centered">
            Getting Block Data..
          </Loader>
        </div>
      );
    }
    if (_.isEmpty(towerInfoDetail) && !isLoading) {
      return (
        <Container fluid textAlign="center">
          <p>No data found!</p>
          <Divider hidden section />
          <Divider hidden section />
          <Divider hidden section />
        </Container>
      );
    }
    if (hasErrored) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again
            {errors}
          </div>
        </div>
      );
    }

    return (
      <div>
        <Header as="h2" className="print-hide">
          Stage 1 Report
        </Header>
        <Divider className="print-hide" />
        <Divider className="print-hide" hidden />
        <Grid columns="equals" padded>
          <Grid.Row className="print-hide">
            <Form className="dropDownForm print-hide">
              <Form.Field>
                <Label>Block</Label>
                <Select
                  placeholder={selectedBlock || 'Please Select A Block'}
                  dataSource={towerInfoDetail}
                  onChange={this.towerInfoDetailUpdate}
                  clearSearchOnSelection
                  deselectOnSelectedOptionClick={false}
                  searchable
                  clearable={false}
                />
              </Form.Field>
            </Form>
          </Grid.Row>
          <Grid.Row>
            <UnitInfoDetail {...this.props} />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  towerInfoDetail: state.towerInfoDetail.towerInfoDetail,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
  propertySelected: state.property.propertySelected,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      stage1TowerInfoGetDetail,
      stage1UpdateTowerInfoDetail,
      stageClear,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TowerInfoDetails);
