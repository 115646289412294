import * as types from '../constants';

const initialState = {
  blockInfo: [],
  blockSelected: {},
  loading: true,
  error: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.BLOCK_INFO_FETCH:
      return { ...state, loading: true, error: null };
    case types.BLOCK_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        blockInfo: action.payload.blockInfo,
      };
    case types.BLOCK_INFO_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case types.BLOCK_INFO_SET:
      return {
        ...state,
        loading: false,
        error: null,
        blockSelected: action.payload.selected,
      };
    default:
      return state;
  }
};
