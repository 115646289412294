import isEmpty from 'lodash/isEmpty';
import * as types from '../constants/Auth';

const initialState = {
  isAuthenticated: false,
  loading: false,
  error: null
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_CURRENT_USER_FETCH:
      return { ...state, loading: true, error: null };
    case types.SET_CURRENT_USER_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case types.SET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: !isEmpty(action.payload.user),
        user: action.payload.user
      };
    default:
      return state;
  }
};
