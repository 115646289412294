import React, { Component } from 'react';
import _ from 'lodash';
import styles from './PlotActivities.module.css';
import ActivityBlcok from './ActivityBlock';

class PlotActivities extends Component {
  constructor() {
    super();
    this.getActivitiesList = this.getActivitiesList.bind(this);
    this.getActivitiesPlot = this.getActivitiesPlot.bind(this);
    this.state = {
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const propsCompare = {
      pre: { activities: prevProps.activities, noResult: prevProps['noResult'] },
      post: { activities: this.props.activities, noResult: this.props['noResult'] }
    };
    if (!_.isEqual(propsCompare.pre, propsCompare.post)) {
      const { activities, selectedActivities, loading } = this.props;
      const activitiesList = this.getActivitiesPlot(activities, selectedActivities, loading);
      if (this.props['noResult'] === 'Unit')
        this.setState({ noResultsVal: 'Unit' });
      else if (activitiesList.length === 0)
        this.setState({ noResultsVal: 'Activity' });
      else
        this.setState({ noResultsVal: null });
    }
    if (this.state['noResultsVal'] !== this.props['noResult']) {
      this.props.noResultsIn(this.state['noResultsVal']);
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps['noResult'] === 'Unit' && !!!prevState['noResultsVal'])
      return { noResultsVal: 'Unit' };
    if (!!!nextProps['noResult'] && prevState['noResultsVal'] === 'Unit')
      return { noResultsVal: null };
    return prevState;
  }

  getActivitiesList(activities, selectedActivities, loading) {
    if (loading)
      return ["Loading Activities..."];
    if (!!!activities)
      return ["Loading Activities..."];
    return activities.length > 0 ? activities.sort(this.props.sortFunction(activities)).map((act) => (
      <ActivityBlcok
        activity={act}
        selected={selectedActivities.includes(act) || false}
      />)) : [];
  }

  getActivitiesPlot(activities, selectedActivities, loading) {
    const activityList = this.getActivitiesList(activities, selectedActivities, loading);
    const activitiesPlot = activityList && (activityList.filter(act => (!!act)).length > 0) ? activityList : [];
    return activitiesPlot;
  }

  render() {
    const { activities, selectedActivities, loading, data, units, sortFunction } = this.props;
    const activitiesPlot = this.getActivitiesPlot(activities, selectedActivities, loading);
    return (
      <div className={styles.PlotActivities}>
        {activitiesPlot}
      </div>
    );
  }
}

export default PlotActivities;
