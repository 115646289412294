import {
  UNIT_ACTIVITY_INFO_FETCH,
  UNIT_ACTIVITY_INFO_SUCCESS,
  UNIT_ACTIVITY_INFO_FAILURE
} from "../actions";

const initialState = {
  unitActivityInfo: {},
  loading: false,
  error: null
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case UNIT_ACTIVITY_INFO_FETCH:
      return { ...state, loading: true, error: null };
    case UNIT_ACTIVITY_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        unitActivityInfo: action.payload.unitActivityInfo
      };
    case UNIT_ACTIVITY_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        unitActivityInfo: {}
      };
    default:
      return state;
  }
};
