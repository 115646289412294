import fetch from 'isomorphic-fetch';
import * as types from './constants';

let timer = null;

export function showNotification(response, constantMessage, notificationCloseDelay, sticky, hideClose) {
  return {
    type: types.FIRE_NOTIFICATION,
    response,
    constantMessage: `${constantMessage}`,
    delay: notificationCloseDelay,
    sticky,
    hideClose
  };
}

function parseJSON(response) {
  return response.text().then((text) => text ? JSON.parse(text) : {});
}

function responseIterceptor(response, apiConstant, successHandler, errorHandler, showError, notificationCloseDelay) {
  return dispatch => {
    if (response.status === 200 && successHandler) {
      parseJSON(response).then((json) => {
        dispatch(successHandler(json,response));
      });
    } else {
      if (errorHandler) dispatch(errorHandler(response));
      if (showError) {
        dispatch(showNotification(response, apiConstant, notificationCloseDelay));
      }
    }
  };
}

function wait(delay = 0) {
    return new Promise((resolve, reject) => {
        timer = setTimeout(resolve, delay);
    });
}

// Extend the promise-based `wait` by throwing
// an Error if the delay is successful
function timeout(delay=3000) {
    return wait(delay).then(() => {
        throw new Error('Timed Out!');
    });
}

function fetchWithTimeout(url, options, delay=3000) {
    // construct an array to pass to `Promise.race`
    return Promise.race([
        fetch(url, options),
        timeout(delay)
    ]);
}

export function makeRequest(
  url, options, apiConstant, loaderHandler, successHandler, errorHandler, showError = true, notificationCloseDelay = 4000) {
  return dispatch => {
    
    if (loaderHandler) {
      dispatch(loaderHandler());
    }

    if (!options['credentials']) {
      options['credentials'] = 'same-origin';
    }

    if(window.serviceConfig && window.serviceConfig.sessionDegraded){
      options['headers'] = Object.assign({}, options['headers']);
      options['headers']['sessionDegraded'] = true;
    }

    fetchWithTimeout(url, options, 12000)
    .then(response => {
        // successful response before the timeout
        dispatch(responseIterceptor(response, apiConstant, successHandler, errorHandler, showError, notificationCloseDelay));
        clearTimeout(timer);
    })
    .catch(e => {
        // Either the timeout occurred or some other error.
        clearTimeout(timer);
        if(logException){
          logException(e,{ debugMsg: "Network Connection, Fetch Failed from sa-widgets-core/ApiManager/makeRequest" });
        }
        if (errorHandler) dispatch(errorHandler(e));
        console.log('request error', e);
    });
  }
}
