import axios from 'axios';
import _ from 'lodash';
import setAuthorizationToken from '../container/utils/setAuthorizationToken';
import config from '../configs/clientConfig';

import * as nav from '../constants/Nav';

// nav bar

export const navInfoFetch = () => ({ type: nav.NAV_INFO_FETCH });

export const navInfoSuccess = navInfo => ({
  type: nav.NAV_INFO_SUCCESS,
  payload: { navInfo },
});

export const navInfoFailure = error => ({
  type: nav.NAV_INFO_FAILURE,
  payload: { error },
});

export function navInfoGet(data) {
  return dispatch => {
    dispatch(navInfoFetch());
    return axios
      .get(`${config.ApiUrl}/api/groups/dashboards`)
      .then(res => {
        // console.log(res.data);
        const sortData = _.orderBy(res.data.groups, ['id', 'asc']);
        localStorage.setItem('navInfo', JSON.stringify(sortData));
        dispatch(navInfoSuccess(sortData));
      })
      .catch(error => dispatch(navInfoFailure(error.response.data)));
  };
}
