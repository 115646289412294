/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component, PureComponent, Fragment } from "react";
import moment from "moment";
import ReactTable from "react-table";
import matchSorter from "match-sorter";
import _ from "lodash";
import "react-table/react-table.css";
import { Icon, Grid, Image } from "semantic-ui-react";
import { MultiSelectionBox } from "@falconbrick/fbt-web-components";
import Modal from "@material-ui/core/Modal";
import imgNotAvail from '../../resources/images/img-not-available.svg';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import styles from "./TowerSnagInfo.module.css";
import EXIF from "exif-js";
import Lightbox from "react-image-lightbox";
import { stageUpdateTowerInfoDetail } from "../../actions";
let rotateStyle = {};
const isChrome =
  !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const isEdge = window.navigator.userAgent.indexOf("Edge") > -1;
// const imgURL = "https://s3.ap-south-1.amazonaws.com/com.fcb.ho.stag/brigade/";
const imgURL = "https://s3.snags.falconbrick.com/brigade/";
function classifier() {
  const addendum = !!this.flag ? styles.sizeImg : "";
  if (this.rotateValue === 6) return `${styles.rotateForSix} ${addendum}`;
  if (this.rotateValue === 8) return `${styles.rotateForEight} ${addendum}`;
  if (this.rotateValue === 3) return `${styles.rotateForThree} ${addendum}`;
  return `${styles.rotateForOne} ${addendum}`;
}
class MyImage extends PureComponent {
  state = {
    rotateValue: 1
  };
  imageRef = React.createRef(null);
  handleLoad = node => {
    if (!!!node) console.log("Aaaa");
    else {
      EXIF.getData(node, () => {
        const orient = EXIF.getTag(node, "Orientation");
        this.setState(state => ({ rotateValue: Number.parseInt(orient) }));
      });
    }
  };
  assignCorrectClass = () =>
    classifier.call({ rotateValue: this.state.rotateValue, flag: true });
  setImage = event => {
    event.preventDefault();
    this.props.handleImageClick(this.props.value, this.state.rotateValue);
  };
  componentDidMount() {
    (isChrome || isEdge) &&
      setTimeout(this.handleLoad, 600, this.imageRef.current);
  }
  render() {
    const { value } = this.props;
    const { rotateValue } = this.state;
    if (!!!value || value === "")
      return <Icon disabled name="image" className="print-hide" />;

    return (
      <div
        className={this.assignCorrectClass()}
        style={{ width: this.props.width || 120 }}
      >
        <div>
          <img
            src={`${imgURL}${value}`}
            size="medium"
            onClick={this.setImage}
            id={`${value}`}
            ref={this.imageRef}
          />
        </div>
      </div>
    );
  }
}
const {
  Sort,
  Filter,
  Search,
  MultiSelection,
  DatePicker,
  Select
} = MultiSelectionBox({
  main: "MultiSelection",
  apply: "Select",
  search: "Search",
  filter: "Filter",
  sort: "Sort",
  datePicker: "DatePicker"
});
function sortedFlagger(id) {
  const isSorted = this.state.sorted.filter(elt => elt.id === id)[0];
  if (!isSorted) return null;
  return isSorted.desc ? "dsc" : "asc";
}
const makeDefaultState = resetFlag => ({
  sorted: [],
  page: 0,
  pageSize: 10,
  expanded: {},
  resized: [],
  filtered: [],
  reset: resetFlag || false,
  openDialog: false,
  showSnagComment: false,
  isOpen: false,
  Image: null
  // getResolvedList: this.getResolvedList(),
  // localTowerSnagInfo: this.props.towerSnagInfo,
});

class TowerSnagTable extends React.Component {
  constructor() {
    super();
    this.state = makeDefaultState();
    this.resetState = this.resetState.bind(this);
    this.sortType = sortedFlagger.bind(this);
    this.printAll = this.printAll.bind(this);
    this.reactTable =  React.createRef();
    //this.handleLoad = this.handleLoad.bind(this);
    // this.sort = this.sort.bind(this);
    // this.getResolvedList = this.getResolvedList.bind(this);
  }

  // getResolvedList = data => {
  //   console.log(data);
  // };

  sort = (id, e) => {
    let newState = [{ id, desc: true }];
    if (e === "asc") {
      newState = [{ id, desc: false }];
    }
    this.setState({ sorted: newState, reset: false });
    // console.log(this.state);
  };

  printAll = length => {
    // console.log(length);
    const downloadLink = document.getElementById("pfdAll");
    downloadLink.className = "ui right floated loading disabled button medium";
    // downloadLink.innerHTML = 'Loading';
    this.setState({ pageSize: length }, () => {
      this.handleClose();
      setTimeout(() => {
        window.print();
        downloadLink.className = "ui right floated button medium";
        // downloadLink.innerHTML = `Print All ${length}`;
      }, 15000);
      // window.onload = function() {
      // };
    });
  };

  applyFilter = (id, value) => {
    // console.log(id);
    // console.log(value);
    const { filtered } = this.state;
    let temp = filtered;
    const tempIndex = _.findIndex(filtered, { id });
    // switch (tempIndex) {
    //   case 0:
    //     if (value.length === 0) {
    //       temp = [];
    //     } else {
    //       temp = [{ id, value }];
    //     }
    //     break;
    //   case 1:
    //     value.length === 0
    //       ? temp.splice(tempIndex, 1)
    //       : (temp[tempIndex].value = value);
    //     break;
    //   case -1:
    //     value.length === 0 ? null : temp.push({ id, value });
    //     console.log(temp);
    //     break;
    //   default:
    //     value.length === 0
    //       ? temp.splice(tempIndex, 1)
    //       : temp.push({ id, value });
    // }
    if (tempIndex === 0) {
      if (value.length === 0) {
        temp = [];
      } else {
        temp = [{ id, value }];
      }
    } else if (tempIndex > 0) {
      value.length === 0
        ? temp.splice(tempIndex, 1)
        : (temp[tempIndex].value = value);
    } else if (tempIndex === -1) {
      value.length === 0 ? null : temp.push({ id, value });
    } else {
      value.length === 0 ? temp.splice(tempIndex, 1) : temp.push({ id, value });
    }
    this.setState({ filtered: temp, reset: false });
    // console.log(this.state);
    // console.log(filtered);
  };

  resetState = () => {
    this.setState(makeDefaultState(true));
  };

  handleClickOpen = () => {
    this.setState({ openDialog: true });
  };

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  setSnagComment = () => {
    if (this.state.showSnagComment) this.setState({ showSnagComment: false });
    else this.setState({ showSnagComment: true });
  };

  // handleImageClick = (img, orientation) => {
  //   console.log(isEdge);
  //   this.setState(state => ({
  //     isOpen: true,
  //     Image: () => (
  //       <Lightbox
  //         mainSrc={`${imgURL}${img}`}
  //         onCloseRequest={() => this.setState({ isOpen: false, Image: null })}
  //         wrapperClassName={classifier.call({ rotateValue: orientation })}
  //         reactModalProps={{ width: "50%" }}
  //       />
  //     )
  //   }));
  // };

  handleImageClick = (img) => {
    this.setState(state => ({
      isOpen: true,
      Image: () => (
        <Lightbox
          mainSrc={`https://s3.ap-south-1.amazonaws.com/com.fcb.ho.stag/large/${img}`}
          onCloseRequest={() => this.setState({ isOpen: false, Image: null })}
        />
      )
    }));
  };

  snagCount = (towerSnagInfo, screenName) => {
    console.log(this.state.sortedData)
    return (
      <p className={styles.snagCount}>
               {screenName}{ 
              // filtered.length > 0 ? 
              // (<span>{filtered.length <= 0 ? <b>0</b> : <b>{(((this.state.page+1)*this.state.pageSize) - this.state.pageSize) + 1}</b>} - <b>{(this.state.page+1)*this.state.pageSize < filtered.length ?  (this.state.page+1)*this.state.pageSize : filtered.length}</b> of <b>{filtered.length}</b></span>)  
              // : 
              <span> {towerSnagInfo.length <= 0 ? <b>0</b> : <b>{(((this.state.page+1)*this.state.pageSize) - this.state.pageSize) + 1}</b>} - <b>{(this.state.page+1)*this.state.pageSize < towerSnagInfo.length ?  (this.state.page+1)*this.state.pageSize : towerSnagInfo.length}</b> of <b>{towerSnagInfo.length}</b></span>}
      </p>
    )
  }

  render() {
    const { towerSnagInfo, pageOptions, isLoading, error, resolvedData, screenName } = this.props;
    const { filtered, Image } = this.state;
    if (error) {
      return <p>{error}</p>;
    }
    // no need to do this
    // const localTowerSnagInfo = _.filter(towerSnagInfo, { unit_title: 'C-401' });
    // const fitlerValues = [];
    // let localTowerSnagInfo = [];
    // if (towerSnagInfo.length > 0) {
    //   _.forEach(fitlerValues, value => {
    //     // console.log(value);
    //     localTowerSnagInfo = localTowerSnagInfo.concat(
    //       _.filter(towerSnagInfo, o => o.unit_title === value)
    //     );
    //   });
    // // }
    // if(!isLoading){
    //   console.log("resolvedData",this.reactTable)

    // }
   

    return (
      <div>
       
        <div className={`${styles.resetButton} print-hide`}>
              {this.snagCount(towerSnagInfo, screenName)}
              <p onClick={this.resetState} className={styles.resetFilterStyle}> Reset All Filters </p>
              <p onClick={this.setSnagComment} className={styles.showCommentStyle}>
                  {" "}{this.state.showSnagComment ? "Hide" : "Show"} Comments
              </p>
        </div>
        {!!Image ? <Image /> : null}
        <Fragment>
          <Grid columns="equal" padded style={{ width: "100%", padding: "0" }}>
            <Grid.Row textAlign="center">
              
              <ReactTable
                ref={r => (this.reactTable = r)}
                data={towerSnagInfo}
                pageSizeOptions={pageOptions}
                filtered={filtered}
                sortedData={this.state.sortedData}
                defaultFilterMethod={(filter, rows) =>
                  filter.value.includes(rows[filter.id])
                }
                columns={[
                  {
                    columns: [
                      {
                        Header: () => (
                          <MultiSelection
                            labelText="Unit Title"
                            id="1"
                            optionsId="unit_title"
                            optionsName="unit_title"
                            sortCallBack={e => this.sort("unit_title", e)}
                            callback={e => this.applyFilter("unit_title", e)}
                            reset={this.state.reset}
                            sortValue={this.sortType("unit_title")}
                          >
                            <Sort>
                              <li label="&uarr; A-z">asc</li>
                              <li label="&darr; Z-a">dsc</li>
                            </Sort>
                            <Filter />
                            <Search />
                            {_.uniqBy(towerSnagInfo, "unit_title").map(list => (
                              <li key={list.id}>{list}</li>
                            ))}
                            <Select>apply </Select>
                          </MultiSelection>
                        ),
                        id: "unit_title",
                        accessor: d => d.unit_title
                      },
                      {
                        Header: () => (
                          <MultiSelection
                            labelText="Activity"
                            id="2"
                            optionsId="activity_name"
                            optionsName="activity_name"
                            sortCallBack={e => this.sort("activity_name", e)}
                            callback={e => this.applyFilter("activity_name", e)}
                            reset={this.state.reset}
                            sortValue={this.sortType("activity_name")}
                          >
                            <Sort>
                              <li label="&uarr; A-z">asc</li>
                              <li label="&darr; Z-a">dsc</li>
                            </Sort>
                            <Filter />
                            <Search />
                            {_.uniqBy(towerSnagInfo, "activity_name").map(
                              list => (
                                <li key={list.id}>{list}</li>
                              )
                            )}
                            <Select>apply </Select>
                          </MultiSelection>
                        ),
                        id: "activity_name",
                        accessor: d => d.activity_name
                      },
                      {
                        Header: () => (
                          <MultiSelection
                            labelText="Raised by"
                            id="1"
                            optionsId="raised_by_username"
                            optionsName="raised_by_username"
                            sortCallBack={e =>
                              this.sort("raised_by_username", e)
                            }
                            callback={e =>
                              this.applyFilter("raised_by_username", e)
                            }
                            reset={this.state.reset}
                            sortValue={this.sortType("raised_by_username")}
                          >
                            <Sort>
                              <li label="&uarr; A-z">asc</li>
                              <li label="&darr; Z-a">dsc</li>
                            </Sort>
                            <Filter />
                            <Search />
                            {_.uniqBy(towerSnagInfo, "raised_by_username").map(
                              list => (
                                <li key={list.id}>{list}</li>
                              )
                            )}
                            <Select>apply </Select>
                          </MultiSelection>
                        ),
                        id: "raised_by_username",
                        accessor: d => d.raised_by_username
                      },
                      {
                        Header: () => (
                          <MultiSelection
                            labelText="Step"
                            id="3"
                            optionsId="step_name"
                            optionsName="step_name"
                            sortCallBack={e => this.sort("step_name", e)}
                            callback={e => this.applyFilter("step_name", e)}
                            reset={this.state.reset}
                            sortValue={this.sortType("step_name")}
                          >
                            <Sort>
                              <li label="&uarr; A-z">asc</li>
                              <li label="&darr; Z-a">dsc</li>
                            </Sort>
                            <Filter />
                            <Search />
                            {_.uniqBy(towerSnagInfo, "step_name").map(list => (
                              <li key={list.id}>{list}</li>
                            ))}
                            <Select>apply </Select>
                          </MultiSelection>
                        ),
                        id: "step_name",
                        accessor: d => d.step_name
                      },
                      {
                        Header: () => (
                          <MultiSelection
                            labelText="Category"
                            id="1"
                            optionsId="category_title"
                            optionsName="category_title"
                            sortCallBack={e => this.sort("category_title", e)}
                            callback={e =>
                              this.applyFilter("category_title", e)
                            }
                            reset={this.state.reset}
                            sortValue={this.sortType("category_title")}
                          >
                            <Sort>
                              <li label="&uarr; A-z">asc</li>
                              <li label="&darr; Z-a">dsc</li>
                            </Sort>
                            <Filter />
                            <Search />
                            {_.uniqBy(towerSnagInfo, "category_title").map(
                              list => (
                                <li key={list.id}>{list}</li>
                              )
                            )}
                            <Select>apply </Select>
                          </MultiSelection>
                        ),
                        id: "category_title",
                        accessor: d => d.category_title
                      },

                      {
                        Header: () => (
                          <MultiSelection
                            labelText="Raised On"
                            id="1"
                            optionsId="sync_ct"
                            optionsName="sync_ct"
                            sortCallBack={e => this.sort("sync_ct", e)}
                            callback={e => this.applyFilter("sync_ct", e)}
                            reset={this.state.reset}
                          // dateCallBack={e => this.applyFilter('sync_ct', e)}
                          >
                            <DatePicker />
                            <Select>apply </Select>
                          </MultiSelection>
                        ),
                        id: "sync_ct",
                        accessor: d => moment(d.sync_ct).format("DD-MM-YYYY"),
                        filterMethod: (filter, row) =>
                          moment(row[filter.id], "DD-MM-YYYY").isBetween(
                            filter.value[0].from,
                            filter.value[0].to,
                            "days",
                            "[]"
                          )
                      },
                      {
                        Header: () => (
                          <MultiSelection
                            labelText="Last Updated"
                            id="2"
                            optionsId="sync_ut"
                            optionsName="sync_ut"
                            sortCallBack={e => this.sort("sync_ut", e)}
                            callback={e => this.applyFilter("sync_ut", e)}
                            reset={this.state.reset}
                          >
                            <DatePicker />
                            <Select>apply </Select>
                          </MultiSelection>
                        ),
                        id: "sync_ut",
                        minWidth: 120,
                        accessor: d => moment(d.sync_ut).format("DD-MM-YYYY"),
                        filterMethod: (filter, row) =>
                          // console.log(moment(row[filter.id], 'DD-MM-YYYY'))
                          moment(row[filter.id], "DD-MM-YYYY").isBetween(
                            filter.value[0].from,
                            filter.value[0].to,
                            "days",
                            "[]"
                          )
                      },
                      {
                        Header: () => (
                          <MultiSelection
                            labelText="Status"
                            id="1"
                            optionsId="status"
                            optionsName="status"
                            sortCallBack={e => this.sort("status", e)}
                            callback={e => this.applyFilter("status", e)}
                            reset={this.state.reset}
                            sortValue={this.sortType("status")}
                          >
                            <Sort>
                              <li label="&uarr; A-z">asc</li>
                              <li label="&darr; Z-a">dsc</li>
                            </Sort>
                            <Filter />
                            <Search />
                            {_.uniqBy(towerSnagInfo, "status").map(list => (
                              <li key={list.id}>{list}</li>
                            ))}
                            <Select>apply </Select>
                          </MultiSelection>
                        ),
                        id: "status",
                        accessor: d => d.status
                      },
                      {
                        Header: () => (
                          <MultiSelection
                            labelText="Room"
                            id="1"
                            optionsId="room_name"
                            optionsName="room_name"
                            sortCallBack={e => this.sort("room_name", e)}
                            callback={e => this.applyFilter("room_name", e)}
                            reset={this.state.reset}
                            sortValue={this.sortType("room_name")}
                          >
                            <Sort>
                              <li label="&uarr; A-z">asc</li>
                              <li label="&darr; Z-a">dsc</li>
                            </Sort>
                            <Filter />
                            <Search />
                            {_.uniqBy(towerSnagInfo, "room_name").map(list => (
                              <li key={list.id}>{list}</li>
                            ))}
                            <Select>apply </Select>
                          </MultiSelection>
                        ),
                        id: "room_name",
                        accessor: d => d.room_name
                      },
                      {
                        Header: () => (
                          <span className={styles.headerWithoutFilter}>
                            Description
                          </span>
                        ),
                        id: "inspection_item_title",
                        accessor: d => d.inspection_item_title,
                        isExpanded: true,
                        minWidth: 200
                      },
                      {
                        Header: () => (
                          <span className={styles.headerWithoutFilter}>
                            Detail
                          </span>
                        ),
                        id: "description",
                        accessor: d => d.description,
                        isExpanded: true,
                        minWidth: 200
                      },

                      // {
                      //   Header: 'Image',
                      //   id: 'attachment',
                      //   accessor: d => d.attachment,
                      //   Cell: ({ value }) =>
                      //     !value || value === '' ? (
                      //       <Icon disabled name="image" className="print-hide" />
                      //     ) : (
                      //       <Image
                      //         src={`https://s3.ap-south-1.amazonaws.com/com.fcb.ho.stag/brigade/${value}`}
                      //         size="medium"
                      //       />
                      //     ),
                      // },
                      {
                        Header: () => (
                          <MultiSelection
                            labelText="Image"
                            id="10"
                            optionsId="attachment"
                            optionsName="attachment"
                            sortCallBack={e => this.sort("attachment", e)}
                            sortValue={this.sortType("attachment")}
                          >
                            <Sort>
                              <li label="&uarr; With Image">dsc</li>
                              <li label="&darr; Without Image">asc</li>
                            </Sort>
                          </MultiSelection>
                        ),
                        id: "attachment",
                        accessor: d => d.attachment,
                        Cell: ({ value }) => (
                          <div className={styles.mainImg}>
                          <center>
                            {/* <MyImage
                              value={value}
                              handleImageClick={this.handleImageClick}
                              width={200}
                            /> */}
                            {value ? 
                            <img src={`https://s3.ap-south-1.amazonaws.com/com.fcb.ho.stag/small/${value}` } onClick={()=>this.handleImageClick(value)} />
                            :
                            <img src={imgNotAvail} className={styles.imgNotAvail}/>
                            }
                          </center>
                          </div>
                        ),
                        isExpanded: false,
                        minWidth: 300
                      },
                      {
                        Header: () => (
                          <span className={styles.headerWithoutFilter}>
                            Comments
                          </span>
                        ),
                        id: "comment_array",
                        accessor: d =>
                          d.comment_array === null ? "NA" : d.comment_array,
                        Cell: ({ value }) =>
                          value === "NA"
                            ? ""
                            : JSON.parse(value).map((element, index) => (
                              <div>
                                {!!element.attachment ? (
                                  <React.Fragment>
                                    {/* <center> */}
                                      <div className={styles.imagePadding}>
                                        {/* <MyImage
                                          value={element.attachment}
                                          handleImageClick={
                                            this.handleImageClick
                                          }
                                          width={150}
                                        /> */}
                                       { element.attachment ?
                                        <img src={ `https://s3.ap-south-1.amazonaws.com/com.fcb.ho.stag/small/${element.attachment}` } onClick={()=>this.handleImageClick(element.attachment)}/>
                                        :
                                        <img src={imgNotAvail}  className={styles.imgNotAvail}/>
                                       }
                                        {/* <hr /> */}
                                      </div>
                                    {/* </center> */}
                                  </React.Fragment>
                                ) : null}
                                {!!element.comment &&
                                  element.comment !== "" ? (
                                    <div>
                                      <div style={{paddingLeft: "5px", width: "100%"}}>
                                      {element.comment}
                                      </div>
                                      <hr style={{width: "95%"}}/>
                                    </div>
                                  ) : null}
                              </div>
                            )),
                        isExpanded: false,
                        minWidth: 300,
                        show: this.state.showSnagComment
                      }
                    ]
                  }
                ]}
                defaultPageSize={10}
                className={`${styles.ReactTable} -highlight`}
                noDataText="No Data Found"
                loading={isLoading}
                style={{
                  width: "100%",
                  height: "70vh",
                  transition: "all .2s ease-out"
                }}
                sortable={false}
                // Controlled props
                sorted={this.state.sorted}
                page={this.state.page}
                pageSize={this.state.pageSize}
                expanded={this.state.expanded}
                resized={this.state.resized}
                // Callbacks
                // {sorted => this.setState({ sorted })}
                onPageChange={page => this.setState({ page })}
                onPageSizeChange={(pageSize, page) =>
                  this.setState({ page, pageSize })
                }
                onExpandedChange={expanded => this.setState({ expanded })}
                onResizedChange={resized => this.setState({ resized })}
              // onFilteredChange={filtered => this.setState({ filtered })}
              />
            </Grid.Row>
          </Grid>
          <Fragment>
            <Dialog
              open={this.state.openDialog}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  All images will have to be downloaded to process your{" "}
                  <b>Print All</b> request. The process is expected to take{" "}
                  <b>20 - 30 secs</b>, depending on your internet speed.
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  Please click on Start Print button below to continue.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.printAll(towerSnagInfo.length)}
                  color="primary"
                >
                  Start Print
                </Button>
                <Button onClick={this.handleClose} color="primary" autoFocus>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Fragment>
        </Fragment>
      </div>
    );
  }
}

export default TowerSnagTable;
