import React, { Component, PropTypes } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { App } from "@falconbrick/fbt-portal-manager";
import styles from "./Programs.module.css";
// import {App} from "@falconbrick/fbt-portal-manager";

export default class Programs extends Component {
  componentDidMount() {
    // console.log("hello");
    document.getElementById("sliderMenu").style.display = "block";
    document.getElementById("headerProperty").style.display = "block";
  }

  render() {
    // const { App } = ProgramWidgets;
    return (
      <div className={styles.programContainer}>
        <App />
      </div>
    );
  }
}
