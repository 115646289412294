import axios from "axios";
import * as types from "../Constants/commonConstants";
import config from "../configs/clientConfig";
import setAuthorizationToken from "../utils/setAuthorizationToken";

/* Get Block from api */
export function getBlocks(requestData) {
    if (localStorage.jwtToken) {
        setAuthorizationToken(localStorage.jwtToken);
    }
    return dispatch => {
        dispatch(getBlocksFetch());
        return axios
            .post(`${config.ApiUrl}/api/unitInfo`, { timeout: 120000, ...requestData })
            .then(res => {
                dispatch(getBlocksSuccess(res.data.data));
                return (Object.assign(res.data.data, { clearSearchedActivities: true }));
                // dispatch(setBlock(res.data.data[0]));
            })
            .catch(error => {
                dispatch(getBlocksError(error));
            });
    };
}

/* dispatch calls */
export const getBlocksFetch = () => ({
    type: types.BLOCKS_FETCH
});

export const getBlocksSuccess = blockList => ({
    type: types.BLOCKS_SUCCESS,
    payload: { blockList }
});

export const getBlocksError = error => ({
    type: types.BLOCKS_ERROR,
    payload: { error }
});

export const setBlock = blockSelected => ({
    type: types.SET_BLOCK,
    payload: { blockSelected }
});
