/* ActivityManager Related Types */
export const GET_ACTIVITY_INFO_FETCH = "GET_ACTIVITY_INFO_FETCH";
export const GET_START_ACTIVITY_INFO_FETCH = "GET_START_ACTIVITY_INFO_FETCH";
export const GET_ACTIVITY_INFO_ERROR = "GET_ACTIVITY_INFO_ERROR";
export const GET_ACTIVITY_INFO_SUCCESS = "GET_ACTIVITY_INFO_SUCCESS";
export const GET_ACTIVITY_LOG_SUCCESS = "GET_ACTIVITY_LOG_SUCCESS";
export const GET_ACTIVITY_INFO_FAILURE = "GET_ACTIVITY_INFO_FAILURE";
export const INIT_ACTIVITY_WIDGET = "INIT_ACTIVITY_WIDGET";
export const ACTIVITY_CHANGE_TYPE = "ACTIVITY_CHANGE_TYPE";
export const ACTIVITY_CHANGE_BLOCK = "ACTIVITY_CHANGE_BLOCK";
export const ACTIVITY_PAGE_CHANGE = "ACTIVITY_PAGE_CHANGE";
export const ACTIVITY_SEARCH_ACTIVITIES = "ACTIVITY_SEARCH_ACTIVITIES";
export const ACTIVITY_SEARCH_UNITS = "ACTIVITY_SEARCH_UNITS";
export const SELECT_ACTIVITY_GROUP = "SELECT_ACTIVITY_GROUP";
export const SELECT_UNIT_ACTIVITY = "SELECT_UNIT_ACTIVITY";
export const START_ACTIVITY = "START_ACTIVITY";
export const SEARCH_HAS_NO_RESULTS = "SEARCH_HAS_NO_RESULTS";
export const ACTIVITY_PRECEDENCE_MATRIX = "ACTIVITY_PRECEDENCE_MATRIX";
export const RESET_ACTIVITIES = "RESET_ACTIVITIES";
