import { SET_TOWER_INFO, UPDATE_TOWER_INFO } from "../constants";

const initialState = {
  towerInfo: {}
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_TOWER_INFO:
      return { towerInfo: action.towerInfo };
    case UPDATE_TOWER_INFO:
      return { ...state, towerInfo: action.towerInfo };
    default:
      return state;
  }
};
