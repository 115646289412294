import React, { Component } from "react";
// import { toast } from "react-toastify";
import { connect } from "react-redux";
import { forgotPassword } from "../../../actions/Auth";
import {
  Loader,
  Container,
} from "semantic-ui-react";

import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  GridContainer,
  GridItem,
  Button,
  CustomInput,
} from '@falconbrick/fbt-web-components';


import { bindActionCreators } from "redux";
import Validator from "validator";
import isEmpty from "lodash/isEmpty";

import styles from "./ForgotPassword.module.css";

class forgotPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      success: "",
      errors: {},
      isLoading: true,
      hasErrored: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    //e.preventDefault();
    localStorage.clear();
  }

  onChange(e) {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  isValid() {
    const { errors, isValid } = this.validateInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  }

  validateInput(data) {
    const errors = {};

    if (_.isEmpty(data.email)) {
      this.setState({ errorEmail: true });
      errors.email = 'This field is required';
    }

    return {
      errors,
      isValid: _.isEmpty(errors),
    };
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.isValid()) {
      this.setState({ errors: {} });
      this.props.forgotPassword(this.state.email).then(
        res => {
          console.log("email sent to" + this.state.email);
          //console.log(res);
          this.setState({ success: `Email Sent to ${this.state.email}` });
          this.props.history.replace("/", this.state.success);
          // toast.success(`Email sent to ${this.state.email}`);
        },
        err =>
          this.setState({
            errors: err.response.data.errors,
            isLoadingLogin: false
          })
      );
    }
  }

  render() {
    const { isLoading, hasErrored } = this.props;

    if (isLoading) {
      return (
        <Container
          textAlign="center"
          className={styles.forgotPassContainer}
          fluid
        >
          <br />
          <Loader active inline="centered" />
        </Container>
      );
    }

    if (hasErrored) {
      return (
        <Container
          textAlign="center"
          className={styles.forgotPassContainer}
          fluid
        >
          <p />
          <div className="ui active">
            Something went wrong! please try again
          </div>
        </Container>
      );
    }

    const { email, errors, success } = this.state;

    return (
      <div className={styles.containerMain}>
        <GridContainer
          justify="center"
          className={styles.showHidden}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              this.onSubmit(e);
            }
          }}
        >
          <GridItem xs={12} sm={12} md={4}>
            <Card className={this.state.cardAnimaton ? styles.cardHidden : styles.showHidden}>
              <form className={styles.form}>
                <CardHeader color="primary" className={styles.cardHeader}>
                  <div className={styles.logoText}>
                    Forgot Password
                  </div>
                  <div className={styles.fp} onClick={e => this.props.history.push('/login')}>
                    back to login
                  </div>
                </CardHeader>
                <CardBody>
                  {errors && <div className={styles.errorAlert}>{errors.form}</div>}
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                      error: this.state.errorEmail,
                    }}
                    inputProps={{ type: 'email', required: true }}
                    onChange={e => this.onChange(e)}
                    inputCustomClasses={styles.loginInput}
                  />
                </CardBody>
                <CardFooter className={styles.cardFooter}>
                  <Button color="primary" size="lg" className={styles.loginButton} onClick={e => this.onSubmit(e)}>
                    EMAIL PASSWORD
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // profile: state.auth.user,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      forgotPassword: forgotPassword
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(forgotPass);
