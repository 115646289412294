import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// import { toast } from "react-toastify";
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Button, Divider, Image, Loader, Card, Container, Header } from 'semantic-ui-react';
import { propertyGet, propertySet } from '../../../actions/Property';
import defaultProjectImg from '../../../resources/images/project_default.png';
import { getParameterByName, cleanReportsLocal } from '../../utils/UtilityFunctions';

import styles from './PropertySelection.module.css';

class PropertySelection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const data = getParameterByName('project');
    this.props.propertyGet(data).then(
      res => {
        this.setState.isLoading = false;
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
    document.getElementById('sliderMenu').style.display = 'none';
    document.getElementById('headerProperty').style.display = 'none';
  }

  propertySetId(property) {
    cleanReportsLocal();
    this.props.propertySet(property).then(
      () => {
        this.props.history.push('/reports/blockInfo');
        // toast.success(`Property Set to ${property.name}`);
        document.getElementById('sliderMenu').style.display = 'block';
        document.getElementById('headerProperty').style.display = 'block';
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
  }

  render() {
    const { property, propertyList } = this.props;

    if (property.loading) {
      return (
        <Container fluid className={styles.propertyContainer}>
          <div className={styles.subText}>Step - 02 / 02</div>
          <div className={styles.h2}>Select A Phase</div>
          <br />
          <Loader active />
        </Container>
      );
    }

    if (_.isEmpty(property.propertyList)) {
      return (
        <Container fluid className={styles.propertyContainer}>
          <div className={styles.subText}>Step - 02 / 02</div>
          <div className={styles.h2}>select a phase</div>
          <p className={styles.emptyAssign}>No phase assigned to you</p>
        </Container>
      );
    }

    if (property.error) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again
            {property.errors}
          </div>
        </div>
      );
    }

    return (
      <Container fluid className={styles.propertyContainer}>
        <div className={styles.subText}>Step - 02 / 02</div>
        <div className={styles.h2}>Select A Phase</div>
        <br />
        <Card.Group>
          {propertyList.map(function(list, index) {
            return (
              <Card key={list.name}>
                <Card.Content>
                  {list.property_image || null ? (
                    <Image src={list.property_image} size="medium" />
                  ) : (
                    <Image src={defaultProjectImg} size="medium" />
                  )}
                  <Divider hidden />
                  <Card.Header>{list.name}</Card.Header>
                  <Divider hidden />
                  <Card.Content>{list.address}</Card.Content>
                  <Divider />
                  <Card.Content>
                    <Button type="submit" onClick={() => this.propertySetId(list)}>
                      Select {list.name}
                    </Button>
                  </Card.Content>
                </Card.Content>
              </Card>
            );
          }, this)}
        </Card.Group>
        <Divider hidden section />

        <Divider hidden section />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  property: state.property,
  propertyList: state.property.propertyList,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      propertyGet,
      propertySet,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertySelection)
);
