import axios from "axios";
import _ from 'lodash';
import config from "../configs/clientConfig";
import * as types from "../Constants/ActivityConstants";
import * as commonActions from "./CommonActions";
import setAuthorizationToken from "../utils/setAuthorizationToken";

/* get inital activitis from api */
export function getInitalData(requestData) {
    if (localStorage.jwtToken) {
        setAuthorizationToken(localStorage.jwtToken);
    }
    console.log("done");
    return dispatch => {
        dispatch(commonActions.getBlocks(requestData)).then(res => {
            try {
                const initalBlock = res[0];
                // console.log(initalBlock);
                dispatch(commonActions.setBlock(initalBlock));
                const requestDataNew = { blockSelected: initalBlock };
                dispatch(getActivityInfo(requestDataNew));
            }
            catch (err) {
                dispatch(getActivityInfoError(err));
            }
        });
    };
}
/* get activitis from api */
export function getActivityInfo(requestData) {
    return dispatch => {
        dispatch(getActivityInfoFetch());
        axios
            .post(`${config.ApiUrl}/api/activity/getAllActivities`, {
                timeout: 120000,
                block_id: requestData.blockSelected.id,
                unit_type: "ALL"
            })
            .then(res => {
                // .log("here");
                res.data.unitTypeSelected = requestData.unitTypeSelected;
                res.data.currentPage = requestData.currentPage;
                res.data.clearSearchedActivities = requestData['clearSearchedActivities'] || false;
                if (res.data.status === 0) {
                    dispatch(getActivityInfoError(res.data.msg));
                } else {
                    dispatch(getActivityInfoSuccess(res.data));
                }
            })
            .catch(err => {
                dispatch(getActivityInfoError(err));
            });
    };
}

export function changeBlock(requestData) {
    return dispatch => {
        dispatch(getActivityInfo(requestData));
        dispatch(commonActions.setBlock(requestData.blockSelected));
    };
}

export function startActivity(requestData) {
    /* api to activate units */
    return dispatch => {
        dispatch(getStartActivityInfoFetch());
        return axios
            .post(`${config.ApiUrl}/api/activity/activateUnit`, {
                unit_id: requestData.selectedActivityUnits
            })
            .then(() => {
                dispatch(getActivityInfoFetch());
                let timeOut = 0;
                timeOut = 1000 * requestData.selectedActivityUnits.length;
                setTimeout(function (timeOut) {
                    console.log("in timeout");
                    console.log(timeOut)
                    dispatch(getActivityInfo(requestData));
                }, timeOut);

            })
            .catch(err => {
                dispatch(getActivityInfoError(err));
            });
    };
}

/* dispatch calls */

export function getSortByPrecedenceFunction(context) {
    return dispatch => {
        return axios
            .post(`${config.ApiUrl}/api/activity/getActivityPrecedence`, {
                unit_type: context.unit_type,
                property_id: context.property_id

            }).then(
                (res) => {
                    dispatch(setPrecedenceMatrix(res['data']));
                }
            )
            .catch(res => {
                dispatch(setPrecedenceMatrix([]))
            }
            )
    }
}

export function getStartActivityInfoFetch() {
    return {
        type: types.GET_START_ACTIVITY_INFO_FETCH
    };
}


export function getActivityInfoFetch() {
    return {
        type: types.GET_ACTIVITY_INFO_FETCH
    };
}

export function getActivityInfoSuccess(response) {
    // remove export
    const activityList = {};
    const activityTable = {};
    const unitTypes = [];
    const { clearSearchedActivities } = response;
    let unitTypeSelected;
    let currentPage;
    response.unitTypeSelected
        ? (unitTypeSelected = response.unitTypeSelected)
        : (unitTypeSelected = []);

    response.currentPage
        ? (currentPage = response.currentPage)
        : (currentPage = 1);

    const units = response;
    if (units) {
        _.sortBy(units, 'id');
        units.map(unit => {
            if (unit.current_action) {
                const type = unit.current_action.unit_type;
                const { floor } = unit.current_action;
                const name = unit.activity_name;
                const { id } = unit;
                const { status } = unit;
                const { title } = unit;

                if (type === "apt" || type === "pour") {
                    if (!activityList[type]) {
                        activityList[type] = {};
                        activityTable[type] = {
                            activities: [],
                            floors: {}
                        };
                        unitTypes.push(type);
                    }
                    if (!activityList[type][floor]) {
                        activityList[type][floor] = {};
                        activityTable[type]["floors"][floor] = [];
                    }
                    if (!activityList[type][floor][name]) {
                        activityList[type][floor][name] = {};
                    }
                    if (activityTable[type]["floors"][floor].indexOf(title) === -1) {
                        activityTable[type]["floors"][floor].push(title);
                    }
                    if (activityTable[type].activities.indexOf(name) === -1) {
                        activityTable[type].activities.push(name);
                    }
                    activityList[type][floor][name][title] = {
                        id,
                        status,
                        activity: name
                    };
                } else {
                    if (!activityList[type]) {
                        activityList[type] = {};
                        activityTable[type] = {
                            activities: [],
                            units: []
                        };
                        unitTypes.push(type);
                    }
                    if (!activityList[type][name]) {
                        activityList[type][name] = {};
                    }
                    if (activityTable[type]["units"].indexOf(title) === -1) {
                        activityTable[type]["units"].push(title);
                    }
                    if (activityTable[type]["activities"].indexOf(name) === -1) {
                        activityTable[type]["activities"].push(name);
                    }
                    activityList[type][name][title] = {
                        id,
                        status,
                        activity: name
                    };
                }
            }
            return true;
        });
    }
    /* sorting of activity names by alphabetical order
     */

    Object.keys(activityTable).map(function (key, index) {
        activityTable[key]['activities'] = _.sortBy(activityTable[key].activities)
    });

    return {
        type: types.GET_ACTIVITY_INFO_SUCCESS,
        activityList,
        activityTable,
        unitTypes,
        unitTypeSelected,
        currentPage,
        clearSearchedActivities
    };
}

export function getActivityInfoError(error) {
    return {
        type: types.GET_ACTIVITY_INFO_ERROR,
        error
    };
}

export function initActivityWidget() {
    return {
        type: types.INIT_ACTIVITY_WIDGET
    };
}

export function changeType(unitType, selectedBlock) {
    return dispatch => {
        dispatch(getSortByPrecedenceFunction({ unit_type: unitType, property_id: selectedBlock }))
        dispatch({
            type: types.ACTIVITY_CHANGE_TYPE,
            unitType
        });
    };
}

export function pageChange(page) {
    return {
        type: types.ACTIVITY_PAGE_CHANGE,
        page
    };
}

export function searchActivities(q) {
    return {
        type: types.ACTIVITY_SEARCH_ACTIVITIES,
        q
    };
}

export function searchUnits(q) {
    return {
        type: types.ACTIVITY_SEARCH_UNITS,
        q
    };
}

export function selectActivity(activity) {
    return {
        type: types.SELECT_ACTIVITY_GROUP,
        activity
    };
}

export function selectUnitActivity(id, activity) {
    return {
        type: types.SELECT_UNIT_ACTIVITY,
        id,
        activity
    };
}

export function noResultsIn(unitOrAct) {
    return {
        type: types.SEARCH_HAS_NO_RESULTS,
        unitOrAct
    };
}
export function setPrecedenceMatrix(objArray) {
    return {
        type: types.ACTIVITY_PRECEDENCE_MATRIX,
        objArray
    }
}
export function resetActivityWidget() {
    return {
        type: types.RESET_ACTIVITIES
    }
}
