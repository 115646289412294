import _ from 'lodash';
import * as types from '../constants';

const initialState = {
  towerSnagInfo: [],
  unitTypeInfo: [],
  unitTypeSelected: {},
  loading: true,
  error: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.TOWER_SNAG_EXPORTS_FETCH:
      return { ...state, loading: true, error: null };
    case types.TOWER_SNAG_EXPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        towerSnagInfo: action.payload.towerSnagInfo,
      };
    case types.TOWER_SNAG_EXPORTS_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    // unit type
    case types.UNIT_TYPE_FETCH:
      return { ...state, loading: true, error: null };
    case types.UNIT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        unitTypeInfo: _.sortBy(action.payload.unitTypeInfo, 'name'),
      };
    case types.UNIT_TYPE_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case types.UNIT_TYPE_SET:
      return {
        ...state,
        loading: false,
        error: null,
        unitTypeSelected: action.payload.selected,
      };
    default:
      return state;
  }
};
