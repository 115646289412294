import React, { Component } from 'react';
// import { precedenceMatrix, getSortingPredicate } from '../utils/ActivityWidgetsUtilities';
import _ from 'lodash';
import styles from './PlotUnits.module.css';
import PlotSingleUnit from './PlotSingleUnit';
import cx from 'classnames';


class PlotUnits extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    this.getSingleUnitData = this.getSingleUnitData.bind(this);
    this.getUnitUI = this.getUnitUI.bind(this);
    this.getUnitsList = this.getUnitsList.bind(this);
  }
  componentDidUpdate(prevProps, prevState) {
    const propsCompare = {
      pre: { units: prevProps.units, noResult: prevProps['noResult'] },
      post: { units: this.props.units, noResult: this.props['noResult'] }
    };
    if (!_.isEqual(propsCompare.pre, propsCompare.post)) {
      const { units, data, activities, selectedActivityUnits, grouping, loading } = this.props;
      const unitsUI = this.getUnitsList(units, data, activities, selectedActivityUnits, grouping, loading);
      if (this.props['noResult'] === 'Activity')
        this.setState({ noResultsVal: 'Activity' });
      else if (unitsUI.length === 0)
        this.setState({ noResultsVal: 'Unit' });
      else
        this.setState({ noResultsVal: null });
    }
    if (this.state['noResultsVal'] !== this.props['noResult']) {
      this.props.noResultsIn(this.state['noResultsVal']);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps['noResult'] === 'Activity' && !!!prevState['noResultsVal'])
      return { noResultsVal: 'Activity' };
    if (!!!nextProps['noResult'] && prevState['noResultsVal'] === 'Activity')
      return { noResultsVal: null };
    return prevState;
  }

  getSingleUnitData(activities, data, unit) {
    const unitMap = activities.length > 0 ? activities.sort(this.props.sortFunction(activities)).map(act => {
      if (!!data[act])
        return data[act][unit] || null;
      return null;
    }) : [];
    return unitMap.length > 0 ? unitMap : null;
  }

  getUnitUI(unit, data, activities, selectedActivityUnits, isGrouped) {
    return (floor) => {
      if (!!unit) {
        if (!isGrouped) {
          const singleUnitData = this.getSingleUnitData(activities, data, unit) || [];
          return (
            <div className={styles.unitGroup}>
              <div className={styles.plotSingleUnit}>
                <div className={styles.units}>
                  <span>{unit}</span>
                </div>
                <div className={styles.activities}>
                  <PlotSingleUnit
                    data={singleUnitData}
                    selectedActivityUnits={selectedActivityUnits}
                  />
                </div>
              </div>
            </div>
          );
        }
        const unitsInfo = typeof (unit) !== 'string' ? unit.sort().map(u => (this.getUnitUI(u, data, activities, selectedActivityUnits, false)(floor))) : false;
        if (!!unitsInfo && unitsInfo.length > 0)
          return (
            <div className={styles.unitGroup}>
              <div className={styles.plotUnitGroup}>
                <h4>Floor {floor}</h4>
              </div>
              {unitsInfo}
            </div>
          );
        return null;
      }
      return null;
    };
  }

  getUnitsList(units, data, activities, selectedActivityUnits, grouping, loading) {
    const unitKeys = typeof (units[0]) === 'string' ? Object.keys(units.sort()) : Object.keys(units);
    const unitsUI = (grouping === null) || loading ? ["Loading..."] : (((isGrouped) => (
      Object.create({
        true: unitKeys.length > 0 ? unitKeys.sort((a, b) => (Number(a) > Number(b) ? 1 : -1)).map(k => (this.getUnitUI(units[k], data[k], activities, selectedActivityUnits, true)(k))) : ["Loading..."],
        false: unitKeys.sort((a, b) => (Number(a) > Number(b) ? 1 : -1)).map((k) => (this.getUnitUI(units[k], data, activities, selectedActivityUnits, false)(k)))
      })[!!isGrouped]))(grouping))
      .filter(res => (!!res));
    return unitsUI.length > 0 ? unitsUI : [];
  }

  render() {
    const { activities, selectedActivityUnits, units, data, grouping, loading } = this.props;
    // const unitsUI = grouping && unitKeys.length > 0 ?
    //       unitKeys.sort().map((i) => {
    //           if (units[i].length > 0) {
    //               return (
    //                   <div className={styles.unitGroup}>
    //                     <div className={styles.plotUnitGroup}>
    //                       <h4>Floor {i}</h4>
    //                     </div>
    //                     {this.getGroupedUnitUI(units[i], data[i], activities, selectedActivityUnits)}
    //                   </div>
    //               );
    //           }
    //       }) :
    //       unitKeys.length > 0 && this.getGroupedUnitUI(units, data, activities, selectedActivityUnits);
    const plottedUnits = this.getUnitsList(units, data, activities, selectedActivityUnits, grouping, loading);
    return (
      <div className={styles.plotUnits} >{plottedUnits}</div>
    );
  }
}

export default PlotUnits;
