import * as types from "../Constants/ActivityConstants";
import * as utils from "./utils";

const initialState = {
    loadingMsg: "Loading All Activities",
    activityList: {},
    activityTable: {},
    unitTypes: [],
    activeUnitType: "",
    dataList: {},
    dataTable: {},
    gridSize: 6,
    totalPages: 1,
    currentPage: 1,
    currentActivities: [],
    currentData: {},
    searchedActivities: [],
    grouping: null,
    units: {},
    selectedActivities: [],
    selectedActivityUnits: [],
    selectedActivityCache: {},
    loading: true,
    error: null,
    noResult: null,
    sortFunction: (a, b) => (a.localeCompare(b))
};

const activityManager = (state = initialState, action = {}) => {
    let newState = {};
    switch (action.type) {
        case types.GET_START_ACTIVITY_INFO_FETCH: {
            newState["loadingMsg"] = "Starting Activities ... Please wait this could take upto 2 Minutes."
            return Object.assign({}, state, newState);
        }

        case types.GET_ACTIVITY_INFO_FETCH: {
            newState["selectedActivityUnits"] = []
            newState["selectedActivities"] = []
            newState["loading"] = true;
            newState["error"] = false;
            return Object.assign({}, state, newState);
        }

        case types.GET_ACTIVITY_INFO_ERROR: {
            newState["loading"] = false;
            newState["error"] = action.error.message;
            return Object.assign({}, state, newState);
        }

        case types.GET_ACTIVITY_INFO_SUCCESS: {
            const { gridSize, clearSearchedActivities } = state;
            const searchedActivities = clearSearchedActivities ? state.clearSearchedActivities : [];
            newState["loadingMsg"] = "Loading All Activities"
            newState["activityList"] = action.activityList;
            newState["activityTable"] = action.activityTable;
            newState["unitTypes"] = action.unitTypes.sort();
            newState["currentPage"] = action.currentPage;
            action.unitTypeSelected.length > 0
                ? (newState["activeUnitType"] = action.unitTypeSelected)
                : (newState["activeUnitType"] = newState.unitTypes[0]);
            newState["dataList"] = newState.activityList[newState.activeUnitType];
            newState["dataTable"] = newState.activityTable[newState.activeUnitType];
            newState["totalPages"] =
                searchedActivities.length > 0
                    ? (newState["totalPages"] = Math.ceil(
                        searchedActivities.length / gridSize
                    ))
                    : Math.ceil(newState.dataTable.activities.length / gridSize);
            newState["currentActivities"] =
                searchedActivities.length > 0
                    ? searchedActivities.sort(state.sortFunction(searchedActivities)).slice(
                        (newState.currentPage - 1) * gridSize,
                        newState.currentPage * gridSize
                    )
                    : newState.dataTable.activities.sort(state.sortFunction(newState.dataTable.activities)).slice(
                        (newState.currentPage - 1) * gridSize,
                        newState.currentPage * gridSize
                    );
            newState["selectedActivityUnits"] = [];
            newState["selectedActivities"] = [];
            newState["selectedActivityCache"] = {};
            newState["grouping"] = !!newState.dataTable.floors;
            newState["units"] = newState.grouping
                ? newState.dataTable.floors
                : newState.dataTable.units;
            newState["currentData"] = newState.grouping
                ? utils.getCurrentActivityData(
                    newState.dataList,
                    newState.currentActivities
                )
                : utils.filterByKeys(newState.dataList, newState.currentActivities);
            newState["loading"] = false;
            newState["error"] = false;
            return Object.assign({}, state, newState);
        }

        case types.ACTIVITY_CHANGE_TYPE: {
            newState = {
                activeUnitType: "",
                dataList: {},
                dataTable: {},
                gridSize: 6,
                totalPages: 1,
                currentPage: 1,
                currentActivities: [],
                currentData: {},
                searchedActivities: [],
                grouping: false,
                units: {},
                selectedActivities: [],
                selectedActivityUnits: []
            };
            const { activityList, activityTable, gridSize } = state;
            newState["activeUnitType"] = action.unitType;
            newState["dataList"] = activityList[action.unitType];
            newState["dataTable"] = activityTable[action.unitType];
            newState["totalPages"] = Math.ceil(
                newState.dataTable.activities.length / gridSize
            );
            newState["currentActivities"] = newState.dataTable.activities.sort(state.sortFunction(newState.dataTable.activities)).slice(
                0,
                gridSize
            );
            newState["grouping"] = !!newState.dataTable.floors;
            newState["units"] = newState.grouping
                ? newState.dataTable.floors
                : newState.dataTable.units;
            newState["currentData"] = newState.grouping
                ? utils.getCurrentActivityData(
                    newState.dataList,
                    newState.currentActivities
                )
                : utils.filterByKeys(newState.dataList, newState.currentActivities);
            return Object.assign({}, state, newState);
        }

        case types.ACTIVITY_PAGE_CHANGE: {
            const {
                dataTable,
                dataList,
                searchedActivities,
                gridSize,
                grouping,
                noResult
            } = state;
            newState["currentPage"] = action.page;
            newState["noResult"] = state['noResult'];
            (newState["currentActivities"] =
                searchedActivities.length > 0
                    ? searchedActivities.sort(state.sortFunction(searchedActivities)).slice(
                        (action.page - 1) * gridSize,
                        action.page * gridSize
                    )
                    : dataTable.activities.sort(state.sortFunction(dataTable.activities)).slice(
                        (action.page - 1) * gridSize,
                        action.page * gridSize
                    )),
                (newState["currentData"] = grouping
                    ? utils.getCurrentActivityData(dataList, newState.currentActivities)
                    : utils.filterByKeys(dataList, newState.currentActivities));
            return Object.assign({}, state, newState);
        }

        case types.ACTIVITY_SEARCH_ACTIVITIES: {
            const { dataTable, dataList, gridSize, grouping } = state;
            newState["searchedActivities"] = utils.arraySearch(
                dataTable.activities,
                action.q
            );
            newState["totalPages"] = Math.ceil(
                newState.searchedActivities.length / gridSize
            );
            newState["currentActivities"] = newState.searchedActivities.sort(state.sortFunction(newState.searchedActivities)).slice(
                0,
                gridSize
            );
            (newState["currentData"] = grouping
                ? utils.getCurrentActivityData(dataList, newState.currentActivities)
                : utils.filterByKeys(dataList, newState.currentActivities));
            (newState["currentPage"] =
                newState.searchedActivities.length > 0 ? 1 : 0);
            return Object.assign({}, state, newState);
        }

        case types.ACTIVITY_SEARCH_UNITS: {
            const { dataTable, grouping } = state;
            newState["units"] = grouping
                ? utils.activityGroupedUnitSearch(dataTable.floors, action.q)
                : utils.arraySearch(dataTable.units, action.q);
            return Object.assign({}, state, newState);
        }

        case types.SELECT_ACTIVITY_GROUP: {
            const {
                selectedActivities,
                selectedActivityUnits,
                currentData,
                grouping,
                selectedActivityCache
            } = state;
            const selectedCopy = [...selectedActivities];
            const selectedUnitsCopy = [...selectedActivityUnits];
            const newCacheObj = {};
            if (selectedCopy.includes(action.activity)) {
                newState["selectedActivities"] = utils.removeArrayElement(
                    selectedCopy,
                    action.activity
                );
                selectedActivityCache[action.activity].map(elm => {
                    utils.removeArrayElement(selectedUnitsCopy, elm);
                });
                newState["selectedActivityUnits"] = [...selectedUnitsCopy];
                newCacheObj[action.activity] = [];
                newState["selectedActivityCache"] = Object.assign(
                    {},
                    selectedActivityCache,
                    newCacheObj
                );
            } else {
                newState["selectedActivities"] = [...selectedCopy, action.activity];
                newCacheObj[action.activity] = [
                    ...utils.selectAllActivityUnits(
                        currentData,
                        action.activity,
                        grouping
                    )
                ];
                newState["selectedActivityUnits"] = utils.arrayUnique([
                    ...selectedUnitsCopy,
                    ...newCacheObj[action.activity]
                ]);
                newState["selectedActivityCache"] = Object.assign(
                    {},
                    selectedActivityCache,
                    newCacheObj
                );
            }
            return Object.assign({}, state, newState);
        }

        case types.SELECT_UNIT_ACTIVITY: {
            const { selectedActivities, selectedActivityUnits } = state;
            const selectedCopy = [...selectedActivityUnits];
            const selectedActivityCopy = [...selectedActivities];
            if (selectedCopy.includes(action.id)) {
                newState["selectedActivityUnits"] = utils.removeArrayElement(
                    selectedCopy,
                    action.id
                );
                newState["selectedActivities"] = utils.removeArrayElement(
                    selectedActivityCopy,
                    action.activity
                );
            } else {
                newState["selectedActivityUnits"] = [...selectedCopy, action.id];
            }
            return Object.assign({}, state, newState);
        }

        case types.SEARCH_HAS_NO_RESULTS: {
            if (!['Activity', 'Unit'].includes(action.unitOrAct))
                newState["noResult"] = null;
            else
                newState["noResult"] = action.unitOrAct;
            return Object.assign({}, state, newState);
        }

        case types.START_ACTIVITY: {
            const { gridSize } = state;
            newState["loadingMsg"] = "loading from start activity reducer"
            newState["activityList"] = action.activityList;
            newState["activityTable"] = action.activityTable;
            newState["unitTypes"] = action.unitTypes.sort();
            newState["activeUnitType"] = newState.unitTypes[0];
            newState["dataList"] = newState.activityList[newState.activeUnitType];
            newState["dataTable"] = newState.activityTable[newState.activeUnitType];
            newState["totalPages"] = Math.ceil(
                newState.dataTable.activities.length / gridSize
            );
            newState["currentActivities"] = newState.dataTable.activities.slice(
                0,
                gridSize
            );
            newState["grouping"] = !!newState.dataTable.floors;
            newState["units"] = newState.grouping
                ? newState.dataTable.floors
                : newState.dataTable.units;
            newState["currentData"] = newState.grouping
                ? utils.getCurrentActivityData(
                    newState.dataList,
                    newState.currentActivities
                )
                : utils.filterByKeys(newState.dataList, newState.currentActivities);
            return Object.assign({}, state, newState);
        }
        case types.ACTIVITY_PRECEDENCE_MATRIX: {
            newState['sortFunction'] = utils.getSortingPredicate(action.objArray);
            return Object.assign({}, state, newState);
        }
        case types.RESET_ACTIVITIES: {
            let newState = {
                selectedActivities: [],
                selectedActivityCache: [],
                selectedActivityUnits: []
            };
            return Object.assign({}, state, newState);
        }
        default:
            return state;
    }
};

export default activityManager;
