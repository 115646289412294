import React, { Fragment } from 'react';
import 'whatwg-fetch';
import moment from 'moment';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import 'react-table/react-table.css';

import { Icon, Grid, Container, Label } from 'semantic-ui-react';

const pageOptions = [10, 20, 25, 50, 100];

const DeltaInfoUnitSub = ({ deltaUnitInfo, isLoading, errors, from, to }) => {
  if (errors) {
    return <p>Something went wrong! Please refresh</p>;
  }
  console.log(from);
  return (
    <Fragment>
      <Grid columns="equal" padded style={{ width: '100%' }}>
        <Grid.Row textAlign="center">
          <Container textAlign="center">
            <Label attached="top right" size="medium" className="custom">
              Showing {deltaUnitInfo.length} units
            </Label>
            <br />
          </Container>
          <ReactTable
            data={deltaUnitInfo}
            pageSizeOptions={pageOptions}
            columns={[
              {
                columns: [
                  {
                    Header: () => (
                      <span>
                        Block
                        <Icon disabled name="sort" />
                      </span>
                    ),
                    id: 'block_name',
                    accessor: d => d.block_name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ['block_name'],
                      }),
                    filterAll: true,
                    filterable: true,
                  },
                  {
                    Header: () => (
                      <span>
                        Unit Title
                        <Icon disabled name="sort" />
                      </span>
                    ),
                    id: 'title',
                    accessor: d => d.title,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ['title'] }),
                    filterAll: true,
                    filterable: true,
                  },
                  {
                    Header: () => (
                      <span>
                        Activity
                        <Icon disabled name="sort" />
                      </span>
                    ),
                    id: 'activity_name',
                    accessor: d => d.activity_name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ['activity_name'],
                      }),
                    filterAll: true,
                    filterable: true,
                    minWidth: 150,
                  },
                ],
              },
              {
                Header: moment(from).calendar(null, {
                  sameElse: 'Do MMMM [at] LT',
                }),
                headerStyle: {
                  flex: '100 0 auto',
                  backgroundColor: 'rgba(81,87,93,0.2)',
                  borderRadius: '2px',
                  transition: 'all .2s ease-out',
                  borderRight: '1px solid rgb(0, 0, 0)',
                  borderLeft: '1px solid rgb(0, 0, 0)',
                },
                style: {
                  flex: '100 0 auto',
                  backgroundColor: 'rgba(81,87,93,0.2)',
                  transition: 'all .2s ease-out',
                  borderRight: '1px solid rgb(0, 0, 0)',
                  borderLeft: '1px solid rgb(0, 0, 0)',
                },
                columns: [
                  {
                    Header: () => <span>User</span>,
                    id: 'before_username',
                    accessor: d => d.before_username,
                    Cell: row => (
                      <span>
                        {row.value === null || row.value.length === 0
                          ? 'NA'
                          : row.value}
                      </span>
                    ),
                    sortable: false,
                    headerStyle: {
                      flex: '100 0 auto',
                      backgroundColor: '#ecf0f185',
                      transition: 'all .2s ease-out',
                      borderLeft: '1px solid rgb(0, 0, 0)',
                      borderRight: '1px solid rgb(0, 0, 0)',
                    },
                    style: {
                      flex: '100 0 auto',
                      backgroundColor: '#ecf0f185',
                      transition: 'all .2s ease-out',
                      borderLeft: '1px solid rgb(0, 0, 0)',
                      borderRight: '1px solid rgb(0, 0, 0)',
                    },
                  },
                  {
                    Header: () => <span>Last Updated</span>,
                    id: 'first_updated_at',
                    accessor: d => `${moment(d.first_updated_at). format('DD-MM-YYYY')}`,
                    sortable: false,
                    minWidth: 100,
                    headerStyle: {
                      flex: '100 0 auto',
                      backgroundColor: '#ecf0f185',
                      borderRadius: '2px',
                      transition: 'all .2s ease-out',
                      borderRight: '1px solid rgb(0, 0, 0)',
                    },
                    style: {
                      flex: '100 0 auto',
                      backgroundColor: '#ecf0f185',
                      transition: 'all .2s ease-out',
                      borderRight: '1px solid rgb(0, 0, 0)',
                    },
                  },
                  {
                    Header: () => <span>Step</span>,
                    id: 'before_node',
                    accessor: d => `${d.before_node_name} (${d.before_node})`,
                    sortable: false,
                    minWidth: 300,
                    headerStyle: {
                      flex: '100 0 auto',
                      backgroundColor: '#ecf0f185',
                      transition: 'all .2s ease-out',
                      borderRight: '1px solid rgb(0, 0, 0)',
                    },
                    style: {
                      flex: '100 0 auto',
                      backgroundColor: '#ecf0f185',
                      transition: 'all .2s ease-out',
                      borderRight: '1px solid rgb(0, 0, 0)',
                    },
                  },
                ],
              },
              {
                Header: moment(to).calendar(null, {
                  sameElse: 'Do MMMM [at] LT',
                }),
                headerStyle: {
                  flex: '100 0 auto',
                  backgroundColor: 'rgba(81,87,93,0.2)',
                  borderRadius: '2px',
                  transition: 'all .2s ease-out',
                  borderRight: '1px solid rgb(0, 0, 0)',
                },
                style: {
                  flex: '100 0 auto',
                  backgroundColor: 'rgba(81,87,93,0.2)',
                  transition: 'all .2s ease-out',
                  borderRight: '1px solid rgb(0, 0, 0)',
                },
                columns: [
                  {
                    Header: () => <span>User</span>,
                    id: 'after_username',
                    accessor: d => d.after_username,
                    Cell: row => (
                      <span>
                        {row.value === null || row.value.length === 0
                          ? 'NA'
                          : row.value}
                      </span>
                    ),
                    sortable: false,
                    headerStyle: {
                      flex: '100 0 auto',
                      backgroundColor: '#ecf0f185',
                      borderRadius: '2px',
                      transition: 'all .2s ease-out',
                      borderRight: '1px solid rgb(0, 0, 0)',
                    },
                    style: {
                      flex: '100 0 auto',
                      backgroundColor: '#ecf0f185',
                      transition: 'all .2s ease-out',
                      borderRight: '1px solid rgb(0, 0, 0)',
                    },
                  },
                  {
                    Header: () => <span>Last Updated</span>,
                    id: 'first_updated_at',
                    accessor: d => `${moment(d.first_updated_at). format('DD-MM-YYYY')}`,
                    sortable: false,
                    minWidth: 100,
                    headerStyle: {
                      flex: '100 0 auto',
                      backgroundColor: '#ecf0f185',
                      borderRadius: '2px',
                      transition: 'all .2s ease-out',
                      borderRight: '1px solid rgb(0, 0, 0)',
                    },
                    style: {
                      flex: '100 0 auto',
                      backgroundColor: '#ecf0f185',
                      transition: 'all .2s ease-out',
                      borderRight: '1px solid rgb(0, 0, 0)',
                    },
                  },
                  {
                    Header: () => <span>Step</span>,
                    id: 'after_node',
                    accessor: d => `${d.after_node_name} (${d.after_node})`,
                    sortable: false,
                    minWidth: 300,
                    headerStyle: {
                      flex: '100 0 auto',
                      backgroundColor: '#ecf0f185',
                      borderRadius: '2px',
                      transition: 'all .2s ease-out',
                      borderRight: '1px solid rgb(0, 0, 0)',
                    },
                    style: {
                      flex: '100 0 auto',
                      backgroundColor: '#ecf0f185',
                      transition: 'all .2s ease-out',
                      borderRight: '1px solid rgb(0, 0, 0)',
                    },
                  },
                ],
              },
            ]}
            defaultPageSize={10}
            className="-highlight"
            noDataText="No Data Found"
            loading={isLoading}
            defaultSorted={[
              {
                id: 'title',
                desc: false,
              },
            ]}
            style={{
              width: '100%',
              height: '100%',
              transition: 'all .2s ease-out',
            }}
          />
        </Grid.Row>
      </Grid>
    </Fragment>
  );
};

export default DeltaInfoUnitSub;
