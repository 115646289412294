/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import Input from '@material-ui/core/Input';
import styles from './search.module.css';
import Icon from '../../portal-widgets-core/Icon';

class Search extends Component {
  state = { hoverState: 'normal' };

  iconStates = {
    normal: 'CLEAR_SEARCH',
    hover: 'CLEAR_SEARCH_HOVER',
  };

  changeHover = event => {
    switch (event.type) {
      case 'mouseover':
        this.setState({ hoverState: 'hover' });
        break;
      case 'mouseleave':
        this.setState({ hoverState: 'normal' });
        break;
      default:
        this.setState({ hoverState: 'normal' });
    }
  };

  render() {
    const { disabled, handlers, name, refs } = this.props;
    const { hoverState } = this.state;
    // console.log(this.props.refs.search.current && this.props.refs.search.current.value);
    return (
      <div className={styles.search}>
        <Input
          id="filter-search"
          variant="outlined"
          autoFocus
          disabled={disabled}
          onClick={e => e.stopPropagation()}
          onChange={handlers.searchChange}
          fullWidth
          disableUnderline
          placeholder={name}
          style={{
            color: !!refs.search.current && refs.search.current.value !== '' ? 'white' : 'black',
            backgroundColor: !!refs.search.current && refs.search.current.value !== '' ? '#2f3e6c' : '#efefef',
            marginBottom: 25,
          }}
          startAdornment={
            <div style={{ paddingTop: 5, marginLeft: 10 }} hidden={disabled}>
              <Icon
                scale="120%"
                name={!!refs.search.current && refs.search.current.value !== '' ? 'SEARCH_SELECTED' : 'SEARCH_DEFAULT'}
              />
            </div>
          }
          endAdornment={
            <div
              hidden={disabled || !refs.search.current || (!!refs.search.current && refs.search.current.value) === ''}
              onMouseLeave={this.changeHover}
              onMouseOver={this.changeHover}
              onClick={handlers.searchClear}
              className={styles.clearIcon}
            >
              <Icon name={this.iconStates[hoverState]} />
            </div>
          }
          inputRef={refs.search}
        />
      </div>
    );
  }
}

Search.defaultProps = {
  type: 'Search',
};

export default Search;
