import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
// import { ToastContainer } from "react-toastify";
import { Route, Switch, Redirect } from 'react-router-dom';
import styles from './Portal.module.css';
import Header from './Header';

import * as Pages from '../../Pages';
// import BreadCrumb from "@falconbrick/fbt-web-breadcrumb";

import setAuthorizationToken from '../../utils/setAuthorizationToken';
import isAuthorised from '../../utils/isAuthorised';
import NotifierContainer from '../../Pages/NotifierContainer/NotifierContainer';
import isSelected from '../../utils/isSelectedBasic';

if (localStorage.jwtToken) {
  setAuthorizationToken(localStorage.jwtToken);
}

export default class Portal extends Component {
  render() {
    const { Dashboard, Programs, Reports, ChangePassword, Logout, ProjectSelection, PropertySelection } = Pages.default;
    return (
      <div className={styles.portal}>
        <Header />
        <Switch>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/reports" component={isAuthorised(isSelected(Reports))} />
          <Route path="/programs" component={isAuthorised(isSelected(Programs))} />
          <Route path="/changePassword" component={isAuthorised(ChangePassword)} />
          <Route path="/logout" component={Logout} />
          <Route exact path="/project" component={ProjectSelection} />
          <Route path="/project/property" component={PropertySelection} />
          <Redirect to="/project" />
        </Switch>
        {/* <ToastContainer /> */}
        <NotifierContainer />
      </div>
    );
  }
}
