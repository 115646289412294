import React, { Component, PropTypes } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { App } from "@falconbrick/fbt-portal-reports";
import styles from "./Reports.module.css";

export default class Reports extends Component {
  componentDidMount() {
    // console.log("hello");
    document.getElementById("sliderMenu").style.display = "block";
    document.getElementById("headerProperty").style.display = "block";
  }

  render() {
    return (
      <div className={styles.reportsContainer}>
        <App />
      </div>
    );
  }
}
