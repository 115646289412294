import axios from 'axios';
import _ from 'lodash';
import config from '../configs';
import * as types from '../constants';

// for block
export const blockInfoFetch = () => ({
  type: types.BLOCK_INFO_FETCH,
});

export const blockInfoSuccess = blockInfo => ({
  type: types.BLOCK_INFO_SUCCESS,
  payload: { blockInfo },
});

export const blockSelected = selected => ({
  type: types.BLOCK_INFO_SET,
  payload: { selected },
});

export const blockInfoError = error => ({
  type: types.BLOCK_INFO_ERROR,
  payload: { error },
});

// for unit type
export const unitTypeInfoFetch = () => ({
  type: types.UNIT_TYPE_FETCH,
});

export const unitTypeInfoSuccess = unitTypeInfo => ({
  type: types.UNIT_TYPE_SUCCESS,
  payload: { unitTypeInfo },
});

export const unitTypeSelected = selected => ({
  type: types.UNIT_TYPE_SET,
  payload: { selected },
});

export const unitTypeInfoError = error => ({
  type: types.UNIT_TYPE_ERROR,
  payload: { error },
});

// for snag type
// export const blockInfoFetch = () => ({
//   type: types.BLOCK_INFO_FETCH,
// });
//
// export const blockInfoSuccess = blockInfo => ({
//   type: types.BLOCK_INFO_SUCCESS,
//   payload: { blockInfo },
// });
//
// export const blockSelected = Selected => ({
//   type: types.BLOCK_INFO_SET,
//   payload: { Selected },
// });
//
// export const blockInfoError = error => ({
//   type: types.BLOCK_INFO_ERROR,
//   payload: { error },
// });

// for snags
export const setTowerSnagInfoFetch = () => ({
  type: types.TOWER_SNAG_EXPORTS_FETCH,
});

export const setTowerSnagInfoSuccess = towerSnagInfo => ({
  type: types.TOWER_SNAG_EXPORTS_SUCCESS,
  payload: { towerSnagInfo },
});

export const setTowerSnagInfoError = error => ({
  type: types.TOWER_SNAG_EXPORTS_ERROR,
  payload: { error },
});

export function getBlocks(requestData) {
  return dispatch => {
    dispatch(blockInfoFetch());
    return axios
      .post(`${config.ApiUrl}/api/towerSnagInfo`, requestData)
      .then(res => {
        dispatch(blockInfoSuccess(res.data.data));
      })
      .catch(error => dispatch(blockInfoError(error)));
  };
}

export function getTowerSnagInfo(requestData) {
  return dispatch => {
    dispatch(setTowerSnagInfoFetch());
    return axios
      .post(`${config.ApiUrl}/api/towerSnagInfo/v2`, requestData)
      .then(res => {
        if (res.data.success) {
          dispatch(setTowerSnagInfoSuccess(res.data.data));
        } else {
          dispatch(setTowerSnagInfoSuccess([]));
        }
      })
      .catch(error => dispatch(setTowerSnagInfoError(error)));
  };
}

export function getUnitTypes(requestData) {
  return dispatch => {
    dispatch(unitTypeInfoFetch(requestData));
    return axios
      .post(`${config.ApiUrl}/api/unitType/getAllUnitTypes`, requestData)
      .then(res => {
        if (res.data.success) {
          dispatch(unitTypeInfoSuccess(res.data.data));
        } else {
          // dispatch(unitTypeInfoSuccess([]));
          const temp = {
            data: [
              {
                id: 'noData',
                name: 'No Data',
              },
            ],
            success: true,
          };
          dispatch(unitTypeInfoSuccess(temp.data));
        }
      })
      .catch(error => dispatch(unitTypeInfoError(error)));
  };
}

export function getInitalData(requestData) {
  return (dispatch, getState) => {
    dispatch(getBlocks(requestData)).then(() => {
      dispatch(getUnitTypes(requestData)).then(() => {
        const state = getState();
        // console.log(state);
        dispatch(blockSelected(state.blockInfoReports.blockInfo[0]));
        dispatch(unitTypeSelected(state.towerSnagInfo.unitTypeInfo[0]));
        const modifiedReqData = requestData;
        modifiedReqData.block_id = state.blockInfoReports.blockInfo[0].id;
        modifiedReqData.unit_type = state.towerSnagInfo.unitTypeInfo[0].id;
        dispatch(getTowerSnagInfo(modifiedReqData));
      });
    });
  };
}

export function updateData(requestData) {
  return (dispatch, getState) => {
    dispatch(getBlocks(requestData)).then(() => {
      dispatch(getUnitTypes(requestData)).then(() => {
        const state = getState();
        //  console.log(state);
        const blockSelectedItem = _.find(state.blockInfoReports.blockInfo, {
          id: requestData.block_id,
        });
        const unitTypeSelectedItem = _.find(state.towerSnagInfo.unitTypeInfo, {
          id: requestData.unit_type,
        });
        dispatch(blockSelected(blockSelectedItem));
        dispatch(unitTypeSelected(unitTypeSelectedItem));
        dispatch(getTowerSnagInfo(requestData));
      });
    });
  };
}
