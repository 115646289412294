/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Checkbox } from '@material-ui/core';
import SelectAll from '@material-ui/icons/SelectAll';
import Clear from '@material-ui/icons/Clear';
import styles from './filter.module.css';

class Filter extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { filterFlag, handlers } = this.props;
    return (
      <div className={styles.filter}>
        <RadioGroup name="filter" value={filterFlag} onChange={handlers.filterChange}>
          <FormControlLabel
            value="filterAll"
            control={<Checkbox icon={<SelectAll />} checkedIcon={<SelectAll />} value="filterAll" />}
            label="Select All"
          />
          <FormControlLabel
            value="filterBy"
            control={<Checkbox icon={<Clear />} checkedIcon={<Clear />} value="filterBy" />}
            label="Deselect All"
          />
        </RadioGroup>
      </div>
    );
  }
}

Filter.defaultProps = {
  type: 'Filter',
};

export default Filter;
