import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import ActivityManager from '../Components/ActivityManager';
import ActivityManagerLog from '../Components/ActivityManagerLog';

class App extends React.Component {
  render() {
    return (
      <Router>
      <Switch>
        <Route
            exact
            path="/programs/activity-manager"
            component={ActivityManager}
          />
        <Route
            exact
            path="/programs/activity-manager-log"
            component={ActivityManagerLog}
          />
          </Switch>
      </Router>
    );
  }
}

export default App;
