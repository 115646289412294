import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./ActivityWidget.module.css";
import * as activityActions from "../../../Actions/ActivityManagerActions";
import PlotActivities from "./PlotActivities";
import NoResultsPlot from "./NoResultsPlot";
import PlotUnits from "./PlotUnits";
import PaginationToolbar from "./PaginationToolbar";
import SearchBox from "./SearchBox";
import cx from "classnames";

class ActivityWidget extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.jumpLast = this.jumpLast.bind(this);
    this.jumpFirst = this.jumpFirst.bind(this);
    this.activitySearch = this.activitySearch.bind(this);
    this.unitSearch = this.unitSearch.bind(this);
    this.noResultsIn = this.noResultsIn.bind(this);
  }

  prev() {
    const { currentPage, dispatch } = this.props;
    if (currentPage > 1) {
      dispatch(activityActions.pageChange(currentPage - 1));
    }
  }

  next() {
    const { currentPage, totalPages, dispatch } = this.props;
    if (currentPage < totalPages) {
      dispatch(activityActions.pageChange(currentPage + 1));
    }
  }

  jumpLast() {
    const { currentPage, totalPages, dispatch } = this.props;
    if (currentPage < totalPages) {
      dispatch(activityActions.pageChange(totalPages));
    }
  }

  jumpFirst() {
    const { currentPage, totalPages, dispatch } = this.props;
    if (currentPage > 1) {
      dispatch(activityActions.pageChange(1));
    }
  }

  activitySearch(value) {
    this.props.dispatch(activityActions.searchActivities(value));
  }

  unitSearch(value) {
    this.props.dispatch(activityActions.searchUnits(value));
  }
  noResultsIn(unitOrAct) {
    this.props.dispatch(activityActions.noResultsIn(unitOrAct));
  }

  render() {
    const {
      gridSize,
      currentPage,
      totalPages,
      grouping,
      currentActivities,
      units,
      currentData,
      selectedActivities,
      selectedActivityUnits,
      loading,
      noResult,
      sortFunction,
      dataTable
    } = this.props;
    let totalUnits = grouping? 
      Object.keys(dataTable.floors).map(x=>dataTable.floors[x].length).reduce((a, b) => a + b, 0):
      dataTable.units.length
    const showHeader = ((grouping, loading, currentData, currentActivities, units) => {
      if (loading)
        return false;
      if (grouping === null)
        return false;
      if (grouping && Object.keys(units).length === 0)
        return false;
      if (Object.keys(currentData).length === 0) {
        if (grouping)
          return false;
        else {
          if (currentActivities.length > 0 && Object.keys(units).length === 0)
            return false;
          return true;
        }
      }
      return true;
    })(grouping, loading, currentData, currentActivities, units);
    return (
      <div className={styles.activityWidget} >
        <div
          className={styles.activityHeader}
          ref={c => {
            this.header = c;
          }}
          style={{ display: showHeader ? 'flex' : 'none' }}
        >
          <div className={cx(styles.unitsHeader, styles.units)} >
            <h4>UNITS</h4>
            <SearchBox
              onChangeCallback={this.unitSearch}
              placeholder="search by unit"
              name="unit_search"
            />
          </div>
          <div className={cx(styles.activitiesHeader, styles.activities)}>
            <h4>ACTIVITIES</h4>
            <SearchBox
              onChangeCallback={this.activitySearch}
              placeholder="search by activity"
              name="activity_search"
            />
            <PlotActivities
              activities={currentActivities}
              selectedActivities={selectedActivities}
              loading={loading}
              data={currentData}
              units={units}
              noResultsIn={this.noResultsIn}
              noResult={noResult}
              sortFunction={sortFunction}
            />
          </div>
        </div>
        <div className={styles.activityFooter}>
          <PaginationToolbar
            currentPage={currentPage}
            totalPages={totalPages}
            totalActivities={dataTable.activities.length}
            totalUnits={totalUnits}
            next={this.next}
            prev={this.prev}
            jumpFirst={this.jumpFirst}
            jumpLast={this.jumpLast}
          />
        </div>
        <div className={styles.activityBody} style={{ display: !!noResult ? 'block' : 'none' }}>
          <NoResultsPlot noResult={noResult} />
        </div>
        <div className={styles.activityBody}>
          <PlotUnits
            activities={currentActivities}
            units={units}
            data={currentData}
            grouping={grouping}
            selectedActivityUnits={selectedActivityUnits}
            loading={loading}
            noResultsIn={this.noResultsIn}
            noResult={noResult}
            sortFunction={sortFunction}
          />
        </div>
      </div>
    );
  }
}

export default connect()(ActivityWidget);
