import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'whatwg-fetch';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import 'react-table/react-table.css';

import {
  Icon,
  Header,
  Grid,
  Divider,
  Loader,
  Breadcrumb,
  Container,
} from 'semantic-ui-react';
import { deltaActivityInfoGet, downloadExcel } from '../../actions';

class deltaInfoActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deltaActivityInfo: [],
      errors: {},
      isLoading: true,
      hasErrored: false,
      active: 0,
      from_date: '',
      to_date: '',
    };
  }

  componentDidMount() {
    let fromDate = moment()
      .startOf('day')
      .format('YYYY-MM-DD HH:MM:SS');
    let toDate = moment().format('YYYY-MM-DD HH:MM:SS');
    if (this.getParameterByName('fromDate')) {
      fromDate = this.getParameterByName('fromDate');
    }
    if (this.getParameterByName('toDate')) {
      toDate = this.getParameterByName('toDate');
    }
    // const blockId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1);
    const requestData = {
      fromDate,
      toDate,
    };
    this.setState({ from_date: fromDate });
    this.setState({ to_date: toDate });
    // console.log(propertySelected);
    this.props.deltaActivityInfoGet(requestData).then(
      res => {
        // console.log(res);
        this.setState.isLoading = false;
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\]]/g, '\\$&');
    let regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  render() {
    const {
      deltaActivityInfo,
      isLoading,
      hasErrored,
      errors,
      propertySelected,
    } = this.props;
    console.log(this.props);
    // console.log({from_date});
    // let customer_id = profile.customer_id;
    if (isLoading) {
      return (
        <Container textAlign="center">
          <Loader active className="centered" />
        </Container>
      );
    }
    if (!deltaActivityInfo.length && !isLoading) {
      return (
        <Container fluid textAlign="center">
          <p>No data change found!</p>
          <Divider hidden section />
          <Divider hidden section />
          <Divider hidden section />
        </Container>
      );
    }
    if (hasErrored) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again
            {errors}
          </div>
        </div>
      );
    }
    const paths = this.props.location.pathname.split('/').map((p, i, arr) => {
      if (p === 'deltaUnitInfo') {
        p = 'Delta Report';
      }
      if (i === 0)
        return {
          key: i,
          content: <p>{propertySelected.name}</p>,
          active: i === arr.length - 1,
          link: i < arr.length - 1,
        };
      if (i === arr.length - 1)
        return {
          key: i,
          content: p,
          active: i === arr.length - 1,
        };

      return {
        key: i,
        content: (
          <Link to={{}`${arr.slice(0, i + 1).join('/')}`}>
            {p.charAt(0).toUpperCase() + p.slice(1)}
          </Link>
        ),
        active: i === arr.length - 1,
        link: i < arr.length - 1,
      };
    });
    const pageOptions = [10, 20, 25, 50, 100];
    return (
      <div>
        <Breadcrumb icon="chevron right" sections={paths} />
        <Header as="h2"> Delta Report (Activity) </Header>
        <Divider />
        <Grid columns="equals" padded style={{ width: '100%' }}>
          <Grid.Row textAlign="center">
            <ReactTable
              data={deltaActivityInfo}
              pageSizeOptions={pageOptions}
              columns={[
                {
                  columns: [
                    {
                      Header: () => (
                        <span>
                          Activity Name
                          <Icon disabled name="sort" />
                        </span>
                      ),
                      id: 'work_flow_name',
                      accessor: d => d.work_flow_name,
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: ['work_flow_name'],
                        }),
                      filterAll: true,
                      filterable: true,
                      minWidth: 150,
                    },
                  ],
                },
                {
                  Header: () =>
                    `Status between ${moment(this.state.from_date).calendar(
                      null,
                      {
                        sameElse: 'Do MMMM [at] LT',
                      }
                    )} - ${moment(this.state.to_date).calendar()}`,
                  headerStyle: {
                    flex: '100 0 auto',
                    backgroundColor: 'rgba(81,87,93,0.8)',
                    borderRadius: '2px',
                    transition: 'all .2s ease-out',
                    borderRight: '1px solid rgb(0, 0, 0)',
                    borderLeft: '1px solid rgb(0, 0, 0)',
                  },
                  style: {
                    flex: '100 0 auto',
                    backgroundColor: 'rgba(81,87,93,0.8)',
                    transition: 'all .2s ease-out',
                    borderRight: '1px solid rgb(0, 0, 0)',
                    borderLeft: '1px solid rgb(0, 0, 0)',
                  },
                  columns: [
                    {
                      Header: () => <span>Started</span>,
                      id: 'started',
                      accessor: d => d.started,
                      sortable: false,
                      headerStyle: {
                        flex: '100 0 auto',
                        backgroundColor: 'rgba(81,87,93,0.4)',
                        transition: 'all .2s ease-out',
                        borderRight: '1px solid rgb(0, 0, 0)',
                      },
                      style: {
                        flex: '100 0 auto',
                        backgroundColor: 'rgba(81,87,93,0.4)',
                        transition: 'all .2s ease-out',
                        borderRight: '1px solid rgb(0, 0, 0)',
                      },
                    },
                    {
                      Header: () => <span>Completed</span>,
                      id: 'finished',
                      accessor: d => d.finished,
                      Cell: row => (
                        <span>
                          {row.value === null || row.value.length === 0
                            ? 'NA'
                            : row.value}
                        </span>
                      ),
                      sortable: false,
                      headerStyle: {
                        flex: '100 0 auto',
                        backgroundColor: 'rgba(81,87,93,0.4)',
                        transition: 'all .2s ease-out',
                        borderLeft: '1px solid rgb(0, 0, 0)',
                        borderRight: '1px solid rgb(0, 0, 0)',
                      },
                      style: {
                        flex: '100 0 auto',
                        backgroundColor: 'rgba(81,87,93,0.4)',
                        transition: 'all .2s ease-out',
                        borderLeft: '1px solid rgb(0, 0, 0)',
                        borderRight: '1px solid rgb(0, 0, 0)',
                      },
                    },
                  ],
                },
                {
                  Header: () =>
                    `Status as on ${moment(this.state.to_date).calendar()}`,
                  headerStyle: {
                    flex: '100 0 auto',
                    backgroundColor: 'rgba(81,87,93,0.8)',
                    borderRadius: '2px',
                    transition: 'all .2s ease-out',
                    borderRight: '1px solid rgb(0, 0, 0)',
                  },
                  style: {
                    flex: '100 0 auto',
                    backgroundColor: 'rgba(81,87,93,0.8)',
                    transition: 'all .2s ease-out',
                    borderRight: '1px solid rgb(0, 0, 0)',
                  },
                  columns: [
                    {
                      Header: () => <span>In Progress</span>,
                      id: 'current',
                      accessor: d => d.current,
                      Cell: row => (
                        <span>
                          {row.value === null || row.value.length === 0
                            ? 'NA'
                            : row.value}
                        </span>
                      ),
                      sortable: false,
                      headerStyle: {
                        flex: '100 0 auto',
                        backgroundColor: 'rgba(81,87,93,0.4)',
                        borderRadius: '2px',
                        transition: 'all .2s ease-out',
                      },
                      style: {
                        flex: '100 0 auto',
                        backgroundColor: 'rgba(81,87,93,0.4)',
                        transition: 'all .2s ease-out',
                      },
                    },
                  ],
                },
              ]}
              defaultPageSize={deltaActivityInfo.length}
              className="-striped -highlight"
              noDataText="No Data Found"
              defaultSorted={[
                {
                  id: 'work_flow_name',
                  desc: false,
                },
              ]}
              style={{
                width: '100%',
                height: '100%',
                transition: 'all .2s ease-out',
              }}
            />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  deltaActivityInfo: state.deltaActivityInfo.deltaActivityInfo,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
  propertySelected: state.property.propertySelected.id,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deltaActivityInfoGet,
      downloadExcel,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(deltaInfoActivity);
