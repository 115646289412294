import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
// import { toast } from "react-toastify";
import Select from 'react-super-select';
import { Form, Label } from 'semantic-ui-react';
import { SelectionBox } from '@falconbrick/fbt-web-components';
import styles from './PropertySelection.module.css';
import { propertyGet, propertySet } from '../../../../actions/Property';
import { cleanReportsLocal } from '../../../utils/UtilityFunctions';

class PropertySelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertySelected: '',
    };
    this.propertySetId = this.propertySetId.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.propertySelected !== prevState.propertySelected) {
      return { propertySelected: nextProps.propertySelected };
    }
    return null;
  }

  propertySetId(selected) {
    cleanReportsLocal();
    // console.log(selected);
    const data = _.find(this.props.propertyList, o => o.id === selected);
    // console.log(data);
    this.props.propertySet(data).then(
      () => {
        // toast.success(
        //   `Property Updated to ${this.props.propertySelected.name}`
        // );
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
  }

  render() {
    const { propertyList } = this.props;
    // console.log(propertyInfo);
    return (
      <div id="headerProperty">
        <SelectionBox
          id="porertySelection"
          labelText="Phase"
          optionId="id"
          optionValue="name"
          callBack={this.propertySetId}
          options={propertyList}
          value={this.state.propertySelected}
          LabelTextCustomClasses={styles.labelText}
          SelectionBoxCustomClasses={styles.selectionBox}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  property: state.property,
  propertySelected: state.property.propertySelected.id,
  propertyList: state.property.propertyList,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      propertyGet,
      propertySet,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertySelection)
);
