import * as types from '../constants/Notify';

const initialState = {
    open: false,
    className: "info",
    messageContent: 'aaa'
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case types.NOTIFY_CLOSE_NOTIFICATION:
            return { ...state, open: false };
        case types.NOTIFY_SET_PROPS:
            return { ...state, messageContent: action.payload.messageContent, className: action.payload.className, open: true };
        default:
            return state;
    }
};
