import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { clr_byTowerGet, clr_byTowerUpdate } from "../../actions";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Header, Divider, Breadcrumb, Button } from "semantic-ui-react";
import Helmet from "react-helmet";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate } from "react-day-picker/moment";
import BytowerData from "./ByTowerData";

class ByTower extends Component {
  constructor(props) {
    super(props);
    this.state = {
      towerInfo: [],
      errors: {},
      isLoading: true,
      hasErrored: false,
      active: 0,
      from: "",
      to: ""
    };
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.update_byTower = this.update_byTower.bind(this);
  }

  componentDidMount() {
    let from = moment()
      .startOf("day")
      .toISOString();
    let to = moment().toISOString();
    if (this.getParameterByName("from")) {
      from = this.getParameterByName("from");
    }
    if (this.getParameterByName("to")) {
      to = this.getParameterByName("to");
    }
    const requestData = { fromDate: from, toDate: to };
    this.setState({ from });
    this.setState({ to });
    this.props.clr_byTowerGet(requestData).then(
      res => {
        // console.log(res);
        this.setState.isLoading = false;
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  toggleClass(index) {
    this.setState({ active: index });
  }

  focusTo() {
    this.timeout = setTimeout(() => this.to.getInput().focus(), 0);
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from }, () => {
      if (!this.state.to) {
        this.focusTo();
      }
    });
  }

  handleToChange(to) {
    this.setState({ to });
    // const blockId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1);
    const requestData = {
      fromDate: this.state.from,
      toDate: this.state.to
    };
    this.props.clr_byTowerUpdate(requestData).then(
      res => {
        // console.log(res);
        this.setState.isLoading = false;
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
  }

  update_byTower(from) {
    let requestData = {};
    if (from) {
      this.setState({ from });
      this.setState({ to: moment().toISOString() });
      requestData = {
        fromDate: from,
        toDate: moment().toISOString()
      };
    } else {
      requestData = {
        fromDate: this.state.from,
        toDate: this.state.to
      };
    }
    this.props.clr_byTowerUpdate(requestData).then(
      res => {
        // console.log(res);
        this.setState.isLoading = false;
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\]]/g, "\\$&");
    let regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  render() {
    const { from, to, active, propertySelected } = this.props;
    const modifiers = { start: from, end: to };
    const paths = this.props.location.pathname.split("/").map((p, i, arr) => {
      if (p === "checklistRejected") {
        p = "Rejected Checklist";
      }
      if (i === 0)
        return {
          key: i,
          content: <p>{propertySelected.name}</p>,
          active: i === arr.length - 1,
          link: i < arr.length - 1
        };
      if (i === 3)
        return {
          key: i,
          content: "",
          active: i === arr.length - 1,
          link: i < arr.length - 1
        };

      if (i === arr.length - 1)
        return {
          key: i,
          content: p,
          active: i === arr.length - 1
        };
      return {
        key: i,
        content: (
          <Link to={`${arr.slice(0, i + 1).join("/")}`}>
            {p.charAt(0).toUpperCase() + p.slice(1)}
          </Link>
        ),
        active: i === arr.length - 1,
        link: i < arr.length - 1
      };
    });

    return (
      <div>
        <Breadcrumb icon="chevron right" sections={paths} size="big" />
        <Header as="h2">Rejected Checklist </Header>
        <Divider />
        <div className="InputFromTo">
          <DayPickerInput
            value={from}
            placeholder="From Date"
            format="DD-MM-YYYY"
            formatDate={formatDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { after: to },
              toMonth: to,
              modifiers,
              numberOfMonths: 1,
              showOutsideDays: "false"
            }}
            onDayChange={this.handleFromChange}
          />{" "}
          —{" "}
          <span className="InputFromTo-to">
            <DayPickerInput
              ref={el => (this.to = el)}
              value={to}
              placeholder="To Date"
              format="DD-MM-YYYY"
              formatDate={formatDate}
              dayPickerProps={{
                selectedDays: [from, { from, to }],
                disabledDays: { before: from },
                todayButton: "Till Now",
                modifiers,
                month: from,
                fromMonth: from,
                numberOfMonths: 1,
                showOutsideDays: "false"
              }}
              onDayChange={this.handleToChange}
            />
          </span>
          <span>
            <Button
              size="small"
              toggle
              basic
              active={active}
              onClick={() => {
                this.update_byTower();
              }}
              className="updateInfoDeltaButton"
            >
              Update
            </Button>
          </span>
          <Divider hidden />
          <span>
            <Button
              size="small"
              toggle
              basic
              active={active}
              onClick={() => {
                const from = moment()
                  .startOf("day")
                  .toISOString();
                this.update_byTower(from);
              }}
              className="updateInfoDelta"
            >
              Today
            </Button>
          </span>
          <span>
            <Button
              size="small"
              toggle
              basic
              active={active}
              onClick={() => {
                const from = moment()
                  .startOf("isoWeek")
                  .toISOString();
                this.update_byTower(from);
              }}
              className="updateInfoDelta"
            >
              This Week
            </Button>
          </span>
          <span>
            <Button
              size="small"
              basic
              onClick={() => {
                const from = moment()
                  .startOf("month")
                  .toISOString();
                this.update_byTower(from);
              }}
              className="updateInfoDelta"
            >
              This Month
            </Button>
          </span>
          <Helmet>
            <style>
              {`
                            .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)
                            {
                            background-color: #f0f8ff !important;
                            color: black;
                            }
                            .InputFromTo .DayPicker-Day {
                                border-radius: 0 !important;
                              }
                              .InputFromTo .DayPicker-Day--start {
                                border-top-left-radius: 50% !important;
                                border-bottom-left-radius: 50% !important;
                              }
                              .InputFromTo .DayPicker-Day--end {
                                border-top-right-radius: 50% !important;
                                border-bottom-right-radius: 50% !important;
                              }
                              .InputFromTo .DayPickerInput-Overlay {
                                width: 250px;
                              }
                              .InputFromTo-to .DayPickerInput-Overlay {
                                margin-left: -198px;
                              }
                            `}
            </style>
          </Helmet>
        </div>
        <Divider hidden />
        <BytowerData />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  towerInfo: state.towerInfo.towerInfo,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
  propertySelected: state.property.propertySelected
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clr_byTowerGet,
      clr_byTowerUpdate
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ByTower)
);
