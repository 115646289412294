import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';

class Notify extends Component {
  state = {
    vertical: 'top',
    horizontal: 'center',
  };
  colour = {
    success: 'green',
    warning: 'orange',
    error: 'red',
    info: 'blue'
  }

  // handleClick = e => {
  //   this.setState({ open: true, ...this.state });
  // };


  render() {
    const { vertical, horizontal } = this.state;
    const colour = this.colour;
    const { messageContent, className, open, closeHandler } = this.props;
    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          className={className}
          onClose={closeHandler}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id='message'>{messageContent}</span>}
          style={{ color: colour[className] }}
        />
      </div>
    );
  }
}

export default Notify;
