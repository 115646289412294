/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import styles from './apply.module.css';

class Apply extends Component {
  render() {
    const { selected, handlers, children } = this.props;
    return (
      <div className={styles.apply}>
        <Button
          fullWidth
          size="large"
          style={{
            /* position: 'fixed', top: '100%', left: '0%', transform: 'translateY(-100%)', */ backgroundColor:
              '#2f3e6c',
            color: 'white',
          }}
          disabled={selected.length === 0}
          onClick={handlers.applyChange}
        >
          {children}
        </Button>
      </div>
    );
  }
}

Apply.defaultProps = {
  type: 'Apply',
};

export default Apply;
