import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import * as ReportWidgets from '@falconbrick/fbt-portal-reports';
import * as ManagerWidgets from '@falconbrick/fbt-portal-manager';
import fbtAppReducers from '../reducers';

const { ReportReducer } = ReportWidgets;
const { programReducers } = ManagerWidgets;

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

export default function configureStore(ExtReducers, initialState) {
  // const combinedObj = {};
  // const appReducerKeys = Object.keys(fbtAppReducers);
  // for (let i = 0; i < appReducerKeys.length; i++) {
  //   combinedObj[appReducerKeys[i]] = fbtAppReducers[appReducerKseys[i]];
  // }
  // const combinedReducers = combineReducers(combinedObj);
  // const store = createStoreWithMiddleware(combinedReducers, initialState);
  // let rootReducer = fbtAppReducers;
  // ExtReducers
  //   ? (rootReducer = combineReducers(
  //       Object.assign({}, fbtAppReducers, ExtReducers)
  //     ))
  //   : (rootReducer = combineReducers(fbtAppReducers));

  // other store enhancers if any
  const rootReducer = combineReducers(
    Object.assign({}, fbtAppReducers, ReportReducer, programReducers)
  );
  /* eslint-disable no-underscore-dangle */
  const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    compose(applyMiddleware(thunk))
  );
  /* eslint-enable */
  if (module.hot) {
    module.hot.accept(fbtAppReducers, () => {
      const nextReducer = fbtAppReducers;
      store.replaceReducer(nextReducer);
    });
  }
  return store;
}
