import { SET_UNIT_DETAIL_DATA, UPDATE_UNIT_DETAIL_DATA } from '../constants';

const initialState = {
    unitDataDetail: {}
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_UNIT_DETAIL_DATA:
            return {
                unitDataDetail: action.unitDataDetail
            };
        case UPDATE_UNIT_DETAIL_DATA:
            return {
                ...state,
                unitDataDetail: action.unitDataDetail
            };
        default:
            return state;
    }
}