import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  GridContainer,
  GridItem,
  Button,
  CustomInput,
} from '@falconbrick/fbt-web-components';
import { notify } from '../../../actions/Notify';

// @material-ui/custom components

// custom component style
import styles from './LoginStyle.module.css';

// actions
import { login, loginWithHasuraJWT } from '../../../actions/Auth';

import Logo from '../../../resources/images/fullLogoWhit.svg';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardAnimaton: true,
      email: '',
      password: '',
      errorEmail: false,
      errorPassword: false,
      errors: {},
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  // componentWillMount() {
  //   if (localStorage.getItem('jwtToken') || localStorage.getItem('jwtToken') === undefined) {
  //     this.context.router.history.push('/project');
  //   }
  // }

  componentDidMount() {
    // animation for card
    setTimeout(() => {
      this.setState({ cardAnimaton: false });
    }, 700);
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.isValid()) {
      this.setState({ errors: {} });
      this.props.dispatch(login(this.state));
      this.props.dispatch(loginWithHasuraJWT(this.state));
    }
  }

  onChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  isValid() {
    const { errors, isValid } = this.validateInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  }

  validateInput(data) {
    const errors = {};

    if (_.isEmpty(data.email)) {
      this.setState({ errorEmail: true });
      errors.email = 'This field is required';
    }

    if (_.isEmpty(data.password)) {
      this.setState({ errorPassword: true });
      errors.password = 'This field is required';
    }

    return {
      errors,
      isValid: _.isEmpty(errors),
    };
  }

  test = options => {
    console.log('call me');
    console.log(options);
  };

  render() {
    const { error } = this.props;
    return (
      <div className={styles.containerMain}>
        <GridContainer
          justify="center"
          className={styles.showHidden}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              this.onSubmit(e);
            }
          }}
        >
          <GridItem xs={12} sm={12} md={4}>
            <Card className={this.state.cardAnimaton ? styles.cardHidden : styles.showHidden}>
              <form className={styles.form}>
                <CardHeader color="primary" className={styles.cardHeader}>
                  <div className={styles.logo}>
                    <img alt="Logo" src={Logo} />
                  </div>
                  <div className={styles.fp} onClick={e => this.props.history.push('/forgot-password')}>
                    Forgot Password?
                  </div>
                </CardHeader>
                <CardBody>
                  {error && <div className={styles.errorAlert}>{error.errors.form}</div>}
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                      error: this.state.errorEmail,
                    }}
                    inputProps={{ type: 'email', required: true }}
                    onChange={e => this.onChange(e)}
                    inputCustomClasses={styles.loginInput}
                  />
                  <CustomInput
                    labelText="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                      error: this.state.errorPassword,
                    }}
                    inputProps={{ type: 'password', required: true }}
                    onChange={e => this.onChange(e)}
                    inputCustomClasses={styles.loginInput}
                  />
                </CardBody>
                <CardFooter className={styles.cardFooter}>
                  <Button color="primary" size="lg" className={styles.loginButton} onClick={e => this.onSubmit(e)}>
                    Login to dashboard
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  error: state.authManager.error,
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Login)
);
