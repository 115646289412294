import axios from "axios";
import jwtDecode from "jwt-decode";
import setAuthorizationToken from "../container/utils/setAuthorizationToken";
import { notify } from "./Notify";
import config from "../configs/clientConfig";
import Cookie from '../configs/cookie'
import * as auth from "../constants/Auth";

// login
export const setCurrentUserFtech = () => ({
    type: auth.SET_CURRENT_USER_FETCH
});

export const setCurrentUserSuccess = user => ({
    type: auth.SET_CURRENT_USER_SUCCESS,
    payload: { user }
});

export const setCurrentUserError = error => ({
    type: auth.SET_CURRENT_USER_ERROR,
    payload: { error }
});

export function login(data) {
    return dispatch => {
        dispatch(setCurrentUserFtech());
        return axios
            .post(`${config.ApiUrl}/api/auth`, data)
            .then(res => {
                const { token } = res.data;
                localStorage.setItem("jwtToken", token);
                localStorage.setItem("newPort", true);
                setAuthorizationToken(token);
                dispatch(setCurrentUserSuccess(jwtDecode(token)));
            })
            .catch(error => dispatch(setCurrentUserError(error.response.data)));
    };
}

export function loginWithHasuraJWT(data) {
    return dispatch => {
        dispatch(setCurrentUserFtech());
        return axios
        .post(`${config.RestApiUrl}auth/hasuraJWT`, data)
        .then(res => {
            const { token } = res.data;
            localStorage.setItem("hasuraJwtToken", token);
        })
        .catch(error => dispatch(setCurrentUserError(error.response.data)));
    };
}
// logout
export function logout() {
    return dispatch => {
        Cookie.erase("jwtAuth");
        Cookie.erase("projectsInfo");
        localStorage.clear();
        window.localStorage.clear();
        setAuthorizationToken(false);
        dispatch(notify("Logged out successfully!", 'success'))
        dispatch(setCurrentUserSuccess({}));
    };
}

// forgot password

export function forgotPassword(email) {
    return dispatch => {
        const data = {
            email
        };
        return axios
            .post(`${config.ApiUrl}/api/auth/forgotPassword`, data)
            .then(res => {
                localStorage.clear();
                setAuthorizationToken(false);
                dispatch(notify(`Email Sent to ${data.email}`, 'success'));
                dispatch(setCurrentUserSuccess({}))
            });
    };
}

export function changePassword(data, email) {
    return dispatch => {
        const postData = {
            email,
            perviousPassword: data.perviousPassword,
            newPassword: data.newPassword
        };
        return axios
            .post(config.ApiUrl + "/api/auth/changePassword", postData)
            .then(response => {
                dispatch(notify('Password has been changed successfully. Kindly logout and login', 'success'));
                // dispatch(setCurrentUserSuccess({}));
            });
    };
}
