import React, { Component } from "react";
import { PortalCoreIcon as Icon } from "@falconbrick/fbt-web-components";
import styles from "./SearchBox.module.css";

class SearchBox extends Component {
  constructor() {
    super();
    this.state = {
      filter: false,
      value: "",
      clearSearch: {
        false: "CLEAR_SEARCH",
        true: "CLEAR_SEARCH_HOVER"
      },
      hover: false
    };
  }

  onChangeHandler(e) {
    const { value } = e.target;
    this.props.onChangeCallback(value);
    this.setState({
      value,
      filter: value.length > 0
    });
  }

  hoverHandler(flag, e) {
    this.setState({ hover: flag });
  }

  clearFilter() {
    this.props.onChangeCallback("");
    this.setState({
      value: "",
      filter: false
    });
  }

  render() {
    const { placeholder, name } = this.props;
    const { filter, value, clearSearch, hover } = this.state;
    let className = ""
    let iconName = ""
    if (filter) {
      className = styles.searchBoxActive
      iconName = "SEARCH_SELECTED"
    } else {
      className = styles.searchBox
      iconName = "SEARCH_DEFAULT"
    }
    return (
      <div className={className}>
        <Icon name={iconName} customClassName={styles.iconStyle} />
        <input
          name={name}
          placeholder={placeholder}
          onChange={e => this.onChangeHandler(e)}
          value={value}
        />
        {filter && (
          <span onMouseOver={e => this.hoverHandler(true, e)} onMouseLeave={e => this.hoverHandler(false, e)} onClick={() => this.clearFilter()} role="link" tabIndex={0} style={{ "outline": "0" }}>
            <Icon name={clearSearch[hover]} customClassName={styles.iconStyle} />
          </span>
        )}
      </div>
    );
    // return (
    //   <div className={styles.searchBox}>
    //     <Icon name="SEARCH" customClassName={styles.iconStyle} />
    //     <input
    //       name={name}
    //       placeholder={placeholder}
    //       onChange={e => this.onChangeHandler(e)}
    //       value={value}
    //     />
    //     {filter && (
    //       <span onClick={() => this.clearFilter()} role="link" tabIndex={0}>
    //         <Icon name="CLEAR_SEARCH" customClassName={styles.iconStyle} />
    //       </span>
    //     )}
    //   </div>
    // );
  }
}

export default SearchBox;
