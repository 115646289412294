import {
  NAV_INFO_FETCH,
  NAV_INFO_SUCCESS,
  NAV_INFO_FAILURE
} from "../actions/Project";

const initialState = {
  navInfo: {},
  loading: false,
  error: null
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case NAV_INFO_FETCH:
      return { ...state, loading: true, error: null };
    case NAV_INFO_SUCCESS:
      return { ...state, loading: false, navInfo: action.payload.navInfo };
    case NAV_INFO_FAILURE:
      return { ...state, loading: false, error: action.payload.error };
    default:
      return state;
  }
};
