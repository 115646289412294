import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'whatwg-fetch';
import { bindActionCreators } from 'redux';
import Select from 'react-super-select';
import _ from 'lodash';

import {
  Grid,
  Header,
  Divider,
  Loader,
  Container,
  Form,
  Label,
} from 'semantic-ui-react';
import UnitInfoDetail from './UnitInfoDetail';
import {
  stage2TowerInfoGetDetail,
  stage2UpdateTowerInfoDetail,
} from '../../../actions';

class TowerInfoDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      towerInfoDetail: [],
      errors: {},
      isLoading: true,
      hasErrored: false,
      active: 0,
    };
    this.towerInfoDetailUpdate = this.towerInfoDetailUpdate.bind(this);
  }

  componentDidMount() {
    const { propertySelected } = this.props;
    const blockSelected = '';
    const unitSelected = '';
    let requestData = {};
    // if (localStorage.getItem('stage2_blockSelectedId')) {
    //   blockSelected = localStorage.getItem('stage2_blockSelectedId');
    // }
    // if (localStorage.getItem('stage2_unitSelectedId')) {
    //   unitSelected = localStorage.getItem('stage2_unitSelectedId');
    // }
    // if (this.props.match.params.blockId) {
    //   blockSelected = this.props.match.params.blockId;
    //   localStorage.setItem('stage2_blockSelectedId', blockSelected);
    // }
    // if (this.props.match.params.unitId) {
    //   unitSelected = this.props.match.params.unitId;
    //   localStorage.setItem('stage2_unitSelectedId', unitSelected);
    // }
    requestData = {
      project: propertySelected,
      blockSelected,
      unitSelected,
    };
    //  console.log(projectSelected + blockSelected);
    this.props.stage2TowerInfoGetDetail(requestData).then(
      res => {
        // console.log(res);
        this.setState.isLoading = false;
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.props.propertySelected) !==
      JSON.stringify(nextProps.propertySelected)
    ) {
      const { propertySelected } = nextProps;
      const blockSelected = '';
      const unitSelected = '';
      let requestData = {};
      requestData = {
        project: propertySelected,
        blockSelected,
        unitSelected,
      };

      this.props.stage2TowerInfoGetDetail(requestData).then(
        res => {
          this.setState.isLoading = false;
        },
        err => this.setState({ errors: err.response, hasErrored: true })
      );
    }
  }

  towerInfoDetailUpdate = function(options) {
    localStorage.setItem('stage2_blockSelectedName', options.name);
    localStorage.setItem('stage2_blockSelectedId', options.id);
    this.props.stage2UpdateTowerInfoDetail(options.id).then(
      res => {
        // console.log(res);
        this.setState.isLoading = false;
        this.setState.hasErrored = false;
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
  };

  render() {
    const { towerInfoDetail, isLoading, hasErrored, errors } = this.props;
    let selectedBlock;
    if (localStorage.getItem('stage1_blockSelectedName')) {
      selectedBlock = localStorage.getItem('stage1_blockSelectedName');
    }

    if (!towerInfoDetail.length) {
      return (
        <div>
          <Loader active indeterminate className="centered">
            Getting Block Data..
          </Loader>
        </div>
      );
    }

    if (_.isEmpty(towerInfoDetail) && !isLoading) {
      return (
        <Container fluid textAlign="center">
          <p>No data found!</p>
          <Divider hidden section />
          <Divider hidden section />
          <Divider hidden section />
        </Container>
      );
    }

    if (hasErrored) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again
            {errors}
          </div>
        </div>
      );
    }

    return (
      <div>
        <Header as="h2" className="print-hide">
          Stage 2 Report
        </Header>
        <Divider className="print-hide" />
        <Grid columns="equals" padded>
          <Grid.Row className="print-hide">
            <Form className="dropDownForm print-hide">
              <Form.Field>
                <Label>Block</Label>
                <Select
                  placeholder={selectedBlock || 'Please Select A Block'}
                  dataSource={towerInfoDetail}
                  onChange={this.towerInfoDetailUpdate}
                  clearSearchOnSelection
                  deselectOnSelectedOptionClick={false}
                  searchable
                  clearable={false}
                />
              </Form.Field>
            </Form>
          </Grid.Row>
          <Divider className="print-hide" />
          <Grid.Row>
            <UnitInfoDetail {...this.props} />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.auth.user,
  towerInfoDetail: state.towerInfoDetail.towerInfoDetail,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
  propertySelected: state.property.propertySelected,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      stage2TowerInfoGetDetail,
      stage2UpdateTowerInfoDetail,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TowerInfoDetails);
