import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "whatwg-fetch";
import moment from "moment";
import { byUnitGet } from "../../actions";
import { bindActionCreators } from "redux";
import _ from "lodash";

import {
  Button,
  Header,
  Grid,
  Divider,
  Loader,
  Breadcrumb,
  Container,
  Table
} from "semantic-ui-react";

class UnitInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unitInfo: [],
      errors: {},
      isLoading: true,
      hasErrored: false
    };
  }

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    // console.log(this.props);
    this.props
      .byUnitGet(this.props.match.params.stage, this.props.match.params.blockId)
      .then(
        res => {
          // console.log(res);
          this.setState.isLoading = false;
        },
        err => this.setState({ errors: err.response, hasErrored: true })
      );
  }

  snagLinkClick(unit) {
    localStorage.setItem("unitSelectedName", unit.title);
    localStorage.setItem("unitSelectedId", unit.t_node);
    this.props.history.push(`/reports/blockInfo/${unit.id}/${unit.t_node}/`);
  }

  unitInfoLinkClicked(unit) {
    const towerSelectedId = localStorage.getItem("blockSelectedId");
    const towerSelectedIndex = localStorage.getItem("blockSelectedIndex");
    localStorage.setItem("unitInfo_unitSelectedId", unit.title);
    localStorage.setItem("unitInfo_blockSelectedId", towerSelectedId);
    localStorage.setItem("unitInfo_blockSelectedIndex", towerSelectedIndex);
    localStorage.removeItem("unitInfo_unitSelectedIndex");
    localStorage.removeItem("unitInfo_blockSelectedName");
    // window.location.pathname = 'project/blockInfo';
    // window.location = '/project/unitInfo' ;
    this.props.history.push("/reports/unitInfo");
  }

  render() {
    const {
      unitInfo,
      isLoading,
      hasErrored,
      errors,
      propertySelected
    } = this.props;
    const paths = this.props.location.pathname.split("/").map((p, i, arr) => {
      if (p === "activityCompleted") {
        p = "Activity Completed";
      }
      if (i === 0)
        return {
          key: i,
          content: <p>{propertySelected.name}</p>,
          active: i === arr.length - 1,
          link: i < arr.length - 1
        };
      if (i === arr.length - 1)
        return {
          key: i,
          content: p,
          active: i === arr.length - 1
        };
      return {
        key: i,
        content: (
          <Link to={`${arr.slice(0, i + 1).join("/")}`}>
            {p.charAt(0).toUpperCase() + p.slice(1)}
          </Link>
        ),
        active: i === arr.length - 1,
        link: i < arr.length - 1
      };
    });
    if (isLoading) {
      return (
        <div>
          <Loader active indeterminate className="centered">
            Updating Units
          </Loader>
        </div>
      );
    }

    if (_.isEmpty(unitInfo) && !isLoading) {
      return (
        <Container fluid>
          <Grid.Row>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Unit</Table.HeaderCell>
                  <Table.HeaderCell>Currently Responsible</Table.HeaderCell>
                  <Table.HeaderCell>Activity</Table.HeaderCell>
                  <Table.HeaderCell>Date Updated</Table.HeaderCell>
                  <Table.HeaderCell>Report</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <p className="noDataFound">No Data Found!</p>
            </Table>
          </Grid.Row>
        </Container>
      );
    }

    if (hasErrored) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again
            {errors}
          </div>
        </div>
      );
    }

    return (
      <div>
        <Breadcrumb icon="chevron right" sections={paths} size="big" />
        <Header as="h2">Activity Completed </Header>
        <Divider />
        <Grid columns="16">
          <Grid.Row>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Unit</Table.HeaderCell>
                  <Table.HeaderCell>Activity</Table.HeaderCell>
                  <Table.HeaderCell>Date Updated</Table.HeaderCell>
                  <Table.HeaderCell>Report</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {unitInfo.map(function(unit, index) {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>{unit.title}</Table.Cell>
                      <Table.Cell>
                        {unit.activity} ({unit.t_node})
                      </Table.Cell>
                      <Table.Cell>
                        {moment(unit.sync_ut).format("DD-MM-YYYY")}
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          type="submit"
                          secondary
                          size="tiny"
                          onClick={() => this.snagLinkClick(unit)}
                        >
                          View Report
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  );
                }, this)}
              </Table.Body>
            </Table>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  unitInfo: state.unitInfo.unitInfo,
  towerInfo: state.towerInfo.towerInfo,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
  propertySelected: state.property.propertySelected
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      byUnitGet
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UnitInfo)
);
