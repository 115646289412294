import { SET_UNIT_DETAIL_DATA_STAGE, SET_UNIT_DATA_STAGE } from '../constants';
import _ from 'lodash'

const initialState = {
  unitDataDetailStage: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_UNIT_DETAIL_DATA_STAGE:
      return {
        ...state,
        unitDataDetailStage: _.sortBy(action.unitDataDetailStage, 'title'),
      };
    default:
      return state;
  }
};
