import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./ActivityBlock.module.css";
import * as activityActions from "../../../../../Actions/ActivityManagerActions";
import cx from "classnames";

class ActivityBlock extends Component {
  constructor() {
    super();
    this.state = {};
  }

  toggleSelect() {
    const { activity, dispatch } = this.props;
    dispatch(activityActions.selectActivity(activity));
  }

  render() {
    const { activity, selected, fake } = this.props;
    return (
      <div
        onClick={() => this.toggleSelect()}
        className={cx(styles.activityBlock, selected ? styles.selected : "")}
      >
        <input type="checkbox" checked={selected} />
        <span>{activity}</span>
      </div>
    );
  }
}

export default connect()(ActivityBlock);
