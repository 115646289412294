import * as types from '../constants/Property';

const initialState = {
  propertySelected: {},
  propertyList: {},
  loading: false,
  error: null
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.PROPERTY_INFO_FETCH:
      return { ...state, loading: true, error: null };
    case types.PROPERTY_INFO_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case types.PROPERTY_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        propertyList: action.payload.propertyList
      };
    case types.PROPERTY_SET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        propertySelected: action.payload.propertySelected
      };
    default:
      return state;
  }
};
