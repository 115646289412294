import axios from "axios";
import _ from 'lodash';
import config from "../configs/clientConfig";
import * as types from "../Constants/ActivityConstants";
import * as commonActions from "./CommonActions";
import setAuthorizationToken from "../utils/setAuthorizationToken";

/* get inital activitis from api */
export function getInitalData(requestData) {
    if (localStorage.jwtToken) {
        setAuthorizationToken(localStorage.jwtToken);
    }
    return dispatch => {
                dispatch(getActivityInfo(requestData));
    };
}
/* get activitis from api */
export function getActivityInfo(requestData) {
    return dispatch => {
        dispatch(getActivityInfoFetch());
        axios
            .post(`${config.ApiUrl}/api/activity/getActivityLog`, {
                timeout: 120000,
                property_id: requestData.propertySelected,
            })
            .then(res => {
                if (res.data.status === 0) {
                    dispatch(getActivityInfoError(res.data.msg));
                } else {
                    dispatch(getActivityInfoSuccess(res.data));
                }
            })
            .catch(err => {
                dispatch(getActivityInfoError(err));
            });
    };
}


export function getStartActivityInfoFetch() {
    return {
        type: types.GET_START_ACTIVITY_INFO_FETCH
    };
}


export function getActivityInfoFetch() {
    return {
        type: types.GET_ACTIVITY_INFO_FETCH
    };
}

export function getActivityInfoSuccess(response) {
    const units = response;
    if (units) {
        _.sortBy(units, 'id');
        
    }

    return {
        type: types.GET_ACTIVITY_LOG_SUCCESS,
        units
    };
}

export function getActivityInfoError(error) {
    return {
        type: types.GET_ACTIVITY_INFO_ERROR,
        error
    };
}

export function initActivityWidget() {
    return {
        type: types.INIT_ACTIVITY_WIDGET
    };
}

export function changeType(unitType, selectedBlock) {
    return dispatch => {
        dispatch(getSortByPrecedenceFunction({ unit_type: unitType, property_id: selectedBlock }))
        dispatch({
            type: types.ACTIVITY_CHANGE_TYPE,
            unitType
        });
    };
}

export function pageChange(page) {
    return {
        type: types.ACTIVITY_PAGE_CHANGE,
        page
    };
}

export function searchActivities(q) {
    return {
        type: types.ACTIVITY_SEARCH_ACTIVITIES,
        q
    };
}

export function searchUnits(q) {
    return {
        type: types.ACTIVITY_SEARCH_UNITS,
        q
    };
}

export function selectActivity(activity) {
    return {
        type: types.SELECT_ACTIVITY_GROUP,
        activity
    };
}

export function selectUnitActivity(id, activity) {
    return {
        type: types.SELECT_UNIT_ACTIVITY,
        id,
        activity
    };
}

export function noResultsIn(unitOrAct) {
    return {
        type: types.SEARCH_HAS_NO_RESULTS,
        unitOrAct
    };
}
export function setPrecedenceMatrix(objArray) {
    return {
        type: types.ACTIVITY_PRECEDENCE_MATRIX,
        objArray
    }
}
export function resetActivityWidget() {
    return {
        type: types.RESET_ACTIVITIES
    }
}
