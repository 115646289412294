import { SET_UNIT_DETAIL_DATA_STAGE, SET_UNIT_DATA_STAGE } from '../constants';

const initialState = {
  unitInfoStage: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_UNIT_DATA_STAGE:
      return {
        ...state,
        unitInfoStage: action.unitInfoStage,
      };
    default:
      return state;
  }
};
