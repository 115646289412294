import { SET_DELTA_ACTIVITY_INFO } from '../constants';

const initialState = {
    deltaActivityInfo: {}
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_DELTA_ACTIVITY_INFO:
            return {
                deltaActivityInfo: action.deltaActivityInfo
            };
        default:
            return state;
    }
}