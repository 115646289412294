import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { photoMiss_byStageGet, photoMiss_byUnitGet } from "../../actions";
import { bindActionCreators } from "redux";
import _ from "lodash";
import {
  Header,
  Grid,
  Divider,
  Loader,
  Breadcrumb,
  Container,
  Table,
  Button
} from "semantic-ui-react";

class Clr_byStageGet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unitInfo: [],
      unitDataDetail: [],
      errors: {},
      isLoading: true,
      hasErrored: false,
      active: 0
    };
  }

  componentDidMount() {
    this.props
      .photoMiss_byUnitGet(
        this.props.match.params.checklist_id,
        this.props.match.params.block_id,
        this.props.match.params.checkpoint
      )
      .then(
        res => {
          // console.log(res);
          this.setState.isLoading = false;
        },
        err => this.setState({ errors: err.response, hasErrored: true })
      );
  }

  snagLinkClick(unit) {
    localStorage.setItem("unitSelectedName", unit.title);
    localStorage.setItem("unitSelectedId", unit.work_flow_node);
    this.props.history.push(
      `/reports/blockInfo/${unit.id}/${unit.work_flow_node}/details`
    );
  }

  render() {
    const { unitDataDetail, isLoading, hasErrored, errors } = this.props;
    const paths = this.props.location.pathname.split("/").map((p, i, arr) => {
      if (p === "photoMissing") {
        p = "Checklist Photo Missing";
      }
      if (i === 0)
        return {
          key: i,
          content: <p>Home</p>,
          active: i === arr.length - 1,
          link: i < arr.length - 1
        };
      if (i >= 5)
        return {
          key: i,
          content: "",
          active: i === arr.length - 1,
          link: i < arr.length - 1
        };

      if (i === arr.length - 1)
        return {
          key: i,
          content: p,
          active: i === arr.length - 1
        };
      return {
        key: i,
        content: (
          <Link to={`${arr.slice(0, i + 1).join("/")}`}>
            {p.charAt(0).toUpperCase() + p.slice(1)}
          </Link>
        ),
        active: i === arr.length - 1,
        link: i < arr.length - 1
      };
    });

    if (isLoading) {
      return (
        <Container textAlign="center">
          <Loader active indeterminate className="centered">
            Getting activity info...
          </Loader>
        </Container>
      );
    }

    if (_.isEmpty(unitDataDetail) && !isLoading) {
      return (
        <Container fluid textAlign="center">
          <p>No Block/s found</p>
        </Container>
      );
    }

    if (hasErrored) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again
            {errors}
          </div>
        </div>
      );
    }

    return (
      <div>
        <Breadcrumb icon="chevron right" sections={paths} size="big" />
        <Header as="h2">Photo Missing Units</Header>
        <Divider />
        <Grid columns="16">
          <Grid.Row>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Unit</Table.HeaderCell>
                  <Table.HeaderCell>Date Updated</Table.HeaderCell>
                  <Table.HeaderCell>Report</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {unitDataDetail.map(function(unit, index) {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>{unit.title}</Table.Cell>
                      <Table.Cell>
                        {moment(unit.sync_ut).format("DD-MM-YYYY")}
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          type="submit"
                          secondary
                          size="tiny"
                          onClick={() => this.snagLinkClick(unit)}
                        >
                          View Details
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  );
                }, this)}
              </Table.Body>
            </Table>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  unitInfo: state.unitInfo.unitInfo,
  unitDataDetail: state.unitDataDetail.unitDataDetail,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
  propertySelected: state.property.propertySelected.id
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      photoMiss_byStageGet,
      photoMiss_byUnitGet
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Clr_byStageGet)
);
