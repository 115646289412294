import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
// import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import _ from "lodash";
import {
  Button,
  Divider,
  Image,
  Loader,
  Card,
  Container,
  Header
} from "semantic-ui-react";
import { projectGet, projectSet } from "../../../actions/Project";
import defaultProjectImg from "../../../resources/images/project_default.png";
import { cleanReportsLocal } from "../../utils/UtilityFunctions";

import styles from "./ProjectSelection.module.css";

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectGet: {},
      projectSet: {},
      errors: {},
      isLoading: true,
      hasErrored: false
    };
  }

  componentDidMount() {
    this.props.projectGet(this.state).then(
      res => {
        this.setState.isLoading = false;
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
    document.getElementById("sliderMenu").style.display = "none";
    document.getElementById("headerProperty").style.display = "none";
  }

  projectSetId(project) {
    cleanReportsLocal();
    this.props.projectSet(project).then(() => {
      // toast.success("Project Set");
      this.props.history.push(`/project/property?project=${project.id}`); //
    });
  }

  render() {
    const { project, errors } = this.props;

    if (project.loading) {
      return (
        <Container fluid className={styles.projectContainer}>
          <div className={styles.subText}>Step - 01 / 02</div>
          <div className={styles.h2}>select a project</div>
          <br />
          <Loader active />
        </Container>
      );
    }

    if (_.isEmpty(project.projectList)) {
      return (
        <Container fluid className={styles.projectContainer}>
          <div className={styles.subText}>Step - 01 / 02</div>
          <div className={styles.h2}>Select A Project</div>
          <p className={styles.emptyAssign}>No projects assigned to you</p>
        </Container>
      );
    }

    if (project.error) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again - {project.error}
            {errors}
          </div>
        </div>
      );
    }

    return (
      <Container fluid className={styles.projectContainer}>
        <div className={styles.subText}>Step - 01 / 02</div>
        <div className={styles.h2}>Select A Project</div>
        <br />
        <Card.Group>
          {project.projectList.map(function(list, index) {
            return (
              <Card key={list.name}>
                <Card.Content>
                  {list.project_image || null ? (
                    <Image src={list.project_image} size="medium" />
                  ) : (
                    <Image src={defaultProjectImg} size="medium" />
                  )}
                  <Divider hidden />
                  <Card.Header>{list.name}</Card.Header>
                  <Divider hidden />
                  <Card.Content>{list.address}</Card.Content>
                  <Divider />
                  <Card.Content>
                    <Button
                      type="submit"
                      onClick={() => this.projectSetId(list)}
                    >
                      Select {list.name}
                    </Button>
                  </Card.Content>
                </Card.Content>
              </Card>
            );
          }, this)}
        </Card.Group>
        <Divider hidden section />
        <Divider hidden section />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  project: state.project,
  property: state.property
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      projectGet,
      projectSet
    },
    dispatch
);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Project)
);
