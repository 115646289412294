import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./PlotSingleUnit.module.css";
import StatusBlock from "./StatusBlock";

class PlotSingleUnit extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentWillMount() {}

  render() {
    const { data, selectedActivitiesMap, selectedActivityUnits } = this.props;
    const unitUI = data.map(d => (
      <StatusBlock
        status={(d && d.status) || false}
        id={(d && d.id) || null}
        activity={(d && d.activity) || null}
        selected={(d && selectedActivityUnits.includes(d.id)) || false}
      />
    ));
    return <div className={styles.plotSingleUnit}>{unitUI}</div>;
  }
}

// export default PlotSingleUnit;
export default connect()(PlotSingleUnit);
