import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./Title.module.css";

class Title extends React.Component {
  render() {
    const { customStyle, titleName } = this.props;
    const titleStyles = {};
    titleStyles[styles.title] = true;
    if (customStyle) titleStyles[customStyle] = true;
    return <div className={cx(titleStyles)}>{titleName}</div>;
  }
}

Title.propTypes = {};

export default Title;
