import React from 'react';
import { connect } from 'react-redux';
// import {addFlashMessage} from '../actions/flashMessages';
import _ from 'lodash';
import jwtDecode from 'jwt-decode';

import { Button, Icon, Header, Divider } from 'semantic-ui-react';

export default function(ComposedComponent) {
  class Authorised extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isAuthorised: true,
      };
      this.setAuthorised = this.setAuthorised.bind(this);
    }

    setAuthorised() {
      this.setState({ isAuthorised: false });
    }

    render() {
      const { navInfo, isLoading } = this.props;
      const { isAuthorised } = this.state;
      let flag = false;
      if (!_.isEmpty(navInfo) && !isLoading && !flag) {
        // console.log(navInfo);
        navInfo.map(link => {
          const a = link.link.match(/^(\/[^/]*\/?){2,}/g);
          const b = this.props.location.pathname.match(/^(\/[^/]*\/?){2,}/g);
          if (a && b !== null) {
            if (
              link.link.match(/^(\/[^/]*\/?){2,}/g)[0] ===
                this.props.location.pathname.match(/^(\/[^/]*\/?){2,}/g)[0] ||
              `${link.link.match(/^(\/[^/]*\/?){2,}/g)[0]}/` ===
                this.props.location.pathname.match(/^(\/[^/]*\/?){2,}/g)[0]
            ) {
              flag = true;
            }
          }
          return true;
        }, this);
        if (
          this.props.location.pathname === '/project' ||
          this.props.location.pathname === '/project/property' ||
          this.props.location.pathname === '/changePassword'
        ) {
          flag = true;
        }
        if (!flag && isAuthorised) {
          this.setAuthorised();
        }
      }
      if (isAuthorised && localStorage.getItem('jwtToken')) {
        let token = '';
        if (localStorage.getItem('jwtToken')) {
          token = localStorage.getItem('jwtToken');
        }
        const decoded = jwtDecode(token);
        const dateNow = Math.round(new Date().getTime() / 1000);
        // console.log(decoded.exp);
        // console.log(dateNow);
        if (decoded.exp < dateNow) {
          return (
            <div style={{ padding: '5rem 1rem' }}>
              <Header as="h1" icon textAlign="center">
                <Icon name="meh" />
                <Header.Content>401 Unauthorized</Header.Content>
                <Divider hidden />
                <p>Your have missed the bus to construction site!!!</p>
                <p>Session has expired! Please logout and login again.</p>
                <Button
                  type="submit"
                  onClick={() => {
                    localStorage.clear();
                    this.props.history.push('/logout');
                  }}
                >
                  Logout
                </Button>
              </Header>
            </div>
          );
        }
        return <ComposedComponent {...this.props} />;
      }
      return (
        <div style={{ padding: '5rem 1rem' }}>
          <Header as="h1" icon textAlign="center">
            <Icon name="frown" />
            <Header.Content>404 Page not found!</Header.Content>
            <Divider hidden />
            <p>Lost your way to construction site?</p>
            <Button type="submit" onClick={() => this.props.history.push('/project')}>
              Click to go back
            </Button>
          </Header>
        </div>
      );
    }
  }

  const mapStateToProps = state => ({
    profile: state.authManager.user,
    navInfo: state.navInfo.navInfo,
  });

  return connect(mapStateToProps)(Authorised);
}
