import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "whatwg-fetch";
import moment from "moment";
import { unitInfoGetAllSteps } from "../../actions";
import { bindActionCreators } from "redux";
import _ from "lodash";

import {
  Button,
  Table,
  Grid,
  Divider,
  Loader,
  Breadcrumb,
  Container,
  Header
} from "semantic-ui-react";

class UnitInfoAllSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unitInfoGetAllSteps: [],
      errors: {},
      isLoading: true,
      hasErrored: false
    };
  }

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.unitInfoGetAllSteps(this.props.match.params).then(
      res => {
        // console.log(res);
        this.setState.isLoading = false;
        this.setState.hasErrored = false;
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
  }

  snagLinkClick(data) {
    localStorage.setItem("unitSelectedId", data.step_name);
    this.props.history.push(`/reports/unitInfo${data.snagLink}/`);
  }

  render() {
    const { unitInfoAllSteps, isLoading, hasErrored, errors } = this.props;

    // console.log(this.props);
    if (isLoading) {
      return (
        <div>
          <Loader active indeterminate className="centered">
            Updating Units
          </Loader>
        </div>
      );
    }
    if (_.isEmpty(unitInfoAllSteps) && !isLoading) {
      const paths = this.props.location.pathname.split("/").map((p, i, arr) => {
        if (i === 0)
          return {
            key: i,
            content: <p>Home</p>,
            active: i === arr.length - 1,
            link: i < arr.length - 1
          };
        if (p === "unitInfo") {
          if (localStorage.getItem("unitInfo_unitSelectedId")) {
            p = localStorage.getItem("unitInfo_unitSelectedId");
          } else {
            p = "Unit Info";
          }
        }
        if (i === 3)
          return {
            key: i,
            content: "",
            active: i === arr.length - 1,
            link: i < arr.length - 1
          };

        if (i === arr.length - 1)
          return {
            key: i,
            content: p,
            active: i === arr.length - 1
          };
        return {
          key: i,
          content: (
            <Link to={`${arr.slice(0, i + 1).join("/")}`}>
              {p.charAt(0).toUpperCase() + p.slice(1)}
            </Link>
          ),
          active: i === arr.length - 1,
          link: i < arr.length - 1
        };
      });
      return (
        <div>
          <Breadcrumb icon="chevron right" sections={paths} size="big" />
          <Divider hidden />
          <Container fluid textAlign="center">
            <Grid columns="16">
              <Grid.Row>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Step Name</Table.HeaderCell>
                      <Table.HeaderCell>Completed By</Table.HeaderCell>
                      <Table.HeaderCell>Completed On</Table.HeaderCell>
                      <Table.HeaderCell>Open</Table.HeaderCell>
                      <Table.HeaderCell>Close</Table.HeaderCell>
                      <Table.HeaderCell>For Review</Table.HeaderCell>
                      <Table.HeaderCell>On Hold</Table.HeaderCell>
                      <Table.HeaderCell>Report</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <div>
                    <p className="noDataFound">No Data Found!</p>
                  </div>
                </Table>
              </Grid.Row>
            </Grid>
          </Container>
        </div>
      );
    }
    if (hasErrored) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again
            {errors}
          </div>
        </div>
      );
    }

    const paths = this.props.location.pathname.split("/").map((p, i, arr) => {
      if (i === 0)
        return {
          key: i,
          content: <p>Home</p>,
          active: i === arr.length - 1,
          link: i < arr.length - 1
        };
      if (p === "unitInfo") {
        if (localStorage.getItem("unitInfo_unitSelectedId")) {
          p = localStorage.getItem("unitInfo_unitSelectedId");
        } else {
          p = "Unit Info";
        }
      }
      if (i === 3)
        return {
          key: i,
          content: "",
          active: i === arr.length - 1,
          link: i < arr.length - 1
        };

      if (i === arr.length - 1)
        return {
          key: i,
          content: p,
          active: i === arr.length - 1
        };
      return {
        key: i,
        content: (
          <Link to={`${arr.slice(0, i + 1).join("/")}`}>
            {p.charAt(0).toUpperCase() + p.slice(1)}
          </Link>
        ),
        active: i === arr.length - 1,
        link: i < arr.length - 1
      };
    });
    return (
      <div>
        <Breadcrumb icon="chevron right" sections={paths} size="big" />
        <Header as="h2">Unit History</Header>
        <Divider hidden />
        <Grid columns="16">
          <Grid.Row>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Step Name</Table.HeaderCell>
                  <Table.HeaderCell>Completed By</Table.HeaderCell>
                  <Table.HeaderCell>Completed On</Table.HeaderCell>

                  <Table.HeaderCell>Open</Table.HeaderCell>
                  <Table.HeaderCell>Close</Table.HeaderCell>
                  <Table.HeaderCell>For Review</Table.HeaderCell>
                  <Table.HeaderCell>On Hold</Table.HeaderCell>
                  <Table.HeaderCell>Report</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {unitInfoAllSteps.map(function(data, index) {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>{data.step_name}</Table.Cell>
                      <Table.Cell>{data.username}</Table.Cell>
                      <Table.Cell>
                        {moment(data.updated_log).format("DD-MM-YYYY")}
                      </Table.Cell>
                      <Table.Cell>{data.snag_status_summary.open}</Table.Cell>
                      <Table.Cell>{data.snag_status_summary.close}</Table.Cell>
                      <Table.Cell>{data.snag_status_summary.fr}</Table.Cell>
                      <Table.Cell>{data.snag_status_summary.hold}</Table.Cell>
                      <Table.Cell>
                        {data.snag_status_summary.open ||
                        data.snag_status_summary.close ||
                        data.snag_status_summary.fr ||
                        data.snag_status_summary.hold ? (
                          <Button
                            type="submit"
                            secondary
                            size="tiny"
                            onClick={() => this.snagLinkClick(data)}
                          >
                            View Report
                          </Button>
                        ) : (
                          <p />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                }, this)}
              </Table.Body>
            </Table>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  unitInfoAllSteps: state.unitInfoAllSteps.unitInfoAllSteps,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
  propertySelected: state.property.propertySelected.id
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      unitInfoGetAllSteps
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnitInfoAllSteps);
