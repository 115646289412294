// renders main raect app
import React from 'react';
import { render } from 'react-dom';
import { Provider, connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
// auth imports
import jwtDecode from 'jwt-decode';
// import ErrorBoundary from './ErrorBoundary';
import setAuthorizationToken from './container/utils/setAuthorizationToken';
import { setCurrentUserSuccess } from './actions/Auth';
import { setProjectSuccess, navInfoSuccess } from './actions/Project';
import { getPropertyListSuccess, setPropertySuccess } from './actions/Property';

import './styleRaw.css';
// import clientConfig from './config/clientConfig';
import * as Layouts from './container/Layouts';

import configureStore from './store/configureStore';
import Cookie from './configs/cookie';

const store = configureStore();
const { LandingLayout } = Layouts.default;
const jwtAuth = Cookie.get("jwtAuth");
const projectsInfo = JSON.parse(Cookie.get("projectsInfo"));

if (!localStorage.newPort) {
  localStorage.clear();
}

if (localStorage.jwtToken) {
  setAuthorizationToken(localStorage.jwtToken);
  store.dispatch(setCurrentUserSuccess(jwtDecode(localStorage.jwtToken)));

}
if(jwtAuth && projectsInfo) {
  localStorage.setItem('jwtToken', jwtAuth);
  localStorage.setItem('newPort', true);
  localStorage.setItem('propertyList', JSON.stringify(projectsInfo.propertyList));
  localStorage.setItem('projectList', JSON.stringify(projectsInfo.projList));
  localStorage.setItem('propertySelected', JSON.stringify(projectsInfo.propertySeleted));
  localStorage.setItem('projectSelected', JSON.stringify(projectsInfo.selectedProj));
  setAuthorizationToken(jwtAuth);
  store.dispatch(setCurrentUserSuccess(jwtDecode(jwtAuth)));
  store.dispatch(setProjectSuccess(projectsInfo.selectedProj));
  store.dispatch(setPropertySuccess(projectsInfo.propertySeleted));
}
if (localStorage.projectSelected !== undefined) {
  store.dispatch(setProjectSuccess(JSON.parse(localStorage.projectSelected)));
}

if (localStorage.propertyList !== undefined) {
  store.dispatch(getPropertyListSuccess(JSON.parse(localStorage.propertyList)));
}
if (localStorage.propertySelected) {
  if (localStorage.propertySelected === 'undefined') {
    store.dispatch(setPropertySuccess({}));
  } else {
    store.dispatch(setPropertySuccess(JSON.parse(localStorage.propertySelected)));
  }
}

if (localStorage.navInfo !== undefined) {
  store.dispatch(navInfoSuccess(JSON.parse(localStorage.navInfo)));
}

window.onbeforeunload = () => {
  localStorage.removeItem('navInfo');
};

render(
  <Provider store={store}>
    <Router>
      <LandingLayout />
    </Router>
  </Provider>,
  document.getElementById('app')
);
