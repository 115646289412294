import { combineReducers } from "redux";
import authManager from "./authManager";
import project from "./ProjectSelection";
import property from "./PropertySelection";
import navInfo from "./NavInfo";
import notify from "./Notifier";

export default {
    authManager,
    project,
    property,
    navInfo,
    notify
};
