import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom';

import style from 'react-table/react-table.css';
// import "../semanticUi/react-super-select.css";
// import "../semanticUi/slateTheme.css";

import { Sidebar, Container, Menu, Image, Icon } from 'semantic-ui-react';

import TowerInfo from './blockInfo';
import TowerInfoDetail from './unitInfo/TowerInfoDetail';
// import towerSnagInfo from './towerSnagInfoNew';
import StageReport0 from './stageInfo/stage0/TowerInfoDetail';
import StageReport1 from './stageInfo/stage1/TowerInfoDetail';
import StageReport2 from './stageInfo/stage2/TowerInfoDetail';
import PowerBi from './powerBi/PowerBi';
import SnagInfo from './snagInfo/SnagInfo';
import SnagInfoDetail from './snagInfo/SnagInfoDetail';
import unitInfoAllSteps from './unitInfo/UnitInfoAllSteps';
import deltaUnitInfo from './deltaInfo/DeltaUnitInfo';
import deltaActivityInfo from './deltaInfo/DeltaActivityInfo';
import dashboard from './dashboard/Dashboard';

// Activity completed
import ActivityCompleted from './activityCompleted/ByTower';
import ActivityCompletedUnits from './activityCompleted/ByUnits';
// //Activity Rejected
import checkListRejectedCount from './checkpointRejected/ByTower';
import checkpointRejected from './checkpointRejected/ByStage';
import checkpointRejectedUnits from './checkpointRejected/ByUnits';
// //Activity missing photos
import photoMissingCount from './photoMissing/ByTower';
import photoMissing from './photoMissing/ByStage';
import photoMissingUnits from './photoMissing/ByUnits';
// New tower snags
import towerSnagInfo from './towerSnagInfoNew';

class Reports extends React.Component {
  render() {
    // console.log("stage3");
    return (
      <div>
        <Switch>
          <Route exact path="/reports/blockInfo" component={TowerInfo} />
          <Route
            exact
            path="/reports/blockInfo/:blockId?"
            component={TowerInfo}
          />
          <Route exact path="/reports/unitInfo" component={TowerInfoDetail} />
          <Route
            exact
            path="/reports/unitInfo/:pathParam1?"
            component={unitInfoAllSteps}
          />
          <Route
            exact
            path="/reports/blockInfo/:pathParam1?/:pathParam2?"
            component={SnagInfo}
          />
          <Route
            path="/reports/blockInfo/:pathParam1?/:pathParam2?/details"
            component={SnagInfoDetail}
          />
          <Route
            exact
            path="/reports/unitInfo/:pathParam1?/:pathParam2?"
            component={SnagInfo}
          />
          <Route
            path="/reports/unitInfo/:pathParam1?/:pathParam2?/details"
            component={SnagInfoDetail}
          />
          <Route path="/reports/towerSnagInfo" component={towerSnagInfo} />
          {/* <Route */}
          {/* path="/reports/towerSnagInfoNew" */}
          {/* component={towerSnagInfoNew} */}
          {/* /> */}
          <Route path="/reports/deltaUnitInfo" component={deltaUnitInfo} />
          <Route exact path="/reports/stage0" component={StageReport0} />
          <Route
            exact
            path="/reports/stage0/:blockId/:unitId"
            component={StageReport0}
          />
          <Route exact path="/reports/stage1" component={StageReport1} />
          <Route
            exact
            path="/reports/stage1/:blockId/:unitId"
            component={StageReport1}
          />
          <Route exact path="/reports/stage2" component={StageReport2} />
          <Route
            exact
            path="/reports/stage2/:blockId/:unitId"
            component={StageReport2}
          />
          <Route
            path="/reports/deltaActivityInfo"
            component={deltaActivityInfo}
          />
          <Route
            exact
            path="/reports/activityCompleted"
            component={ActivityCompleted}
          />
          <Route
            exact
            path="/reports/activityCompleted/:stage"
            component={ActivityCompleted}
          />
          <Route
            exact
            path="/reports/activityCompleted/:stage/:blockId"
            component={ActivityCompletedUnits}
          />
          <Route
            exact
            path="/reports/checklistRejected"
            component={checkListRejectedCount}
          />
          <Route
            exact
            path="/reports/checklistRejected/:stage"
            component={checkListRejectedCount}
          />
          <Route
            exact
            path="/reports/checklistRejected/:stage/:blockId"
            component={checkpointRejected}
          />
          <Route
            path="/reports/checklistRejected/:checklist_id/:block_id/:checkpoint"
            component={checkpointRejectedUnits}
          />
          <Route
            exact
            path="/reports/checklistRejected"
            component={checkListRejectedCount}
          />
          <Route
            exact
            path="/reports/photoMissing"
            component={photoMissingCount}
          />
          <Route
            exact
            path="/reports/photoMissing/:stage"
            component={photoMissingCount}
          />
          <Route
            exact
            path="/reports/photoMissing/:stage/:blockId"
            component={photoMissing}
          />
          <Route
            path="/reports/photoMissing/:checklist_id/:block_id/:checkpoint"
            component={photoMissingUnits}
          />
          <Route path="/reports/dashboard-p/:id" component={PowerBi} />
          <Route path="/reports/dashboard-h/:id" component={dashboard} />
          <Redirect to="/reports/blockInfo" component={TowerInfo} />
        </Switch>
      </div>
    );
  }
}

class App extends React.Component {
  render() {
    // console.log("stage2");
    return (
      <Router>
        <Route path="/reports" component={Reports} />
      </Router>
    );
  }
}

export default App;
