const menuStyle = {
  flex: {
    flex: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  list: {
    background: 'linear-gradient(125deg, #273271, #1f63a7)',
    boxShadow: '0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)',
    transition: 'all 150ms ease 0s',
  },
  listItem: {},
  listItemText: {
    color: 'white',
  },
};

export default menuStyle;
