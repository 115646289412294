import { SET_UNIT_INFO, UPDATE_UNIT_INFO } from "../constants";

const initialState = {
  unitInfo: {}
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_UNIT_INFO:
      return {
        unitInfo: action.unitInfo
      };
    case UPDATE_UNIT_INFO:
      return {
        ...state,
        unitInfo: action.unitInfo
      };
    default:
      return state;
  }
};
