import React, { Component } from 'react'; import PropTypes from 'prop-types';
// import Tooltip from '@falconbrick/tooltip';
import cx from 'classnames';
import styles from './Icon.css';
import iconConstants from './iconConstants';

class Icon extends Component {
  constructor() {
    super();
    this.showTooltip = this.showTooltip.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
    this.state = {
      iconPath: null,
      showTooltip: false,
      tooltipMargin: 0,
      tooltipMarginTop: 0,
    };
  }


  componentDidMount() {
    this.validateIconName(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.validateIconName(nextProps);
  }

  validateIconName(props) {
    const icon = iconConstants[props.name];
    if (icon) {
      this.setState({ iconPath: icon });
    } else {
      this.setState({ iconPath: null });
    }
  }

  showTooltip() {
    const { tooltipContent, tooltipMarginTop } = this.props;
    if (tooltipContent) {
      let top = 0;
      if (tooltipMarginTop) {
        top = tooltipMarginTop;
      } else {
        top = Math.abs(this.icon.offsetParent.offsetTop - this.icon.offsetTop) + 10;
      }
      this.setState({
        showTooltip: true,
        tooltipMargin: 0,
        tooltipMarginTop: `${top}px`,
      });
    }
  }

  hideTooltip() {
    const { tooltipContent } = this.props;
    if (tooltipContent) {
      this.setState({
        showTooltip: false,
        tooltipMargin: 0,
        tooltipMarginTop: 0,
      });
    }
  }

  render() {
    const { customClassName, tooltipContent, tooltipCustomClass, scale } = this.props;
    const { iconPath, showTooltip, tooltipMargin, tooltipMarginTop } = this.state;
    const iconStyles = cx(customClassName, styles.icon);
    const icon = iconPath && require(`./icons/${iconPath}`) || false;
    return (
      <div
        className={iconStyles}
        ref={(ref) => {
          this.icon = ref;
        }}
        onMouseEnter={this.showTooltip}
        onMouseLeave={this.hideTooltip}
      >
        {iconPath && <img src={icon} style={{ height: scale, width: scale }} />}
        {!iconPath && <span>?</span>}
        {/* { showTooltip &&
             <Tooltip
            content={tooltipContent}
            tooltipMargin={tooltipMargin}
            tooltipMarginTop={tooltipMarginTop}
            customClass={cx(tooltipCustomClass, styles.tooltipClass)}
          />
        } */}
      </div>
    );
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  customClassName: PropTypes.string,
  tooltipContent: PropTypes.string,
  tooltipCustomClass: PropTypes.string,
  tooltipMarginTop: PropTypes.number
};

export default Icon;
