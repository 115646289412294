import axios from 'axios';
import jwtDecode from 'jwt-decode';
import urlencode from 'urlencode';
import orderBy from 'lodash/orderBy';
import _ from 'lodash';
import {
  SET_CURRENT_USER,
  SET_PROJECT_INFO,
  UPDATE_PROJECT_INFO,
  SET_PROPERTY_INFO,
  UPDATE_PROPERTY_INFO,
  SET_UNIT_INFO,
  SET_TOWER_INFO,
  UPDATE_TOWER_INFO,
  UPDATE_UNIT_INFO,
  SET_UNIT_DETAIL_INFO,
  SET_TOWER_DETAIL_INFO,
  UPDATE_UNIT_DETAIL_INFO,
  UPDATE_UNIT_DETAIL_DATA,
  SET_UNIT_DETAIL_DATA,
  SET_SNAG_INFO,
  SET_TOWER_SNAG_INFO,
  UPDATE_TOWER_SNAG_INFO,
  UNIT_INFO_ALL_STEPS,
  SNAG_INFO_PROJECT_DETAILS,
  SET_DELTA_UNIT_INFO,
  UPDATE_DELTA_UNIT_INFO,
  SET_DELTA_ACTIVITY_INFO,
  SET_UNIT_DATA_STAGE,
  SET_UNIT_DETAIL_DATA_STAGE,
} from '../constants';
import config from '../configs';
import setAuthorizationToken from '../utils/setAuthorizationToken';

if (localStorage.jwtToken) {
  setAuthorizationToken(localStorage.jwtToken);
}

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user,
  };
}

export function setProject(projectInfo) {
  return {
    type: SET_PROJECT_INFO,
    projectInfo,
  };
}

export function selectedProject(projectSelected) {
  return {
    type: UPDATE_PROJECT_INFO,
    projectSelected,
  };
}

export function setProperty(propertyInfo) {
  return { type: SET_PROPERTY_INFO, propertyInfo };
}

export function selectedProperty(propertySelected) {
  return { type: UPDATE_PROPERTY_INFO, propertySelected };
}

export function setTowerInfo(towerInfo) {
  return {
    type: SET_TOWER_INFO,
    towerInfo,
  };
}

export function updateTowerInfo(towerInfo) {
  return {
    type: UPDATE_TOWER_INFO,
    towerInfo,
  };
}

export function setUnitInfo(unitInfo) {
  return {
    type: SET_UNIT_INFO,
    unitInfo,
  };
}

export function updateUnit(unitInfo) {
  return {
    type: UPDATE_UNIT_INFO,
    unitInfo,
  };
}

export function setUnitData(unitDataDetail) {
  return {
    type: SET_UNIT_DETAIL_DATA,
    unitDataDetail,
  };
}

export function setUnitInfoAllSteps(unitInfoAllSteps) {
  return {
    type: UNIT_INFO_ALL_STEPS,
    unitInfoAllSteps,
  };
}

export function updateUnitData(unitDataDetail) {
  return {
    type: UPDATE_UNIT_DETAIL_DATA,
    unitDataDetail,
  };
}

export function setTowerInfoDetail(towerInfoDetail) {
  return {
    type: SET_TOWER_DETAIL_INFO,
    towerInfoDetail,
  };
}

export function setUnitInfoDetail(unitInfoDetail) {
  return {
    type: SET_UNIT_DETAIL_INFO,
    unitInfoDetail,
  };
}

// stage
export function setUnitInfoDetailStage(unitDataDetailStage) {
  return {
    type: SET_UNIT_DETAIL_DATA_STAGE,
    unitDataDetailStage,
  };
}

export function setUnitDataStage(unitInfoStage) {
  return {
    type: SET_UNIT_DATA_STAGE,
    unitInfoStage,
  };
}

export function updateUnitDetail(unitInfoDetail) {
  return {
    type: UPDATE_UNIT_DETAIL_INFO,
    unitInfoDetail,
  };
}

export function setTowerSnagInfo(towerSnagInfo) {
  return {
    type: SET_TOWER_SNAG_INFO,
    towerSnagInfo,
  };
}

export function updateTowerSnag(towerSnagInfo) {
  return {
    type: UPDATE_TOWER_SNAG_INFO,
    towerSnagInfo,
  };
}

export function setDeltaUnitInfo(deltaUnitInfo) {
  return {
    type: SET_DELTA_UNIT_INFO,
    deltaUnitInfo,
  };
}

export function updateDeltaUnitInfo(deltaUnitInfo) {
  return {
    type: UPDATE_DELTA_UNIT_INFO,
    deltaUnitInfo,
  };
}

export function setDeltaActivityInfo(deltaActivityInfo) {
  return {
    type: SET_DELTA_ACTIVITY_INFO,
    deltaActivityInfo,
  };
}

export function setSnagInfo(snagInfo) {
  return {
    type: SET_SNAG_INFO,
    snagInfo,
  };
}

export function setSnagInfoProjectDetails(snagInfoProjectDetails) {
  return {
    type: SNAG_INFO_PROJECT_DETAILS,
    snagInfoProjectDetails,
  };
}

export function logout() {
  return dispatch => {
    localStorage.clear();
    setAuthorizationToken(false);
    dispatch(setCurrentUser({}));
  };
}

export function itemsHasErrored(bool) {
  return {
    type: 'ITEMS_HAS_ERRORED',
    hasErrored: bool,
  };
}

export function itemsIsLoading(bool) {
  return {
    type: 'ITEMS_IS_LOADING',
    isLoading: bool,
  };
}

export function dashboardInfoSet(dashboardInfo) {
  return {
    type: 'DASHBOARD_INFO_SET',
    dashboardInfo,
  };
}

export function login(data) {
  return dispatch =>
    axios.post(`${config.ApiUrl}/api/auth`, data).then(res => {
      const token = res.data.token;
      localStorage.setItem('jwtToken', token);
      setAuthorizationToken(token);
      dispatch(setCurrentUser(jwtDecode(token)));
    });
}

export function forgotPassword(email) {
  return dispatch => {
    const data = {
      email,
    };
    return axios
      .post(`${config.ApiUrl}/api/auth/forgotPassword`, data)
      .then(res => {
        localStorage.clear();
        setAuthorizationToken(false);
        dispatch(setCurrentUser({}));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      });
  };
}

export function changePassword(data, email) {
  return dispatch => {
    const postData = {
      email,
      perviousPassword: data.perviousPassword,
      newPassword: data.newPassword,
    };
    return axios
      .post(`${config.ApiUrl}/api/auth/changePassword`, postData)
      .then(response => {
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      });
  };
}

export function projectGet(data) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios.get(`${config.ApiUrl}/api/projectList`).then(res => {
      // console.log(res.data.data);
      dispatch(setProject(res.data.data));
      dispatch(itemsHasErrored(false));
      dispatch(itemsIsLoading(false));
    });
  };
}

export function projectSet(projectSelected) {
  return dispatch =>
    new Promise((resolve, reject) => {
      localStorage.setItem('projectSelected', projectSelected);
      dispatch(selectedProject(projectSelected));
      dispatch(itemsHasErrored(false));
      dispatch(itemsIsLoading(false));
      resolve();
    });
}

export function propertyGet(data) {
  // console.log(data);
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios.get(`${config.ApiUrl}/api/projectList/${data}`).then(res => {
      // console.log(res.data.data);
      localStorage.setItem('propertyList', JSON.stringify(res.data.data));
      dispatch(setProperty(res.data.data));
      dispatch(itemsHasErrored(false));
      dispatch(itemsIsLoading(false));
    });
  };
}

export function propertySet(propertySelected) {
  return dispatch =>
    new Promise((resolve, reject) => {
      localStorage.setItem('propertySelected', propertySelected);
      dispatch(selectedProperty(propertySelected));
      dispatch(itemsHasErrored(false));
      dispatch(itemsIsLoading(false));
      resolve();
    });
}

// block Info
export function unitInfoGet(requestData) {
  if (localStorage.jwtToken) {
    setAuthorizationToken(localStorage.jwtToken);
  }
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .post(`${config.ApiUrl}/api/unitInfo`, requestData)
      .then(res => {
        const towerInfo = res.data.data;
        let blockSet;
        const pageData = { offset: 0, limit: 999, snag_status_summary: 0 };
        if (requestData.blockSelected === '') {
          blockSet = towerInfo[0].id;
          localStorage.setItem('blockSelectedName', towerInfo[0].name);
          localStorage.setItem('blockSelectedId', towerInfo[0].id);
        } else {
          blockSet = localStorage.getItem('blockSelectedId');
        }
        axios
          .post(`${config.ApiUrl}/api/unitInfo/${blockSet}`, pageData)
          .then(res => {
            dispatch(setUnitInfo(res.data.data));
            dispatch(setTowerInfo(towerInfo));
            dispatch(itemsHasErrored(false));
            dispatch(itemsIsLoading(false));
          });
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function updateUnitInfo(id) {
  if (localStorage.getItem('blockSelectedId')) {
    id = localStorage.getItem('blockSelectedId');
  }
  return dispatch => {
    dispatch(itemsIsLoading(true));
    const pageData = { offset: 0, limit: 999, snag_status_summary: 0 };
    return axios
      .post(`${config.ApiUrl}/api/unitInfo/${id}`, pageData)
      .then(res => {
        dispatch(updateUnit(res.data.data));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

// activity manager

export const UNIT_ACTIVITY_INFO_FETCH = ' UNIT_ACTIVITY_INFO_FETCH';
export const UNIT_ACTIVITY_INFO_SUCCESS = 'UNIT_ACTIVITY_INFO_SUCCESS';
export const UNIT_ACTIVITY_INFO_FAILURE = 'UNIT_ACTIVITY_INFO_FAILURE';

export const unitActivityInfoFetch = () => ({ type: UNIT_ACTIVITY_INFO_FETCH });

export const UnitActivityInfoSuccess = unitActivityInfo => ({
  type: UNIT_ACTIVITY_INFO_SUCCESS,
  payload: { unitActivityInfo },
});

export const UnitActivityInfoFailure = error => ({
  type: UNIT_ACTIVITY_INFO_FAILURE,
  payload: { error },
});

export function actMgrBlockInfoGet(requestData) {
  return dispatch =>
    // dispatch(itemsIsLoading(true));
    axios
      .post(`${config.ApiUrl}/api/unitInfo`, requestData)
      .then(res => {
        localStorage.setItem('actMgrBlockSelectedId', res.data.data[0].id);
        localStorage.setItem('actMgrBlockSelectedName', res.data.data[0].name);
        requestData.blockSelected = localStorage.getItem(
          'actMgrBlockSelectedId'
        );
        dispatch(setTowerInfo(res.data.data));
        dispatch(unitActivityInfoGet(requestData));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
}

export function unitActivityInfoGet(requestData) {
  return dispatch => {
    dispatch(unitActivityInfoFetch());
    const data = {};
    data.unit_type = 'apt';
    data.block_id = requestData.blockSelected;
    return axios
      .post(`${config.ApiUrl}/api/activity/getAllActivities`, data)
      .then(res => {
        dispatch(UnitActivityInfoSuccess(res.data));
      })
      .catch(error => dispatch(UnitActivityInfoFailure(error)));
  };
}

export function unitActivityActivate(array) {
  return dispatch => {
    const data = { unit_id: array };
    return axios
      .post(`${config.ApiUrl}/api/activity/activateUnit`, data)
      .then(res => {
        // console.log("success");
      })
      .catch(error => dispatch(itemsHasErrored(true)));
  };
}
// end of activity manager

export function unitInfoGetAllSteps(requestData) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .get(`${config.ApiUrl}/api/unitInfo/${requestData.pathParam1}/allSteps`)
      .then(res => {
        // console.log(res.data.data);
        dispatch(setUnitInfoAllSteps(res.data.data));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function updateUnitDataDetail(title, id) {
  // console.log(title);
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .get(`${config.ApiUrl}/api/unitDetails/${title}/${id}`)
      .then(res => {
        dispatch(updateUnitData(res.data.data));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function stageUpdateUnitDataDetail(floor_name) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .get(`${config.ApiUrl}/api/stageReport/stage0/null/${floor_name}`)
      .then(res => {
        // dispatch(setUnitInfoDetailStage(res.data.data));
        dispatch(setUnitDataStage(res.data.data));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function stage1UpdateUnitDataDetail(floor_name) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .get(`${config.ApiUrl}/api/stageReport/stage1/null/${floor_name}`)
      .then(res => {
        dispatch(setUnitDataStage(res.data.data));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function stage2UpdateUnitDataDetail(floor_name) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .get(`${config.ApiUrl}/api/stageReport/stage2/null/${floor_name}`)
      .then(res => {
        dispatch(updateUnitData(res.data.data));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function towerInfoGetDetail(requestData) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .post(`${config.ApiUrl}/api/unitInfo`, requestData)
      .then(res => {
        const towerInfo = res.data.data;
        // console.log(towerInfo[0].id);
        let blockSet;
        if (requestData.blockSelected === '') {
          blockSet = towerInfo[0].id;
          localStorage.setItem('unitInfo_blockSelectedName', towerInfo[0].name);
          localStorage.setItem('unitInfo_blockSelectedId', towerInfo[0].id);
          // localStorage.removeItem('unitInfo_unitSelectedId');
          // localStorage.removeItem('unitInfo_unitSelectedName');
        } else {
          blockSet = requestData.blockSelected;
          // localStorage.setItem('unitInfo_blockSelectedName', blockSet.blockSelectedName);
          // localStorage.setItem('unitInfo_blockSelectedId',blockSet.blockSelectedId);
          // blockSet = localStorage.getItem('unitInfo_blockSelectedId');
        }
        return axios
          .get(`${config.ApiUrl}/api/towerDetails/${blockSet}`)
          .then(res => {
            const unitInfo = res.data.data;
            if (requestData.unitSelected !== '') {
              return axios
                .get(
                  `${config.ApiUrl}/api/unitDetails/${
                    requestData.unitSelected
                  }/${blockSet}`
                )
                .then(res => {
                  dispatch(setUnitData(res.data.data));
                  dispatch(setUnitInfoDetail(unitInfo));
                  dispatch(setTowerInfoDetail(towerInfo));
                  dispatch(itemsHasErrored(false));
                  dispatch(itemsIsLoading(false));
                })
                .catch(() => dispatch(itemsHasErrored(true)));
            }
            if (unitInfo.length === 0) {
              dispatch(setUnitData({}));
              dispatch(setUnitInfoDetail(unitInfo));
              dispatch(setTowerInfoDetail(towerInfo));
              dispatch(itemsHasErrored(false));
              dispatch(itemsIsLoading(false));
            } else {
              return axios
                .get(
                  `${config.ApiUrl}/api/unitDetails/${
                    unitInfo[0].title
                  }/${blockSet}`
                )
                .then(res => {
                  dispatch(setUnitData(res.data.data));
                  dispatch(setUnitInfoDetail(unitInfo));
                  dispatch(setTowerInfoDetail(towerInfo));
                  dispatch(itemsHasErrored(false));
                  dispatch(itemsIsLoading(false));
                })
                .catch(() => dispatch(itemsHasErrored(true)));
            }
          })
          .catch(() => dispatch(itemsHasErrored(true)));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function updateTowerInfoDetail(id) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .get(`${config.ApiUrl}/api/towerDetails/${id}`)
      .then(res => {
        const unitInfoDetail = res.data.data;
        if (unitInfoDetail.length === 0) {
          dispatch(updateUnitDetail({}));
          dispatch(updateUnitData({}));
          dispatch(itemsHasErrored(false));
          dispatch(itemsIsLoading(false));
        } else {
          const unitSelected = unitInfoDetail[0].title;
          return axios
            .get(`${config.ApiUrl}/api/unitDetails/${unitSelected}/${id}`)
            .then(res => {
              localStorage.setItem(
                'unitInfo_unitSelectedId',
                unitInfoDetail[0].title
              );
              dispatch(updateUnitDetail(unitInfoDetail));
              dispatch(updateUnitData(res.data.data));
              dispatch(itemsHasErrored(false));
              dispatch(itemsIsLoading(false));
            })
            .catch(() => dispatch(itemsHasErrored(true)));
        }
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function stageTowerInfoGetDetail(requestData) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .post(`${config.ApiUrl}/api/stageReport`, requestData)
      .then(res => {
        const towerInfo = res.data.data;
        // console.log(requestData);
        let blockSet;
        if (requestData.blockSelected === '') {
          blockSet = towerInfo[0].id;
          localStorage.setItem(
            'stageInfo_blockSelectedName',
            towerInfo[0].name
          );
          localStorage.setItem('stageInfo_blockSelectedId', towerInfo[0].id);
          localStorage.removeItem('stageInfo_unitSelectedId');
          localStorage.removeItem('stageInfo_unitSelectedName');
        } else {
          blockSet = requestData.blockSelected;
          localStorage.setItem('stageInfo_blockSelectedId', blockSet);
        }
        return axios
          .get(`${config.ApiUrl}/api/stageReport/stage0/${blockSet}`)
          .then(res => {
            const unitInfo = res.data.data;
            // console.log(unitInfo);
            if (requestData.unitSelected !== '') {
              return axios
                .get(
                  `${config.ApiUrl}/api/stageReport/stage0/${blockSet}/${
                    requestData.unitSelected
                  }`
                )
                .then(res => {
                  localStorage.setItem(
                    'stageInfo_unitSelectedId',
                    requestData.unitSelected
                  );
                  dispatch(setUnitDataStage(res.data.data));
                  dispatch(setUnitInfoDetailStage(unitInfo));
                  dispatch(setTowerInfoDetail(towerInfo));
                  dispatch(itemsHasErrored(false));
                  dispatch(itemsIsLoading(false));
                })
                .catch(() => dispatch(itemsHasErrored(true)));
            }
            if (unitInfo.length === 0) {
              dispatch(setUnitDataStage({}));
              dispatch(setUnitInfoDetailStage(unitInfo));
              dispatch(setTowerInfoDetail(towerInfo));
              dispatch(itemsHasErrored(false));
              dispatch(itemsIsLoading(false));
            } else {
              return axios
                .get(
                  `${config.ApiUrl}/api/stageReport/stage0/${blockSet}/${
                    unitInfo[0].floor_name
                  }`
                )
                .then(res => {
                  localStorage.setItem(
                    'stageInfo_unitSelectedId',
                    unitInfo[0].floor_name
                  );
                  dispatch(setUnitDataStage(res.data.data));
                  dispatch(setUnitInfoDetailStage(unitInfo));
                  dispatch(setTowerInfoDetail(towerInfo));
                  dispatch(itemsHasErrored(false));
                  dispatch(itemsIsLoading(false));
                })
                .catch(() => dispatch(itemsHasErrored(true)));
            }
          })
          .catch(() => dispatch(itemsHasErrored(true)));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function stageUpdateTowerInfoDetail(options) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .get(`${config.ApiUrl}/api/stageReport/stage0/${options.id}`)
      .then(res => {
        const unitInfoDetail = res.data.data;
        if (_.isEmpty(unitInfoDetail)) {
          dispatch(setUnitInfoDetailStage({}));
          dispatch(setUnitDataStage({}));
          dispatch(itemsHasErrored(false));
          dispatch(itemsIsLoading(false));
        } else {
          const unitSelected = unitInfoDetail[0].floor_name;
          return axios
            .get(
              `${config.ApiUrl}/api/stageReport/stage0/${
                options.id
              }/${unitSelected}`
            )
            .then(res => {
              localStorage.setItem('stageInfo_unitSelectedId', unitSelected);
              dispatch(setUnitInfoDetailStage(unitInfoDetail));
              dispatch(setUnitDataStage(res.data.data));
              dispatch(itemsHasErrored(false));
              dispatch(itemsIsLoading(false));
            })
            .catch(() => dispatch(itemsHasErrored(true)));
        }
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function stage1TowerInfoGetDetail(requestData) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .post(`${config.ApiUrl}/api/stageReport`, requestData)
      .then(res => {
        const towerInfo = res.data.data;
        // console.log(requestData);
        let blockSet;
        if (requestData.blockSelected === '') {
          blockSet = towerInfo[0].id;
          localStorage.setItem('stage1_blockSelectedName', towerInfo[0].name);
          localStorage.setItem('stage1_blockSelectedId', towerInfo[0].id);
          localStorage.removeItem('stage1_unitSelectedId');
          localStorage.removeItem('stage1_unitSelectedName');
        } else {
          blockSet = requestData.blockSelected;
          localStorage.setItem('stage1_blockSelectedId', blockSet);
        }
        return axios
          .get(`${config.ApiUrl}/api/stageReport/stage1/${blockSet}`)
          .then(res => {
            const unitInfo = res.data.data;
            // console.log(unitInfo);
            if (requestData.unitSelected !== '') {
              return axios
                .get(
                  `${config.ApiUrl}/api/stageReport/stage1/${blockSet}/${
                    requestData.unitSelected
                  }`
                )
                .then(res => {
                  localStorage.setItem(
                    'stage1_unitSelectedId',
                    requestData.unitSelected
                  );
                  dispatch(setUnitDataStage(res.data.data));
                  dispatch(setUnitInfoDetailStage(unitInfo));
                  dispatch(setTowerInfoDetail(towerInfo));
                  dispatch(itemsHasErrored(false));
                  dispatch(itemsIsLoading(false));
                })
                .catch(() => dispatch(itemsHasErrored(true)));
            }
            if (unitInfo.length === 0) {
              dispatch(setUnitDataStage({}));
              dispatch(setUnitInfoDetailStage(unitInfo));
              dispatch(setTowerInfoDetail(towerInfo));
              dispatch(itemsHasErrored(false));
              dispatch(itemsIsLoading(false));
            } else {
              return axios
                .get(
                  `${config.ApiUrl}/api/stageReport/stage1/${blockSet}/${
                    unitInfo[0].id
                  }`
                )
                .then(res => {
                  localStorage.setItem('stage1_unitSelectedId', unitInfo[0].id);
                  localStorage.setItem(
                    'stage1_unitSelectedName',
                    unitInfo[0].title
                  );
                  dispatch(setUnitDataStage(res.data.data));
                  dispatch(setUnitInfoDetailStage(unitInfo));
                  dispatch(setTowerInfoDetail(towerInfo));
                  dispatch(itemsHasErrored(false));
                  dispatch(itemsIsLoading(false));
                })
                .catch(() => dispatch(itemsHasErrored(true)));
            }
          })
          .catch(() => dispatch(itemsHasErrored(true)));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function stage1UpdateTowerInfoDetail(id) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .get(`${config.ApiUrl}/api/stageReport/stage1/${id}`)
      .then(res => {
        const unitInfoDetail = res.data.data;
        if (unitInfoDetail.length === 0) {
          dispatch(setUnitInfoDetailStage({}));
          dispatch(setUnitDataStage({}));
          dispatch(itemsHasErrored(false));
          dispatch(itemsIsLoading(false));
        } else {
          const unitSelected = unitInfoDetail[0].id;
          return axios
            .get(
              `${config.ApiUrl}/api/stageReport/stage1/${id}/${unitSelected}`
            )
            .then(res => {
              localStorage.setItem('stage1_unitSelectedId', unitSelected);
              localStorage.setItem(
                'stage1_unitSelectedName',
                unitInfoDetail[0].title
              );
              dispatch(setUnitInfoDetailStage(unitInfoDetail));
              dispatch(setUnitDataStage(res.data.data));
              dispatch(itemsHasErrored(false));
              dispatch(itemsIsLoading(false));
            })
            .catch(() => dispatch(itemsHasErrored(true)));
        }
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function stageClear() {
  return dispatch => {
    dispatch(setUnitInfoDetailStage({}));
    dispatch(setUnitDataStage({}));
  };
}

export function stage2TowerInfoGetDetail(requestData) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .post(`${config.ApiUrl}/api/stageReport`, requestData)
      .then(res => {
        const towerInfo = res.data.data;
        // console.log(requestData);
        let blockSet;
        if (requestData.blockSelected === '') {
          blockSet = towerInfo[0].id;
          localStorage.setItem('stage2_blockSelectedName', towerInfo[0].name);
          localStorage.setItem('stage2_blockSelectedId', towerInfo[0].id);
          localStorage.removeItem('stage2_unitSelectedId');
          localStorage.removeItem('stage2_unitSelectedName');
        } else {
          blockSet = requestData.blockSelected;
          localStorage.setItem('stage2_blockSelectedId', blockSet);
        }
        return axios
          .get(`${config.ApiUrl}/api/stageReport/stage2/${blockSet}`)
          .then(res => {
            const unitInfo = res.data.data;
            // console.log(unitInfo);
            if (requestData.unitSelected === '') {
              return axios
                .get(
                  `${config.ApiUrl}/api/stageReport/stage2/${blockSet}/${
                    requestData.unitSelected
                  }`
                )
                .then(res => {
                  localStorage.setItem(
                    'stage2_unitSelectedId',
                    requestData.unitSelected
                  );
                  dispatch(setUnitDataStage(res.data.data));
                  dispatch(setUnitInfoDetail(unitInfo));
                  dispatch(setTowerInfoDetail(towerInfo));
                  dispatch(itemsHasErrored(false));
                  dispatch(itemsIsLoading(false));
                })
                .catch(() => dispatch(itemsHasErrored(true)));
            }
            if (unitInfo.length === 0) {
              dispatch(setUnitDataStage({}));
              dispatch(setUnitInfoDetail(unitInfo));
              dispatch(setTowerInfoDetail(towerInfo));
              dispatch(itemsHasErrored(false));
              dispatch(itemsIsLoading(false));
            } else {
              return axios
                .get(
                  `${config.ApiUrl}/api/stageReport/stage2/${blockSet}/${
                    unitInfo[0].id
                  }`
                )
                .then(res => {
                  localStorage.setItem('stage2_unitSelectedId', unitInfo[0].id);
                  localStorage.setItem(
                    'stage2_unitSelectedName',
                    unitInfo[0].title
                  );
                  dispatch(setUnitDataStage(res.data.data));
                  dispatch(setUnitInfoDetail(unitInfo));
                  dispatch(setTowerInfoDetail(towerInfo));
                  dispatch(itemsHasErrored(false));
                  dispatch(itemsIsLoading(false));
                })
                .catch(() => dispatch(itemsHasErrored(true)));
            }
          })
          .catch(() => dispatch(itemsHasErrored(true)));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function stage2UpdateTowerInfoDetail(id) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .get(`${config.ApiUrl}/api/stageReport/stage2/${id}`)
      .then(res => {
        const unitInfoDetail = res.data.data;
        if (unitInfoDetail.length === 0) {
          dispatch(updateUnitDetail({}));
          dispatch(updateUnitData({}));
          dispatch(itemsHasErrored(false));
          dispatch(itemsIsLoading(false));
        } else {
          const unitSelected = unitInfoDetail[0].id;
          return axios
            .get(
              `${config.ApiUrl}/api/stageReport/stage2/${id}/${unitSelected}`
            )
            .then(res => {
              localStorage.setItem('stage2_unitSelectedId', unitSelected);
              localStorage.setItem(
                'stage2_unitSelectedName',
                unitInfoDetail[0].title
              );
              dispatch(updateUnitDetail(unitInfoDetail));
              dispatch(updateUnitData(res.data.data));
              dispatch(itemsHasErrored(false));
              dispatch(itemsIsLoading(false));
            })
            .catch(() => dispatch(itemsHasErrored(true)));
        }
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function towerSnagInfoGet(requestData) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .post(`${config.ApiUrl}/api/towerSnagInfo`, requestData)
      .then(res => {
        const towerInfo = res.data.data;
        const blockSelected = requestData.blockSelected;
        if (blockSelected === '') {
          axios
            .get(`${config.ApiUrl}/api/towerSnagInfo/${towerInfo[0].id}`)
            .then(res => {
              localStorage.setItem(
                'towerSnagInfo_blockName',
                towerInfo[0].name
              );
              localStorage.setItem('towerSnagInfo_blockId', towerInfo[0].id);
              dispatch(setTowerSnagInfo(res.data.data));
              dispatch(setTowerInfo(towerInfo));
              dispatch(itemsHasErrored(false));
              dispatch(itemsIsLoading(false));
            });
        } else {
          axios
            .get(`${config.ApiUrl}/api/towerSnagInfo/${blockSelected}`)
            .then(res => {
              dispatch(setTowerSnagInfo(res.data.data));
              dispatch(setTowerInfo(towerInfo));
              dispatch(itemsHasErrored(false));
              dispatch(itemsIsLoading(false));
            });
        }
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function deltaUnitInfoGet(requestData) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .post(`${config.ApiUrl}/api/deltaInfo/unitInfo`, requestData)
      .then(res => {
        dispatch(setDeltaUnitInfo(res.data.data));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function deltaUnitInfoUpdate(requestData) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .post(`${config.ApiUrl}/api/deltaInfo/unitInfo`, requestData)
      .then(res => {
        dispatch(updateDeltaUnitInfo(res.data.data));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function deltaActivityInfoGet(requestData) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .post(`${config.ApiUrl}/api/deltaInfo/activityInfo`, requestData)
      .then(res => {
        dispatch(setDeltaActivityInfo(res.data.data));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function updateTowerSnagInfo(id, type) {
  console.log(id, type);
  return dispatch => {
    dispatch(itemsIsLoading(true));
    if (type === 'task/snag') {
      return axios
        .get(`${config.ApiUrl}/api/towerSnagInfo/${id}`)
        .then(res => {
          dispatch(updateTowerSnag(res.data.data));
          dispatch(itemsHasErrored(false));
          dispatch(itemsIsLoading(false));
        })
        .catch(() => dispatch(itemsHasErrored(true)));
    }
    if (type === 'task') {
      return axios
        .get(`${config.ApiUrl}/api/towerSnagInfo/${id}`)
        .then(res => {
          const tasks = _.filter(res.data.data, o => o.raised_by === null);
          dispatch(updateTowerSnag(tasks));
          dispatch(itemsHasErrored(false));
          dispatch(itemsIsLoading(false));
        })
        .catch(() => dispatch(itemsHasErrored(true)));
    }
    if (type === 'snag') {
      return axios
        .get(`${config.ApiUrl}/api/towerSnagInfo/${id}`)
        .then(res => {
          const snags = _.filter(res.data.data, o => o.raised_by !== null);
          dispatch(updateTowerSnag(snags));
          dispatch(itemsHasErrored(false));
          dispatch(itemsIsLoading(false));
        })
        .catch(() => dispatch(itemsHasErrored(true)));
    }
  };
}

export function snagInfoGet(data, search) {
  // console.log(data);
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .get(
        `${config.ApiUrl}/api/snagInfo/${data.pathParam1}/${
          data.pathParam2
        }${search}`
      )
      .then(res => {
        const snagInfoData = res.data.data;
        axios
          .get(
            `${config.ApiUrl}/api/snagInfo/projectDetails/${data.pathParam1}/${
              data.pathParam2
            }`
          )
          .then(res => {
            // console.log(res.data);
            dispatch(setSnagInfoProjectDetails(res.data.data[0]));
            dispatch(setSnagInfo(snagInfoData));
            dispatch(itemsHasErrored(false));
            dispatch(itemsIsLoading(false));
          });
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function downloadExcel(requestData) {
  // console.log(requestData);
  return dispatch =>
    axios
      .post(`${config.ApiUrl}/api/reports/exports/xlsx`, requestData)
      .then(
        res =>
          // console.log(res);
          res.data
      )
      .catch(() => dispatch(itemsHasErrored(true)));
}

export function downloadPdf(requestData) {
  // console.log(requestData);
  return dispatch =>
    axios
      .post(`${config.ApiUrl}/api/reports/exports/pdf`, requestData)
      .then(
        res =>
          // console.log(res);
          res.data
      )
      .catch(() => dispatch(itemsHasErrored(true)));
}

// nav bar
export const NAV_INFO_FETCH = ' NAV_INFO_FETCH';
export const NAV_INFO_SUCCESS = 'NAV_INFO_SUCCESS';
export const NAV_INFO_FAILURE = 'NAV_INFO_FAILURE';

export const navInfoFetch = () => ({ type: NAV_INFO_FETCH });

export const navInfoSuccess = navInfo => ({
  type: NAV_INFO_SUCCESS,
  payload: { navInfo },
});

export const navInfoFailure = error => ({
  type: NAV_INFO_FAILURE,
  payload: { error },
});

export function navInfoGet(data) {
  return dispatch => {
    dispatch(navInfoFetch());
    return axios
      .get(`${config.ApiUrl}/api/groups/dashboards`)
      .then(res => {
        // console.log(res.data);
        const sort_data = orderBy(res.data.groups, ['id', 'asc']);
        localStorage.setItem('navInfo', JSON.stringify(sort_data));
        dispatch(navInfoSuccess(sort_data));
      })
      .catch(error => dispatch(navInfoFailure(error)));
  };
}

export function dashboardInfoGet(requestData) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .post(`${config.ApiUrl}/api/groups/getDashboardToken`, requestData)
      .then(res => {
        // console.log(res.data);
        dispatch(dashboardInfoSet(res.data));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

// Activity Completed
export function byTowerGet() {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .get(`${config.ApiUrl}/api/activityCompleted`)
      .then(res => {
        const towerInfo = res.data.data;
        dispatch(setTowerInfo(towerInfo));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}
export function byUnitGet(stage, blockId) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .get(`${config.ApiUrl}/api/activityCompleted/${stage}/${blockId}`)
      .then(res => {
        const unitInfo = res.data.data;
        dispatch(setUnitInfo(unitInfo));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

// Checkpoint Rejected
export function clr_byTowerGet(requestedData) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .post(`${config.ApiUrl}/api/rejectedCheckpoints`, requestedData)
      .then(res => {
        const towerInfo = res.data.data;
        dispatch(setTowerInfo(towerInfo));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function clr_byTowerUpdate(requestedData) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .post(`${config.ApiUrl}/api/rejectedCheckpoints`, requestedData)
      .then(res => {
        const towerInfo = res.data.data;
        dispatch(updateTowerInfo(towerInfo));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function clr_byStageGet(stage, blockId) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .get(
        `${
          config.ApiUrl
        }/api/rejectedCheckpoints/checkpoint/${stage}/${blockId}`
      )
      .then(res => {
        const unitInfo = res.data.data;
        dispatch(setUnitInfo(unitInfo));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function clr_byUnitGet(checklist_id, block_id, checkpoint) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .get(
        `${
          config.ApiUrl
        }/api/rejectedCheckpoints/checkpoint/${checklist_id}/${block_id}/${urlencode(
          checkpoint
        )}`
      )
      .then(res => {
        const unitInfoDetail = res.data.data;
        dispatch(setUnitData(unitInfoDetail));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

// Checkpoint Photo Missing
export function photoMiss_byTowerGet(requestData) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .post(`${config.ApiUrl}/api/photoMissing`, requestData)
      .then(res => {
        const towerInfo = res.data.data;
        dispatch(setTowerInfo(towerInfo));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function photoMiss_byTowerUpdate(requestData) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .post(`${config.ApiUrl}/api/photoMissing`, requestData)
      .then(res => {
        const towerInfo = res.data.data;
        dispatch(updateTowerInfo(towerInfo));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function photoMiss_byStageGet(stage, blockId) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .get(`${config.ApiUrl}/api/photoMissing/checkpoint/${stage}/${blockId}`)
      .then(res => {
        const unitInfo = res.data.data;
        dispatch(setUnitInfo(unitInfo));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}

export function photoMiss_byUnitGet(checklist_id, block_id, checkpoint) {
  return dispatch => {
    dispatch(itemsIsLoading(true));
    return axios
      .get(
        `${
          config.ApiUrl
        }/api/photoMissing/checkpoint/${checklist_id}/${block_id}/${urlencode(
          checkpoint
        )}`
      )
      .then(res => {
        const unitInfoDetail = res.data.data;
        dispatch(setUnitData(unitInfoDetail));
        dispatch(itemsHasErrored(false));
        dispatch(itemsIsLoading(false));
      })
      .catch(() => dispatch(itemsHasErrored(true)));
  };
}
