import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'whatwg-fetch';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import Select from 'react-super-select';
import _ from 'lodash';

import {
  Table,
  Grid,
  Divider,
  Image,
  Loader,
  Container,
  List,
  Form,
  Label,
} from 'semantic-ui-react';
import lAndT from '../../../semanticUi/images/Lnt_realty_logo.png';
import { stageUpdateUnitDataDetail } from '../../../actions';

class UnitInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unitInfoDetail: [],
      errors: {},
      isLoading: true,
      hasErrored: false,
      active_tower: 0,
    };
    this.updateUnitDataDetail = this.updateUnitDataDetail.bind(this);
    this.snagLinkClick = this.snagLinkClick.bind(this);
  }

  updateUnitDataDetail = function(options) {
    localStorage.setItem('stageInfo_unitSelectedId', options.floor_name);
    this.props.stageUpdateUnitDataDetail(options.floor_name).then(
      res => {
        // console.log(res);
        this.setState.isLoading = false;
        this.setState.hasErrored = false;
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
  };

  snagLinkClick(unit) {
    this.props.history.push(
      `/reports/blockInfo/${unit.activity_id}/${unit.work_flow_node}/`
    );
  }

  render() {
    const {
      unitInfoDetail,
      unitDataDetail,
      isLoading,
      hasErrored,
      errors,
    } = this.props;
    // console.log(this.props);
    let selectedBlock;
    if (localStorage.getItem('stageInfo_unitSelectedId')) {
      selectedBlock = localStorage.getItem('stageInfo_unitSelectedId');
    }
    const pour1 = [];
    const pour2 = [];
    const map = new Map();
    const result = [];
    let combine1 = [];
    let combine2 = [];

    if (isLoading) {
      return (
        <div>
          <Loader active indeterminate className="centered">
            Updating units...
          </Loader>
        </div>
      );
    }

    if (_.isEmpty(unitInfoDetail) && _.isEmpty(unitDataDetail) && !isLoading) {
      return (
        <Container fluid textAlign="center">
          <Divider className="print-hide" />
          <p>No units found!</p>
          <Divider hidden section />
          <Divider hidden section />
          <Divider hidden section />
        </Container>
      );
    }

    if (hasErrored) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again
            {errors}
          </div>
        </div>
      );
    }

    if (!_.isEmpty(unitInfoDetail) && !_.isEmpty(unitDataDetail)) {
      unitDataDetail.map((unit, index) => {
        if (_.endsWith(unit.pour_id, 'P1')) {
          unit.comment_array_pour1 = JSON.parse(unit.comment_array);
          pour1.push(unit);
        }
        if (_.endsWith(unit.pour_id, 'P2')) {
          unit.comment_array_pour2 = JSON.parse(unit.comment_array);
          pour2.push(unit);
        }
        return true;
      }, this);
      combine1 = _.sortBy(pour1, ['inspection_item_title']);
      combine2 = _.sortBy(pour2, ['inspection_item_title']);

      function mergeObject(target) {
        for (let i = 1; i < arguments.length; i++) {
          const source = arguments[i];
          for (const key in source) {
            if (source.hasOwnProperty(key)) {
              target[key] = source[key];
            }
          }
        }
        return target;
      }

      function merge(o) {
        let temp;
        if (map.has(o.inspection_item_title)) {
          // Object.assign(map.get(o.inspection_item_title), o);
          mergeObject(map.get(o.inspection_item_title), o);
          return;
        }
        temp = { ...o };
        map.set(temp.inspection_item_title, temp);
        result.push(temp);
      }

      [combine1, combine2].forEach(a => {
        a.forEach(merge);
      });
    }

    let info_LTR_Tower_In_Charge = '';
    let info_LTC_Tower_In_Charge = '';
    let info_date = '';

    if (!_.isEmpty(unitInfoDetail)) {
      unitDataDetail.map(data => {
        if (
          data.floor_id === localStorage.getItem('stageInfo_unitSelectedId')
        ) {
          info_LTR_Tower_In_Charge = data.LTR_Tower_In_Charge;
          info_LTC_Tower_In_Charge = data.LTC_Tower_In_Charge;
          info_date = data.date;
        }
        return true;
      });
    }

    return (
      <Grid columns="equals">
        <Grid.Row className="print-hide">
          <Form className="dropDownForm print-hide">
            <Form.Field>
              <Label>Unit</Label>
              <Select
                placeholder={selectedBlock || 'Please Select A Unit'}
                dataSource={unitInfoDetail}
                onChange={this.updateUnitDataDetail}
                clearSearchOnSelection
                deselectOnSelectedOptionClick={false}
                searchable
                clearable={false}
                optionLabelKey="floor_name"
              />
            </Form.Field>
          </Form>
        </Grid.Row>
        <List size="medium" className="printCard">
          <Grid>
            <Grid.Column textAlign="left" width={4}>
              <List.Item>
                <Image src={lAndT} size="medium" />
              </List.Item>
            </Grid.Column>
            <Grid.Column textAlign="left" width={12}>
              <List.Item>
                <h2 className="header">STAGE 0 - CERTIFICATE</h2>
              </List.Item>
            </Grid.Column>
          </Grid>
        </List>
        <List size="medium" className="">
          <Grid>
            <Grid.Column floated="left" width={10}>
              <List.Item>
                <h3>
                  <b>
                    Floor/Slabs - {unitDataDetail[0].floor_id}
                    _P1, {unitDataDetail[0].floor_id}
                    _P2
                  </b>
                </h3>
              </List.Item>
            </Grid.Column>
            <Grid.Column textAlign="right" width={6}>
              <List.Item>
                <h3>
                  <b>
                    Date -{' '}
                    {moment(info_date)
                      .tz('Asia/Calcutta')
                      .format('DD-MM-YYYY')}
                  </b>
                </h3>
              </List.Item>
            </Grid.Column>
          </Grid>
        </List>

        <Grid.Row>
          {unitDataDetail.length ? (
            <Container fluid>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Particulars</Table.HeaderCell>
                    <Table.HeaderCell>Pour 1</Table.HeaderCell>
                    <Table.HeaderCell>Pour 2</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {result.map(
                    (data, index) => (
                      // console.log(data);
                      <Table.Row key={index}>
                        <Table.Cell>{data.inspection_item_title}</Table.Cell>
                        <Table.Cell>
                          {!data.comment_array_pour1 ||
                          data.comment_array_pour1 === null ||
                          data.comment_array_pour1 === ''
                            ? 'NA'
                            : data.comment_array_pour1.map((comment, index) => {
                                if (
                                  comment.comment !== 'Work complete set to YES'
                                ) {
                                  return (
                                    <div className="ui" key={index}>
                                      <div className="commentDate">
                                        {moment(`${comment.time_stamp}Z`)
                                          .tz('Asia/Calcutta')
                                          .format('DD-MM-YYYY, h:mm A')}
                                      </div>
                                      <div>
                                        {comment.comment ? (
                                          <p>{comment.comment}</p>
                                        ) : (
                                          <p />
                                        )}
                                      </div>
                                      <div>
                                        {comment.attachment ? (
                                          <Image
                                            src={`https://s3.ap-south-1.amazonaws.com/com.fcb.ho.stag/brigade/${
                                              comment.attachment
                                            }`}
                                            size="medium"
                                          />
                                        ) : (
                                          <p />
                                        )}
                                      </div>
                                      <Divider hidden />
                                    </div>
                                  );
                                }
                                return true;
                              })}
                        </Table.Cell>
                        <Table.Cell>
                          {!data.comment_array_pour2 ||
                          data.comment_array_pour2 === null ||
                          data.comment_array_pour2 === ''
                            ? 'NA'
                            : data.comment_array_pour2.map((comment, index) => {
                                if (
                                  comment.comment !== 'Work complete set to YES'
                                ) {
                                  return (
                                    <div className="ui" key={index}>
                                      <div className="commentDate">
                                        {moment(`${comment.time_stamp}Z`)
                                          .tz('Asia/Calcutta')
                                          .format('DD-MM-YYYY, h:mm A')}
                                      </div>
                                      <div>
                                        {comment.comment ? (
                                          <p>{comment.comment}</p>
                                        ) : (
                                          <p />
                                        )}
                                      </div>
                                      <div>
                                        {comment.attachment ? (
                                          <Image
                                            src={`https://s3.ap-south-1.amazonaws.com/com.fcb.ho.stag/brigade/${
                                              comment.attachment
                                            }`}
                                            size="medium"
                                          />
                                        ) : (
                                          <p />
                                        )}
                                      </div>
                                      <Divider hidden />
                                    </div>
                                  );
                                }
                                return true;
                              })}
                        </Table.Cell>
                      </Table.Row>
                    ),
                    this
                  )}
                  <Table.Row>
                    <Table.Cell>
                      Pour card reference # for respective pour
                    </Table.Cell>
                    <Table.Cell
                      className="underline"
                      onClick={() => this.snagLinkClick(combine1[0])}
                    >
                      {combine1[0].floor_id}
                      _P1
                    </Table.Cell>
                    <Table.Cell
                      className="underline"
                      onClick={() => this.snagLinkClick(combine2[0])}
                    >
                      {combine2[0].floor_id}
                      _P2
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      Formwork checklists reference # for respective pour
                    </Table.Cell>
                    <Table.Cell
                      className="underline"
                      onClick={() => this.snagLinkClick(combine1[0])}
                    >
                      {combine1[0].floor_id}
                      _P1
                    </Table.Cell>
                    <Table.Cell
                      className="underline"
                      onClick={() => this.snagLinkClick(combine2[0])}
                    >
                      {combine2[0].floor_id}
                      _P2
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      Reinforcement checklist reference # for respective pour
                    </Table.Cell>
                    <Table.Cell
                      className="underline"
                      onClick={() => this.snagLinkClick(combine1[0])}
                    >
                      {combine1[0].floor_id}
                      _P1
                    </Table.Cell>
                    <Table.Cell
                      className="underline"
                      onClick={() => this.snagLinkClick(combine2[0])}
                    >
                      {combine2[0].floor_id}
                      _P2
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      MEP checklist (slab conduit & sleeve) No{' '}
                    </Table.Cell>
                    <Table.Cell
                      className="underline"
                      onClick={() => this.snagLinkClick(combine1[0])}
                    >
                      {combine1[0].floor_id}
                      _P1
                    </Table.Cell>
                    <Table.Cell
                      className="underline"
                      onClick={() => this.snagLinkClick(combine2[0])}
                    >
                      {combine2[0].floor_id}
                      _P2
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>P/T Slab checklist no</Table.Cell>
                    <Table.Cell
                      className="underline"
                      onClick={() => this.snagLinkClick(combine1[0])}
                    >
                      {combine1[0].floor_id}
                      _P1
                    </Table.Cell>
                    <Table.Cell
                      className="underline"
                      onClick={() => this.snagLinkClick(combine2[0])}
                    >
                      {combine2[0].floor_id}
                      _P2
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>No. of cube filled</Table.Cell>
                    <Table.Cell>&nbsp;</Table.Cell>
                    <Table.Cell>&nbsp;</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Table celled className="printCard">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Observations/Remarks</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <Divider hidden />
                      <Divider hidden />
                      <Divider hidden />
                      <Divider hidden />
                      <Divider hidden />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <List size="medium" className="printCard">
                <Grid>
                  <Grid.Column floated="left" width={4}>
                    <List.Item>
                      <h3 className="sub-header">LTR Tower In-charge</h3>
                    </List.Item>
                    <List.Item>
                      <h4>Sig </h4>
                    </List.Item>
                    <br />
                    <List.Item>
                      <h4>{info_LTR_Tower_In_Charge}</h4>
                    </List.Item>
                  </Grid.Column>
                  <Grid.Column floated="left" width={4}>
                    <List.Item>
                      <h3 className="sub-header">LTC Tower In-charge</h3>
                    </List.Item>
                    <List.Item>
                      <h4>Sig </h4>
                    </List.Item>
                    <br />
                    <List.Item>
                      <h4>{info_LTC_Tower_In_Charge}</h4>
                    </List.Item>
                  </Grid.Column>
                </Grid>
              </List>
            </Container>
          ) : (
            <Container fluid>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Particulars</Table.HeaderCell>
                    <Table.HeaderCell>Pour 1</Table.HeaderCell>
                    <Table.HeaderCell>Pour 2</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <div>
                  <p className="noDataFound">No Data Found!</p>
                </div>
              </Table>
            </Container>
          )}
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  unitDataDetail: state.unitInfoStage.unitInfoStage,
  unitInfoDetail: state.unitDataDetailStage.unitDataDetailStage,
  towerInfoDetail: state.towerInfoDetail.towerInfoDetail,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
  propertySelected: state.property.propertySelected,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      stageUpdateUnitDataDetail,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnitInfo);
