import { SNAG_INFO_PROJECT_DETAILS } from '../constants';

const initialState = {
    snagInfoProjectDetails: {}
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SNAG_INFO_PROJECT_DETAILS:
            return {
                snagInfoProjectDetails: action.snagInfoProjectDetails
            };
        default:
            return state;
    }
}