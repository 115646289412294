import axios from 'axios';
import _ from 'lodash';
import setAuthorizationToken from '../container/utils/setAuthorizationToken';
import config from '../configs/clientConfig';

import * as project from '../constants/Project';

// project get
export const getProjectList = () => ({
  type: project.PROJECT_INFO_FETCH,
});

export const getProjectListSuccess = projectList => ({
  type: project.PROJECT_INFO_SUCCESS,
  payload: { projectList },
});

export const getProjectListError = error => ({
  type: project.PROJECT_INFO_ERROR,
  payload: { error },
});

export function projectGet(data) {
  return dispatch => {
    dispatch(getProjectList());
    return axios
      .get(`${config.ApiUrl}/api/projectList`)
      .then(res => {
        dispatch(getProjectListSuccess(res.data.data));
        localStorage.setItem('projectList', JSON.stringify(res.data.data));
      })
      .catch(error => dispatch(getProjectListError(error.response.data)));
  };
}

// project set
export const setProjectSuccess = projectSelected => ({
  type: project.PROJECT_SET_SUCCESS,
  payload: { projectSelected },
});

export function projectSet(projectSelected) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch(setProjectSuccess(projectSelected));
      localStorage.setItem('projectSelected', JSON.stringify(projectSelected));
      resolve();
    });
}

// nav bar
export const NAV_INFO_FETCH = ' NAV_INFO_FETCH';
export const NAV_INFO_SUCCESS = 'NAV_INFO_SUCCESS';
export const NAV_INFO_FAILURE = 'NAV_INFO_FAILURE';

export const navInfoFetch = () => ({ type: NAV_INFO_FETCH });

export const navInfoSuccess = navInfo => ({
  type: NAV_INFO_SUCCESS,
  payload: { navInfo },
});

export const navInfoFailure = error => ({
  type: NAV_INFO_FAILURE,
  payload: { error },
});

export function navInfoGet(data) {
  return dispatch => {
    dispatch(navInfoFetch());
    return axios
      .get(`${config.ApiUrl}/api/groups/dashboards`)
      .then(res => {
        // console.log(res.data);
        const sortData = _.orderBy(res.data.groups, ['id', 'asc']);
        localStorage.setItem('navInfo', JSON.stringify(sortData));
        dispatch(navInfoSuccess(sortData));
      })
      .catch(error => dispatch(navInfoFailure(error.response.data)));
  };
}
