import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./Skeleton.module.css";

class Skeleton extends Component {
  render() {
    const { customClass } = this.props;
    const skeletonStyles = cx(customClass, styles.skeleton);
    return (
      <div
        ref={skeleton => {
          this.skeleton = skeleton;
        }}
        className={skeletonStyles}
      />
    );
  }
}

Skeleton.propTypes = {
  customClass: PropTypes.string
};

export default Skeleton;
