import * as types from "../Constants/commonConstants";
// import * as utils from "./utils";

const initialState = {
    blockList: {},
    blockSelected: {},
    loading: false,
    error: null
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case types.BLOCKS_FETCH:
            return { ...state, loading: true, error: null };
        case types.BLOCKS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                blockList: action.payload.blockList
            };
        case types.BLOCKS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message,
                blockList: {}
            };
        case types.SET_BLOCK:
            return {
                ...state,
                loading: false,
                error: null,
                blockSelected: action.payload.blockSelected
            };
        default:
            return state;
    }
};
