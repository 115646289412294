/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import styles from './sort.module.css';

class Sort extends Component {
  //   constructor(props) {
  //     super(props);
  //   }

  render() {
    const { value, handlers, children, sortValue } = this.props;
    return (
      <div className={styles.sort}>
        <RadioGroup name="sort" value={sortValue} onChange={handlers.sortChange}>
          {React.Children.map(children, child => (
            <FormControlLabel
              className={styles.sortValues}
              value={child.props.children}
              control={<Radio />}
              label={`Sort - ${child.props.label}`}
            />
          ))}
        </RadioGroup>
      </div>
    );
  }
}

Sort.defaultProps = {
  type: 'Sort',
};

export default Sort;
