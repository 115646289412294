export function filterByKeys(data, allowed) {
    return Object.keys(data)
        .filter(key => allowed.includes(key))
        .reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
        }, {});
}

export function arraySearch(arr, query) {
    return arr.filter(
        elm =>
            // return elm.substr(0, query.length).toUpperCase() === key.toUpperCase(); //autocomplete
            elm.toUpperCase().search(query.toUpperCase()) > -1 // pattern match
    );
}

export function removeArrayElement(arr, elm) {
    const i = arr.indexOf(elm);
    if (i > -1) {
        arr.splice(i, 1);
    }
    return arr;
}

export function arrayUnique(array) {
    const a = array.concat();
    for (let i = 0; i < a.length; ++i) {
        for (let j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j]) a.splice(j--, 1);
        }
    }
    return a;
}

export function getCurrentActivityData(data, filter) {
    const obj = {};
    Object.keys(data).map((key, index) => {
        obj[key] = filterByKeys(data[key], filter);
    });
    return obj;
}

export function activityGroupedUnitSearch(data, query) {
    const obj = {};
    Object.keys(data).map((key, index) => {
        obj[key] = arraySearch(data[key], query);
    });
    return obj;
}

export function selectAllActivityUnits(data, activity, grouping) {
    const returnArr = [];
    grouping
        ? Object.keys(data).map(o => {
            data[o][activity] &&
                Object.keys(data[o][activity]).map(e => {
                    data[o][activity][e].status === "Inactive"
                        ? returnArr.push(data[o][activity][e].id)
                        : null;
                });
        })
        : data[activity] &&
        Object.keys(data[activity]).map(e => {
            data[activity][e].status === "Inactive"
                ? returnArr.push(data[activity][e].id)
                : null;
        });
    return returnArr;
}

function getSortingMatrix(context, obj) {
    //   if (!(Object.keys(context).includes('activities') && Object.keys(context).includes('data') && Object.keys(context).includes('unit')))
    //   throw new Error("Wrong keys provided");
    //   const { activities, data, unit } = context;
    //   const commonActivitiesMapper = activities
    //         .filter(act => (!!data[act]))
    //         .filter(act => (data[act][unit] && obj[data[act][unit]['id'].match(regEx)] ? obj[data[act][unit]['id'][0]] : false))
    //         .map(act => ({ [act]: obj[data[act][unit]['id'].match(regEx)[0]] }));
    //   const commonActivitiesPrecedence = commonActivitiesMapper.length > 0 ?
    //         commonActivitiesMapper.reduce((acc, actObj) => (Object.assign(acc, actObj))) : {};
    //   const xActivityPrecedence = Object.assign(commonActivitiesPrecedence, activities.filter(act => !Object.keys(commonActivitiesPrecedence).includes(act)).map(act => ({ [act]: defaultPrecedence })).reduce((acc, actObj) => (Object.assign(acc, actObj))));
    if (!Array.isArray(context))
        throw new Error("Must be an array");
    const defaultPrecedence = 100;
    if (context.length === 0)
        return null;
    const commonActivitiesMapper = obj
        .filter(elt => (!!context.includes(elt['activity_name'])))
        .map(elt => ({ [elt.activity_name]: elt.precedence }));
    const commonActivitiesPrecedence = commonActivitiesMapper.length > 0 ?
        commonActivitiesMapper.reduce((acc, actObj) => (Object.assign(acc, actObj)), {}) : {};
    const xActivityPrecedence = Object.assign(commonActivitiesPrecedence, context.filter(act => (!Object.keys(commonActivitiesPrecedence).includes(act))).map(act => ({ [act]: defaultPrecedence })).reduce((acc, actObj) => (Object.assign(acc, actObj)), {}));
    return xActivityPrecedence;
}

export function getSortingPredicate(objArray) {
    // Create function that takes an object, matches keys of the activities of the
    // current unit type, and returns a predicate function for sorting based on the
    // precedence given
    // Sample precedence matrix
    //     {"WF_BW": 1, "WF_DW": 1, "WF_FF": 1, "WF_GP": 1, "WF_LW": 1,
    //      "WF_TM": 1, "WF_IPS": 1, "WF_MEP": 1, "WF_MSR": 1, "WF_GPFC": 3,
    //     "WF_SHAFTP": 3, "WF_SHAFTW": 3, "WF_Stage1": 0, "WF_Stage3": 4,
    //     "WF_TIDADO": 3, "WF_stage2": 2, "WF_BALCONY": 3, "WF_CERAMIC": 3,
    //     "WF_WALLPUT": 3, "WF01_WProof": 1, "WF_VITRITIL": 3,
    //     "WF_GRANITE_T": 3, "WF_CEILINGPUT": 3}
    const obj = objArray || [{}];
    return activities => {
        const xActivityPrecedence = getSortingMatrix(activities, obj);
        if (!!!xActivityPrecedence)
            return undefined;
        return (a, b) => {
            if (xActivityPrecedence[a] === xActivityPrecedence[b])
                return a.localeCompare(b);
            if (xActivityPrecedence[a] < xActivityPrecedence[b])
                return -1;
            return 1;
        };
    };
}
