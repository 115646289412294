import React, { Component, PropTypes } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import { toast } from "react-toastify";
import styles from "./Logout.module.css";
import { logout } from "../../../actions/Auth";
import { notify } from "../../../actions/Notify";

class Logout extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    localStorage.clear();
    // toast.success("Logged out Successfully! ");
    dispatch(logout());
  }

  render() {
    return <div>Logged out! Have a nice day!</div>;
  }
}

export default connect(
  null,
  null
)(Logout);
