import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import 'whatwg-fetch';
import moment from 'moment-timezone';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import Lightbox from 'react-image-lightbox';
import {
  Button,
  Icon,
  Table,
  Grid,
  List,
  Divider,
  Image,
  Loader,
  Breadcrumb,
  Header,
} from 'semantic-ui-react';
import config from '../../configs/index';

import { downloadExcel, downloadPdf } from '../../actions';
import { snagInfoGet } from '../../actions';

class SnagInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snagInfo: [],
      snagInfoProjectDetails: [],
      errors: {},
      isLoading: true,
      hasErrored: false,
      photoIndex: 0,
      isOpen: false,
      image: '',
    };
  }

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props
      .snagInfoGet(this.props.match.params, this.props.location.search)
      .then(
        res => {
          // console.log(res);
        },
        err => this.setState({ errors: err.response, hasErrored: true })
      );
  }

  downloadExcel(snagInfo, customer_id) {
    const filterData = {
      snagInfo: snagInfo.map(
        ({
          step_name,
          work_flow_node,
          raised_by_username,
          sync_ut,
          room_name,
          category_title,
          inspection_item_title,
          status,
        }) => ({
          work_flow_node,
          raised_by_username,
          sync_ut: moment(sync_ut)
            .tz('Asia/Calcutta')
            .format('DD-MM-YYYY'),
          room_name,
          category_title,
          inspection_item_title,
          status,
        })
      ),
    };
    const data = filterData.snagInfo;
    for (let i = 0; i < data.length; i++) {
      data[i].Step = data[i].work_flow_node;
      data[i].Raised_By = data[i].raised_by_username;
      data[i].Date_Updated = data[i].sync_ut;
      data[i].Area = data[i].room_name;
      data[i].Category = data[i].category_title;
      data[i].Description = data[i].inspection_item_title;
      data[i].Detail = data[i].description;
      data[i].Status = data[i].status;
      delete data[i].work_flow_node;
      delete data[i].raised_by_username;
      delete data[i].sync_ut;
      delete data[i].room_name;
      delete data[i].category_title;
      delete data[i].inspection_item_title;
      delete data[i].description;
      delete data[i].status;
    }
    const requestData = {
      wSheetData: data,
      wSheetName: customer_id,
    };
    const downloadLink = document.getElementById('xlsx');
    downloadLink.className = 'ui right floated loading disabled button medium';
    downloadLink.innerHTML = 'Loading';
    this.props.downloadExcel(requestData).then(
      res => {
        const a = document.createElement('a');
        a.href = res.reXportPath; // Set the file name.
        a.innerHTML = 'Download xlsx file';
        a.download = 'true';
        a.className = 'ui right floated button positive';
        document.body.appendChild(a);
        const downloadLink = document.getElementById('xlsx');
        downloadLink.className = 'ui right floated button positive';
        downloadLink.innerHTML = '';
        downloadLink.replaceWith(a);
      },
      err => this.setState({ errors: err.response })
    );
  }

  downloadPdf(pageURL, authInfo) {
    const requestData = {
      pageURL,
      authInfo,
    };
    const downloadLink = document.getElementById('pdf');
    downloadLink.className = 'ui right floated loading disabled button medium';
    downloadLink.innerHTML = 'Loading';
    this.props.downloadPdf(requestData).then(
      res => {
        const a = document.createElement('a');
        a.href = res.reXportPath; // Set the file name.
        a.innerHTML = 'Download pdf file';
        a.download = 'true';
        a.className = 'ui right floated button positive';
        document.body.appendChild(a);
        const downloadLink = document.getElementById('pdf');
        downloadLink.className = 'ui right floated button positive';
        downloadLink.innerHTML = '';
        downloadLink.replaceWith(a);
      },
      err => this.setState({ errors: err.response })
    );
  }

  setImage(photo) {
    this.setState({ isOpen: true });
    this.setState({
      image: `https://s3.ap-south-1.amazonaws.com/com.fcb.ho.stag/brigade/${photo}`,
    });
  }

  render() {
    const {
      profile,
      snagInfo,
      snagInfoProjectDetails,
      hasErrored,
      isLoading,
      errors,
      projectSelected,
    } = this.props;
    const { image } = this.state;
    const customer_id = profile.customer_id;
    const pageURL = `${config.SiteUrl}${this.props.location.pathname}`;
    const authInfo = localStorage.getItem('jwtToken');
    // console.log(this.props);
    if (isLoading) {
      return (
        <div>
          <Loader active indeterminate className="centered">
            Updating Info...
          </Loader>
        </div>
      );
    }

    if (_.isEmpty(snagInfo) && !isLoading) {
      return (
        <Grid columns="16" padded>
          <Grid.Row>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Step Name</Table.HeaderCell>
                  <Table.HeaderCell>Raised By</Table.HeaderCell>
                  <Table.HeaderCell>Date Raised</Table.HeaderCell>
                  <Table.HeaderCell>Area</Table.HeaderCell>
                  <Table.HeaderCell>Category</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell>Detail</Table.HeaderCell>
                  <Table.HeaderCell>Image</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <div>
                <p className="noDataFound">No Data Found!</p>
              </div>
            </Table>
          </Grid.Row>
        </Grid>
      );
    }

    if (hasErrored) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again
            {errors}
          </div>
        </div>
      );
    }

    let lightbox;
    if (this.state.isOpen) {
      lightbox = (
        <Lightbox
          mainSrc={image}
          onCloseRequest={() => this.setState({ isOpen: false })}
        />
      );
    }

    const paths = this.props.location.pathname.split('/').map((p, i, arr) => {
      p = '';
      if (i === 0)
        return {
          key: i,
          content: <p>{projectSelected.name}</p>,
          active: i === arr.length - 1,
          link: i < arr.length - 1,
        };
      if (i === 1) {
        p = snagInfoProjectDetails.project_name;
      }
      if (i === 2) {
        p = snagInfoProjectDetails.block_name;
      }
      if (i === 3) {
        p = snagInfoProjectDetails.unit_title;
      }
      if (i === 4) {
        p = `${snagInfoProjectDetails.activity_name} > ${
          snagInfoProjectDetails.step_name
        }`;
      }
      if (i === arr.length - 1)
        return {
          key: i,
          content: p,
          active: i === arr.length - 1,
        };
      return {
        key: i,
        content: (
          <Link to={`${arr.slice(0, i + 1).join('/')}`}>
            {p.charAt(0).toUpperCase() + p.slice(1)}
          </Link>
        ),
        active: i === arr.length - 1,
        link: i < arr.length - 1,
      };
    });
    return (
      <div>
        {lightbox}
        <List size="medium" className="printCard">
          <List.Item>
            <h2 className="header">{snagInfoProjectDetails.project_name}</h2>
          </List.Item>
          <Divider />
          <List.Item>
            <h2 className="sub-header">Checklist Report</h2>
          </List.Item>
          <br />
          <Grid>
            <Grid.Column floated="left" width={7}>
              <h3>
                <b>Activity - {snagInfoProjectDetails.activity_name}</b>
              </h3>
              <List.Item>
                <h3>
                  <b>Step - {snagInfoProjectDetails.step_name}</b>
                </h3>
              </List.Item>
            </Grid.Column>
            <Grid.Column floated="right" width={3}>
              <List.Item>
                <h3>
                  <b>Block - {snagInfoProjectDetails.block_name}</b>
                </h3>
              </List.Item>
              <br />
              <List.Item>
                <h3>
                  <b>Unit - {snagInfoProjectDetails.unit_title}</b>
                </h3>
              </List.Item>
            </Grid.Column>
          </Grid>
        </List>
        <Divider hidden />
        <Breadcrumb icon="chevron right" sections={paths} size="big" />
        <Header as="h2" className="info">
          Checklist Report
        </Header>
        <Divider className="print-hide" />

        <Button
          type="submit"
          id="details"
          onClick={() => this.props.history.push('details')}
        >
          View Details
        </Button>
        <Button
          floated="right"
          type="submit"
          id="xlsx"
          onClick={() => this.downloadExcel(snagInfo, customer_id)}
        >
          {' '}
          <Icon disabled name="file excel outline" />
          Export As xlsx
        </Button>
        <Button
          floated="right"
          type="submit"
          id="pdf"
          onClick={() => this.downloadPdf(pageURL, authInfo)}
        >
          <Icon disabled name="file pdf outline" />
          Export As PDF
        </Button>
        <Divider hidden />
        <Grid columns="16" fluid>
          <Grid.Row>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Step Name</Table.HeaderCell>
                  <Table.HeaderCell>Raised By</Table.HeaderCell>
                  <Table.HeaderCell>Last Updated</Table.HeaderCell>
                  <Table.HeaderCell>Area</Table.HeaderCell>
                  <Table.HeaderCell>Category</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell>Detail</Table.HeaderCell>
                  <Table.HeaderCell>Image</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {snagInfo.map(function(snag, index) {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>
                        <p>{snagInfoProjectDetails.step_name}</p>
                      </Table.Cell>
                      <Table.Cell>{snag.raised_by_username}</Table.Cell>
                      <Table.Cell>
                        {moment(snag.sync_ut)
                          .tz('Asia/Calcutta')
                          .format('DD-MM-YYYY')}
                      </Table.Cell>
                      <Table.Cell>{snag.room_name}</Table.Cell>
                      <Table.Cell>{snag.category_title}</Table.Cell>
                      <Table.Cell>{snag.status.toUpperCase()}</Table.Cell>
                      <Table.Cell>{snag.inspection_item_title}</Table.Cell>
                      <Table.Cell>{snag.description}</Table.Cell>
                      <Table.Cell>
                        {snag.attachment ? (
                          <Image
                            src={`https://s3.ap-south-1.amazonaws.com/com.fcb.ho.stag/brigade/${
                              snag.attachment
                            }`}
                            size="medium"
                            onClick={() => this.setImage(snag.attachment)}
                          />
                        ) : (
                          <Icon disabled name="image" />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                }, this)}
              </Table.Body>
            </Table>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  snagInfo: state.snagInfo.snagInfo,
  snagInfoProjectDetails: state.snagInfoProjectDetails.snagInfoProjectDetails,
  projectSelected: state.project.projectSelected,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      snagInfoGet,
      downloadExcel,
      downloadPdf,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SnagInfo)
);
