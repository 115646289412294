import { DASHBOARD_INFO_SET } from "../constants";

const initialState = {
  dashboardInfo: {}
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case DASHBOARD_INFO_SET:
      return {
        dashboardInfo: action.dashboardInfo
      };
    default:
      return state;
  }
};
