import { Dialog } from '@falconbrick/fbt-web-components';
import React, { Component } from 'react';

export default function (opts) {
  const { data, fullscreen, callback, type } = opts;
  if (type === 'reset')
    return class extends Component {
      render() {
        return (
          <Dialog
            title="Data Reset Alert"
            textContent={`Changing the selection from ${data.from.toUpperCase()} to ${data.to.toUpperCase()} will reset all selections made.`}
            fullScreen={fullscreen}
          >
            <div>
              <button style={{ background: "#2f3e6c" }}
                onClick={e => { callback() }}
              >
                Continue
              </button>
            </div>
            <div>
              <a toggle={true}>Close</a>
            </div>
          </Dialog >
        );
      }
    }
  else
    return class extends Component {
      render() {
        return (<div>Not implemented yet</div>);
      }
    }
}
