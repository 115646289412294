import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./StatusBlock.module.css";
import * as activityActions from "../../../../../../Actions/ActivityManagerActions";
import cx from "classnames";
import { PortalCoreIcon as Icon } from "@falconbrick/fbt-web-components";

class StatusBlock extends Component {
  constructor() {
    super();
    this.state = {};
    this.statusIconMap = {
      complete: "COMPLETED",
      Active: "ONGOING",
      Inactive: "NOT_STARTED",
      NotConfigured: "NOT_CONFIGURED"
    };
    this.selectedStatusIconMap = {
      complete: "COMPLETED_SELECTED",
      Active: "ONGOING_SELECTED",
      Inactive: "NOT_STARTED_SELECTED"
    };
    this.hoverHandler = this.hoverHandler.bind(this);
    this.leaveHandler = this.leaveHandler.bind(this);
  }

  toggleSelect() {
    const { status, id, activity, dispatch } = this.props;
    if (status === "Inactive") {
      dispatch(activityActions.selectUnitActivity(id, activity));
    }
  }
  hoverHandler(e) {
    e.preventDefault();
    const { status } = this.props;
    if (status === "Inactive") this.setState.bind(this)({ hover: true });
    else this.setState.bind(this)({ hover: false });
  }
  leaveHandler(e) {
    e.preventDefault();
    const { status } = this.props;
    const { hover } = this.state;
    this.setState.bind(this)({ hover: false });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.status !== this.props.status) {
    }
  }

  render() {
    const { status, selected } = this.props;
    let { hover } = this.state;
    let hoverIcon = hover ? "NOT_STARTED_SELECTED" : false;
    // hoverIcon needs to show not started not selected icon during unselect
    const statusStyle = (status => {
      if (!status) return styles.empty;
      else if (status === "Inactive") return styles.notstarted;
      return styles.notempty;
    })(status);
    const selectedStyle = selected ? styles.selected : "";
    return (
      <div
        onClick={() => this.toggleSelect()}
        onMouseOver={e => this.hoverHandler(e)}
        onMouseLeave={e => this.leaveHandler(e)}
        className={cx(styles.statusBlock, statusStyle, selectedStyle)}
      >
        {status ? (
          <Icon
            name={
              selected
                ? this.selectedStatusIconMap[status]
                : hoverIcon || this.statusIconMap[status]
            }
            customClassName={styles.iconStyle}
          />
        ) : (
          <Icon
            name={this.statusIconMap["NotConfigured"]}
            customClassName={styles.iconStyle}
          />
        )}
      </div>
    );
  }
}

export default connect()(StatusBlock);
