import React, { Component, PropTypes } from "react";
import { Link, withRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import "react-toastify/dist/ReactToastify.css";
import styles from "./LandingLayout.module.css";
import * as Layouts from "../../Layouts";

class LandingLayout extends React.Component {
  render() {
    const { Portal, Settings } = Layouts.default;
    const Layout = this.props.isAuthenticated ? <Portal /> : <Settings />;
    return Layout;
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.authManager.isAuthenticated
});

export default withRouter(connect(mapStateToProps)(LandingLayout));
