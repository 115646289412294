import React, { Component } from "react";
import styles from "./NoResultsPlot.module.css";

export default class NoResultsPlot extends Component {
  render() {
    const { noResult } = this.props;
    return (
      <div className={styles.noresults}>
        <div className={styles.noresultsMessage}>No Results Found</div>
        <div className={styles.noresultsInfo}>We were not able to find a match for the searched term in the {noResult} section.</div>
      </div>
    );
  }
}
