import { SET_TOWER_DETAIL_INFO } from "../constants";

const initialState = {
  towerInfoDetail: {}
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_TOWER_DETAIL_INFO:
      return {
        towerInfoDetail: action.towerInfoDetail
      };
    default:
      return state;
  }
};
