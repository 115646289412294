import { UNIT_INFO_ALL_STEPS } from '../constants';

const initialState = {
    unitInfoAllSteps: {}
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case UNIT_INFO_ALL_STEPS:
            return {
                unitInfoAllSteps: action.unitInfoAllSteps
            };
        default:
            return state;
    }
}