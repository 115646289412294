import { SET_SNAG_INFO } from '../constants';

const initialState = {
    snagInfo: {}
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_SNAG_INFO:
            return {
                snagInfo: action.snagInfo
            };
        default:
            return state;
    }
}