import React, { Component } from "react";
import styles from "./PaginationToolbar.module.css";

import { PortalCoreIcon as Icon } from "@falconbrick/fbt-web-components";

class PaginationToolbar extends Component {
  constructor() {
    super();
    this.state = {};
  }

  jumpFirst() {
    this.props.jumpFirst();
  }

  jumpLast() {
    this.props.jumpLast();
  }

  next() {
    this.props.next();
  }

  prev() {
    this.props.prev();
  }

  pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  render() {
    const { currentPage, totalPages, totalActivities, totalUnits } = this.props;

    return (
      <div className={styles.paginationToolbar}>
        <div className={styles.leftDisplay}>
          Total number of units: {totalUnits}
        </div>
        <div className={styles.rightDisplay}>
          <div className={styles.leftControl}>
            <div className={styles.iconClass} onClick={() => this.jumpFirst()}>
              <Icon
                name={
                  currentPage > 1 ? "PAGE_START_ACTIVE" : "PAGE_START_INACTIVE"
                }
              />
            </div>
            <div className={styles.iconClass} onClick={() => this.prev()}>
              <Icon
                name={currentPage > 1 ? "PREV_PAGE_ACTIVE" : "PREV_PAGE_INACTIVE"}
              />
            </div>
          </div>
          <div className={styles.centerDisplay}>
            Page [ {this.pad(currentPage)}/{this.pad(totalPages)} ]  | Total number of activities: {totalActivities}
          </div>
          <div className={styles.rightControl}>
            <div className={styles.iconClassReverse} onClick={() => this.next()}>
              <Icon
                name={
                  currentPage < totalPages ? "PREV_PAGE_ACTIVE" : "PREV_PAGE_INACTIVE"
                }
              />
            </div>
            <div
              className={styles.iconClassReverse}
              onClick={() => this.jumpLast()}
            >
              <Icon
                name={
                  currentPage < totalPages ? "PAGE_START_ACTIVE" : "PAGE_START_INACTIVE"
                }
              />
            </div>
          </div>
        </div >
      </div >
    );
  }
}

export default PaginationToolbar;
