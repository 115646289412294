import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { some, isEmpty } from 'lodash';

export default function(ComposedComponent) {
  class Selected extends React.Component {
    componentWillMount() {
      if (isEmpty(this.props.project) || isEmpty(this.props.property)) {
        // this.props.addFlashMessage({
        //   type: "error",
        //   text: "You need to login to access this page"
        // });
        this.context.router.history.push('/project');
        console.log('select atleast one');
      }
    }

    componentWillUpdate(nextProps) {
      if (isEmpty(nextProps.project) || isEmpty(nextProps.property)) {
        this.context.router.history.push('/project');
        console.log('select atleast one');
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  Selected.contextTypes = {
    router: PropTypes.object.isRequired,
  };

  function mapStateToProps(state) {
    return {
      isAuthenticated: state.authManager.isAuthenticated,
      project: state.project.projectSelected,
      property: state.property.propertySelected,
    };
  }

  return connect(mapStateToProps)(Selected);
}
