/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import cx from 'classnames';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import SelectionBox from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

// core components
import styles from './SelectionBoxStyles.module.css';

class CustomSelectionBox extends Component {
  static defaultProps = {
    value: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { placeholder } = this.props;
    if (placeholder) {
      // console.log(this.props.placeholder);
      this.setState({ selected: placeholder });
    }
  }

  handleChange = event => {
    const { selected } = this.state;
    selected !== event.target.value ? this.props.callBack(event.target.value) : null;
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      labelText,
      id,
      value,
      options,
      optionId,
      optionValue,
      name,
      onChange,
      labelProps,
      LabelTextCustomClasses,
      SelectionBoxCustomClasses,
      formControlClasses,
      callBack,
      placeholder,
    } = this.props;
    const { selected } = this.state;
    const selectionBoxStyle = SelectionBoxCustomClasses
      ? cx(styles.selectionBox, SelectionBoxCustomClasses)
      : cx(styles.selectionBox);
    const labelTextStyle = LabelTextCustomClasses ? cx(styles.labelText, LabelTextCustomClasses) : cx(styles.labelText);

    return (
      <div className={styles.selectionBox}>
        <FormControl className={styles.formControl}>
          {labelText !== undefined ? (
            <InputLabel className={labelTextStyle} htmlFor={id}>
              {labelText}
            </InputLabel>
          ) : null}
          <SelectionBox
            className={selectionBoxStyle}
            value={value}
            name="selected"
            onChange={this.handleChange}
            id={id}
          >
            {selected === ' ' && (
              <MenuItem value=" " className={styles.disable}>
                <span className={styles.disable}>{placeholder}</span>
              </MenuItem>
            )}

            {options.length > 0 ? (
              options.map(option => (
                <MenuItem value={option[optionId]} key={option[optionId]}>
                  {option[optionValue]}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="loading">Loading...</MenuItem>
            )}
          </SelectionBox>
        </FormControl>
      </div>
    );
  }
}

// CustomSelectionBox.propTypes = {
//   labelText: PropTypes.string,

//   id: PropTypes.string,
//   optionId: PropTypes.isRequired,
//   onChange: PropTypes.func.isRequired,
// };

export default withStyles(styles)(CustomSelectionBox);
