import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { Grid, Loader, Container, Table } from "semantic-ui-react";

class ByTower extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      isLoading: true,
      hasErrored: false
    };
  }

  byUnitLink(stage, blockId) {
    // localStorage.setItem("unitSelectedId", data.step_name);
    this.props.history.push(`/reports/checklistRejected/${stage}/${blockId}`);
  }

  render() {
    const {
      towerInfo,
      isLoading,
      hasErrored,
      errors,
      propertySelected
    } = this.props;

    if (isLoading) {
      return (
        <Container textAlign="center">
          <Loader active indeterminate className="centered">
            Getting info...
          </Loader>
        </Container>
      );
    }

    if (_.isEmpty(towerInfo) && !isLoading) {
      return (
        <Container fluid textAlign="center">
          <p>No Changes found</p>
        </Container>
      );
    }

    if (hasErrored) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again
            {errors}
          </div>
        </div>
      );
    }

    const dataGrid = [];
    const activityGrp_dup = _
      .chain(towerInfo)
      .sortBy("checklist_id")
      .groupBy("checklist_id")
      .value();
    let activityGrp, blockGrp;

    if (!_.isEmpty(towerInfo) && !isLoading) {
      // activityGrp = _.groupBy(towerInfo, "checklist_id");
      // blockGrp = _.groupBy(towerInfo, "tower");

      activityGrp = _
        .chain(towerInfo)
        .sortBy("checklist_id")
        .groupBy("checklist_id")
        .value();

      blockGrp = _
        .chain(towerInfo)
        .sortBy("tower")
        .groupBy("tower")
        .value();

      for (let i = 0; i < Object.keys(blockGrp).length; i++) {
        dataGrid[i] = [];
      }

      Object.keys(activityGrp).map((item, index) => {
        for (let y = 0; y < Object.keys(blockGrp).length; y++) {
          activityGrp_dup[item][y] = [];
        }
        return true;
      });

      Object.keys(activityGrp).map((item, index) => {
        let index_p;
        const index_full = Object.keys(blockGrp);
        // activityGrp_dup[item] = activityGrp[item];
        for (let i = 0; i < Object.keys(blockGrp).length; i++) {
          if (activityGrp[item][i] !== undefined) {
            index_p = index_full.indexOf(activityGrp[item][i].tower);
            activityGrp_dup[item][index_p] = activityGrp[item][i];
            if (
              activityGrp_dup[item][index_p].tower ===
                activityGrp[item][i].tower &&
              i !== index_p
            ) {
              activityGrp_dup[item][i] = [];
            }
          } else if (activityGrp_dup[item][i] === []) {
            activityGrp_dup[item][i] = [];
          }
        }
        return true;
      });

      Object.keys(activityGrp_dup).map((item, index) => {
        dataGrid[index] = activityGrp_dup[item].map((values, valIndex) => {
          if (values.length === 0) {
            return "0";
          }
          return values.count;
        });
        return true;
      });
    }

    return (
      <div>
        <Grid columns="equal" padded>
          <Table celled>
            <Table.Header>
              <Table.HeaderCell>Activity/Tower</Table.HeaderCell>
              {Object.keys(blockGrp).map((key, index) => (
                <Table.HeaderCell>{key}</Table.HeaderCell>
              ))}
            </Table.Header>
            <Table.Body>
              {Object.keys(activityGrp_dup).map(function(key, index) {
                return (
                  <Table.Row>
                    <Table.Cell>
                      {Object.keys(
                        _.groupBy(activityGrp_dup[key], "checklist")
                      )[0] === "undefined"
                        ? Object.keys(
                            _.groupBy(activityGrp_dup[key], "checklist")
                          )[1]
                        : Object.keys(
                            _.groupBy(activityGrp_dup[key], "checklist")
                          )[0]}
                      - {"  "}
                      <span className="liteText"> {key}</span>
                    </Table.Cell>
                    {dataGrid[index].map(function(value, val_index) {
                      return (
                        <Table.Cell>
                          {value === "0" ? (
                            <p className="liteText"> 0</p>
                          ) : (
                            <p
                              className="asLink"
                              as="a"
                              type="submit"
                              size="tiny"
                              onClick={() =>
                                this.byUnitLink(
                                  activityGrp_dup[key][val_index].checklist_id,
                                  activityGrp_dup[key][val_index].block_id
                                )
                              }
                            >
                              {value}
                            </p>
                          )}
                        </Table.Cell>
                      );
                    }, this)}
                  </Table.Row>
                );
              }, this)}
            </Table.Body>
          </Table>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  towerInfo: state.towerInfo.towerInfo,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
  propertySelected: state.property.propertySelected
});

export default withRouter(connect(mapStateToProps)(ByTower));
