// add 2 values
export function add(num1, num2) {
  return num1 + num2;
}
// get url params
export function getParameterByName(name, url) {
  const urlTemp = url;
  if (!urlTemp) url = window.location.href;
  name = name.replace(/[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

// remove all localstorage related to reports
export function cleanReportsLocal() {
  localStorage.removeItem('blockSelectedId');
  localStorage.removeItem('blockSelectedName');
  localStorage.removeItem('blockSelectedIndex');
  localStorage.removeItem('unitSelectedId');
  localStorage.removeItem('unitSelectedName');
  localStorage.removeItem('unitSelectedAction');
  localStorage.removeItem('actMgrBlockSelectedId');
  localStorage.removeItem('actMgrBlockSelectedName');
}
