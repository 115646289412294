import React, { Component, PropTypes } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
// import { ToastContainer } from "react-toastify";
import styles from "./Settings.module.css";
import * as Pages from "../../Pages";

export default class Settings extends Component {
  render() {
    const { Login, Logout, ForgotPassword, NotifierContainer } = Pages.default;
    return (
      <div className={styles.settings}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/logout" component={Logout} />
          <Redirect to="/login" />
        </Switch>
        {/* <ToastContainer /> */}
        <NotifierContainer />
      </div>
    );
  }
}
