import { SET_UNIT_DETAIL_INFO, UPDATE_UNIT_DETAIL_INFO } from '../constants';


const initialState = {
    unitInfoDetail: {}
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_UNIT_DETAIL_INFO:
            return {
                unitInfoDetail: action.unitInfoDetail
            };
        case UPDATE_UNIT_DETAIL_INFO:
            return {
                ...state,
                unitInfoDetail: action.unitInfoDetail
            };
        default:
            return state;
    }
}