import { combineReducers } from 'redux';
import unitInfo from './UnitInfo';
import towerInfo from './TowerInfo';
import unitInfoDetail from './UnitInfoDetail';
import unitDataDetail from './UnitDataDetail';
import towerInfoDetail from './TowerInfoDetail';
import snagInfo from './SnagInfo';
import unitInfoAllSteps from './UnitInfoAllSteps';
import towerSnagInfo from './TowerSnagInfoNew';
import isLoading from './IsLoading';
import hasErrored from './HasErrored';
import snagInfoProjectDetails from './SnagInfoProjectDetails';
import deltaUnitInfo from './DeltaUnitInfo';
import deltaActivityInfo from './DeltaActivityInfo';
import dashboardInfo from './DashboardInfo';
import unitActivityInfo from './UnitActivityInfo';
import blockInfoReports from './BlockInfo';
import unitInfoStage from './StageData';
import unitDataDetailStage from './StageInfo';


export default {
  unitInfo,
  towerInfo,
  unitInfoDetail,
  unitDataDetail,
  towerInfoDetail,
  snagInfo,
  unitInfoAllSteps,
  towerSnagInfo,
  isLoading,
  hasErrored,
  snagInfoProjectDetails,
  deltaUnitInfo,
  deltaActivityInfo,
  dashboardInfo,
  unitActivityInfo,
  blockInfoReports,
  unitDataDetailStage,
  unitInfoStage,
};
