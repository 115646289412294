import isEmpty from 'lodash/isEmpty';
import * as types from '../constants/Project';

const initialState = {
  projectSelected: {},
  projectList: {},
  loading: false,
  error: null
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case types.PROJECT_INFO_FETCH:
      return { ...state, loading: true, error: null };
    case types.PROJECT_INFO_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case types.PROJECT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        projectList: action.payload.projectList
      };
    case types.PROJECT_SET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        projectSelected: action.payload.projectSelected
      };
    default:
      return state;
  }
};
