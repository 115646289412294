import React, { Component } from "react";
import { connect } from "react-redux";
import { changePassword } from "../../../actions/Auth";
//To be changed with MaterialUI
import {
  Button,
  Segment,
  Divider,
  Loader,
  Container,
  Form
} from "semantic-ui-react";
import TextFieldGroup from "../common/TextFieldGroup";
import { bindActionCreators } from "redux";
import Validator from "validator";
import isEmpty from "lodash/isEmpty";
// import { toast } from "react-toastify";

import styles from "./ChangePassword.module.css";

class changePass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perviousPassword: "",
      newPassword: "",
      confirmPassword: "",
      errors: {},
      isLoading: true,
      hasErrored: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ errors: "" });
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.isValid()) {
      this.props.changePassword(this.state, this.props.profile.email).then(
        res => {
          this.setState({ success: true, successMsg: `Password has been changed successfully` });
          this.props.history.replace("/", this.state.success);
          // toast.success(`Password has been changed successfully`);
        },
        err =>
          this.setState({
            errors: err.response.data.errors,
            isLoadingLogin: false
          })
      );
    }
  }
  validateInput(data) {
    let errors = {};
    if (!Validator.equals(data.newPassword, data.confirmPassword)) {
      errors.confirmPassword = "Passwords do not match";
    }
    if (Validator.isEmpty(data.confirmPassword)) {
      errors.confirmPassword = "This field is required";
    }
    if (Validator.isEmpty(data.perviousPassword)) {
      errors.perviousPassword = "This field is required";
    }
    if (Validator.isEmpty(data.newPassword)) {
      errors.newPassword = "This field is required";
    }
    return {
      errors,
      isValid: isEmpty(errors)
    };
  }

  isValid() {
    const { errors, isValid } = this.validateInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  }

  render() {
    const { isLoading, hasErrored } = this.props;

    if (isLoading) {
      return (
        <Container
          textAlign="center"
          className={styles.changePassContainer}
          fluid
        >
          <br />
          <Loader active inline="centered" />
        </Container>
      );
    }

    if (hasErrored) {
      return (
        <Container
          textAlign="center"
          className={styles.changePassContainer}
          fluid
        >
          <p />
          <div className="ui active">
            Something went wrong! please try again
                </div>
        </Container>
      );
    }

    const {
      errors,
      success,
      successMsg,
      perviousPassword,
      newPassword,
      confirmPassword
    } = this.state;

    return (
      <Container
        textAlign="center"
        className={styles.changePassContainer}
        fluid
      >
        <Segment raised className={styles.card}>
          <Form onSubmit={this.onSubmit}>
            {success && (
              <div>
                <i />
                <div>{successMsg}</div>
              </div>
            )}
            {errors.form && (
              <div className="alert alert-danger">{errors.form}</div>
            )}
            <Divider hidden />
            <TextFieldGroup
              label="Current Password"
              field="perviousPassword"
              value={perviousPassword}
              error={errors.perviousPassword}
              type="password"
              onChange={this.onChange}
            />
            <Divider hidden />
            <TextFieldGroup
              label="New Password"
              field="newPassword"
              value={newPassword}
              error={errors.newPassword}
              type="password"
              onChange={this.onChange}
            />
            <TextFieldGroup
              label="Confirm Password"
              field="confirmPassword"
              value={confirmPassword}
              error={errors.confirmPassword}
              type="password"
              onChange={this.onChange}
            />
            <Divider hidden />
            <Button className="btn btn-primary btn-lg" disabled={success}>
              Submit
            </Button>
          </Form>
        </Segment>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
  error: state.error
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePassword
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(changePass);
