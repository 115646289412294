import * as notification from '../constants/Notify';

export const closeNotifcation = () => ({
    type: notification.NOTIFY_CLOSE_NOTIFICATION
});

export const setProps = (message, className = 'info') => ({
    type: notification.NOTIFY_SET_PROPS,
    payload: { className: className, messageContent: message }
});

export const notify = (message, className = 'info') => {
    return dispatch => {
        dispatch(setProps(message, className));
    };
}

