import Login from "./Login";
import Logout from "./Logout";
import ChangePassword from "./ChangePassword";
import ForgotPassword from "./ForgotPassword";
import Dashboard from "./Dashboard";
import Reports from "./Reports";
import Programs from "./Programs";
import ProjectSelection from "./ProjectSelection";
import PropertySelection from "./PropertySelection";
import NotifierContainer from "./NotifierContainer/NotifierContainer";

const Pages = {
    Login,
    Logout,
    ChangePassword,
    ForgotPassword,
    Dashboard,
    Reports,
    Programs,
    ProjectSelection,
    PropertySelection,
    NotifierContainer
};

export default Pages;
