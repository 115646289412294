import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { byTowerGet } from "../../actions";
import { bindActionCreators } from "redux";
import _ from "lodash";
import {
  Header,
  Grid,
  Divider,
  Loader,
  Breadcrumb,
  Container,
  Table
} from "semantic-ui-react";

class ByTower extends Component {
  constructor(props) {
    super(props);
    this.state = {
      towerInfo: [],
      errors: {},
      isLoading: true,
      hasErrored: false,
      active: 0
    };
  }

  componentDidMount() {
    this.props.byTowerGet().then(
      res => {
        // console.log(res);
        this.setState.isLoading = false;
      },
      err => this.setState({ errors: err.response, hasErrored: true })
    );
  }

  byUnitLink(stage, blockId) {
    // localStorage.setItem("unitSelectedId", data.step_name);
    this.props.history.push(`/reports/activityCompleted/${stage}/${blockId}`);
  }

  render() {
    const {
      towerInfo,
      isLoading,
      hasErrored,
      errors,
      propertySelected
    } = this.props;
    const paths = this.props.location.pathname.split("/").map((p, i, arr) => {
      if (p === "activityCompleted") {
        p = "Activity Completed";
      }
      if (i === 0)
        return {
          key: i,
          content: <p>{propertySelected.name}</p>,
          active: i === arr.length - 1,
          link: i < arr.length - 1
        };
      if (i === 3)
        return {
          key: i,
          content: "",
          active: i === arr.length - 1,
          link: i < arr.length - 1
        };

      if (i === arr.length - 1)
        return {
          key: i,
          content: p,
          active: i === arr.length - 1
        };
      return {
        key: i,
        content: (
          <Link to={`${arr.slice(0, i + 1).join("/")}`}>
            {p.charAt(0).toUpperCase() + p.slice(1)}
          </Link>
        ),
        active: i === arr.length - 1,
        link: i < arr.length - 1
      };
    });

    if (isLoading) {
      return (
        <Container textAlign="center">
          <Loader active indeterminate className="centered">
            Getting activity info...
          </Loader>
        </Container>
      );
    }

    if (_.isEmpty(towerInfo) && !isLoading) {
      return (
        <Container fluid textAlign="center">
          <p>No Block/s found</p>
        </Container>
      );
    }

    if (hasErrored) {
      return (
        <div className="ui segment">
          <p />
          <div className="ui active">
            Something went wrong! please try again
            {errors}
          </div>
        </div>
      );
    }
    const dataGrid = [[]];
    let activityGrp, blockGrp;
    const activityGrp_dup = _.chain(towerInfo)
      .sortBy("activity")
      .groupBy("activity")
      .value();
    if (!_.isEmpty(towerInfo) && !isLoading) {
      activityGrp = _.chain(towerInfo)
        .sortBy("activity")
        .groupBy("activity")
        .value();

      blockGrp = _.chain(towerInfo)
        .sortBy("block_id")
        .groupBy("block_name")
        .value();

      for (let i = 0; i < Object.keys(blockGrp).length; i++) {
        dataGrid[i] = [];
      }

      Object.keys(activityGrp).map((item, index) => {
        for (let y = 0; y < Object.keys(blockGrp).length; y++) {
          activityGrp_dup[item][y] = [];
        }
        return true;
      });
      Object.keys(activityGrp).map((item, index) => {
        let index_p;
        const index_full = Object.keys(blockGrp);
        // activityGrp_dup[item] = activityGrp[item];
        for (let i = 0; i < Object.keys(blockGrp).length; i++) {
          if (activityGrp[item][i] !== undefined) {
            index_p = index_full.indexOf(activityGrp[item][i].block_name);
            activityGrp_dup[item][index_p] = activityGrp[item][i];
            if (
              activityGrp_dup[item][index_p].block_name ===
                activityGrp[item][i].block_name &&
              i !== index_p &&
              activityGrp_dup[item][i] === []
            ) {
              activityGrp_dup[item][i] = [];
            }
          } else if (activityGrp_dup[item][i] === []) {
            activityGrp_dup[item][i] = [];
          }
        }
        return true;
      });

      Object.keys(activityGrp_dup).map((item, index) => {
        dataGrid[index] = activityGrp_dup[item].map((values, valIndex) => {
          if (values.length === 0) {
            return "0";
          }
          return values.complete;
        });
        return true;
      });
    }

    return (
      <div>
        <Breadcrumb icon="chevron right" sections={paths} size="big" />
        <Header as="h2">Activity Completed </Header>
        <Divider />
        <Grid columns="equal" padded>
          <Table celled>
            <Table.Header>
              <Table.HeaderCell>Activity/Tower</Table.HeaderCell>
              {Object.keys(blockGrp).map((key, index) => (
                <Table.HeaderCell>{key}</Table.HeaderCell>
              ))}
            </Table.Header>
            <Table.Body>
              {Object.keys(activityGrp_dup).map(function(key, index) {
                return (
                  <Table.Row>
                    <Table.Cell>{key}</Table.Cell>
                    {dataGrid[index].map(function(value, val_index) {
                      return (
                        <Table.Cell>
                          {value === "0" ? (
                            <p className="liteText"> 0</p>
                          ) : (
                            <p
                              className="asLink"
                              as="a"
                              type="submit"
                              size="tiny"
                              onClick={() =>
                                this.byUnitLink(
                                  activityGrp_dup[key][val_index].id,
                                  activityGrp_dup[key][val_index].block_id
                                )
                              }
                            >
                              {value}
                            </p>
                          )}
                        </Table.Cell>
                      );
                    }, this)}
                  </Table.Row>
                );
              }, this)}
            </Table.Body>
          </Table>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.authManager.user,
  towerInfo: state.towerInfo.towerInfo,
  isLoading: state.isLoading,
  hasErrored: state.hasErrored,
  propertySelected: state.property.propertySelected
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      byTowerGet
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ByTower)
);
