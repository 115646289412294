export const FIRE_NOTIFICATION = 'FIRE_NOTIFICATION';
export const EXTINGUISH_NOTIFICATION = 'EXTINGUISH_NOTIFICATION';
export const SESSION_COMPLETED = 'SESSION_COMPLETED';
export const INITIATE_ROUTE_CHANGE = 'INITIATE_ROUTE_CHANGE';
export const OPEN_GENERAL_QUERY = 'OPEN_GENERAL_QUERY';
export const SET_CONTEXT_IN_GENERAL_QUERY = 'SET_CONTEXT_IN_GENERAL_QUERY';
export const SET_CONTEXT_LOCALLY = 'SET_CONTEXT_LOCALLY';
export const OPEN_NEW_SESSION = 'OPEN_NEW_SESSION';
export const SET_CURRENT_ISSUE_VISIBILTY = 'SET_CURRENT_ISSUE_VISIBILTY';
export const SET_CURRENT_SESSION = 'SET_CURRENT_SESSION';
export const SELECT_GENERAL_QUERY = 'SELECT_GENERAL_QUERY';
export const INCIDENT_SELECTED_FROM_INBOX = 'INCIDENT_SELECTED_FROM_INBOX';
export const INBOX_FAILED = 'INBOX_FAILED';
export const INBOX_FETCHED = 'INBOX_FETCHED';
export const FETCH_ASSIGNED_SESSIONS_FOR_AGENT = 'FETCH_ASSIGNED_SESSIONS_FOR_AGENT';
export const CLEAR_SELECTED_PARAMS = 'CLEAR_SELECTED_PARAMS';
