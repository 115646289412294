/* Reports Related Types */
// export const FETCH_UNIT_DETAILS = "FETCH_UNIT_DETAILS";
export const ADD_FLASH_MESSAGE = 'ADD_FLASH_MESSAGE';
export const DELETE_FLASH_MESSAGE = 'DELETE_FLASH_MESSAGE';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_PROJECT_INFO = 'SET_PROJECT_INFO';
export const UPDATE_PROJECT_INFO = 'UPDATE_PROJECT_INFO';
export const SET_PROPERTY_INFO = 'SET_PROPERTY_INFO';
export const UPDATE_PROPERTY_INFO = 'UPDATE_PROPERTY_INFO';
export const SET_UNIT_INFO = 'SET_UNIT_INFO';
export const UPDATE_UNIT_INFO = 'UPDATE_UNIT_INFO';
export const SET_UNIT_DETAIL_INFO = 'SET_UNIT_DETAIL_INFO';

export const UPDATE_UNIT_DETAIL_INFO = 'UPDATE_UNIT_DETAIL_INFO';
export const SET_UNIT_DETAIL_DATA = 'SET_UNIT_DETAIL_DATA';
export const UPDATE_UNIT_DETAIL_DATA = 'UPDATE_UNIT_DETAIL_DATA';
export const SET_TOWER_INFO = 'SET_TOWER_INFO';
export const UPDATE_TOWER_INFO = 'UPDATE_TOWER_INFO';
export const SET_TOWER_DETAIL_INFO = 'SET_TOWER_DETAIL_INFO';
export const SET_SNAG_INFO = 'SET_SNAG_INFO';
export const SET_TOWER_SNAG_INFO = 'SET_TOWER_SNAG_INFO';
export const UPDATE_TOWER_SNAG_INFO = 'UPDATE_TOWER_SNAG_INFO';
export const ITEMS_HAS_ERRORED = 'ITEMS_HAS_ERRORED';
export const ITEMS_IS_LOADING = 'ITEMS_IS_LOADING';
// Unit Info
export const UNIT_INFO_ALL_STEPS = 'UNIT_INFO_ALL_STEPS';
export const SNAG_INFO_PROJECT_DETAILS = 'SNAG_INFO_PROJECT_DETAILS';
// Delta Info
export const SET_DELTA_UNIT_INFO = 'SET_DELTA_UNIT_INFO';
export const UPDATE_DELTA_UNIT_INFO = 'UPDATE_DELTA_UNIT_INFO';
export const SET_DELTA_ACTIVITY_INFO = 'SET_DELTA_ACTIVITY_INFO';
// Nav Items
export const NAV_INFO_SET = 'NAV_INFO_SET';
// dashboard info
export const DASHBOARD_INFO_SET = 'DASHBOARD_INFO_SET';
// Activity Manger
export const SET_UNIT_ACTIVITY_INFO = 'SET_UNIT_ACTIVITY_INFO';
export const UPDATE_UNIT_ACTIVITY_INFO = 'UPDATE_UNIT_ACTIVITY_INFO';
// New Tower Snags/exports
export const TOWER_SNAG_EXPORTS_FETCH = 'TOWER_SNAG_EXPORTS_FETCH';
export const TOWER_SNAG_EXPORTS_SUCCESS = 'TOWER_SNAG_EXPORTS_SUCCESS';
export const TOWER_SNAG_EXPORTS_ERROR = 'TOWER_SNAG_EXPORTS_ERROR';
// New Block Info
export const BLOCK_INFO_FETCH = 'BLOCK_INFO_FETCH';
export const BLOCK_INFO_SUCCESS = 'BLOCK_INFO_SUCCESS';
export const BLOCK_INFO_ERROR = 'BLOCK_INFO_ERROR';
export const BLOCK_INFO_SET = 'BLOCK_INFO_SET';
// new unit type
export const UNIT_TYPE_FETCH = 'UNIT_TYPE_FETCH';
export const UNIT_TYPE_SUCCESS = 'UNIT_TYPE_SUCCESS';
export const UNIT_TYPE_ERROR = 'UNIT_TYPE_ERROR';
export const UNIT_TYPE_SET = 'UNIT_TYPE_SET';
// new snag type
export const SNAG_TYPE_FETCH = 'SNAG_TYPE_FETCH';
export const SNAG_TYPE_SUCCESS = 'SNAG_TYPE_SUCCESS';
export const SNAG_TYPE_ERROR = 'SNAG_TYPE_ERROR';
export const SNAG_TYPE_SET = 'SNAG_TYPE_SET';
// stage
export const SET_UNIT_DATA_STAGE = 'SET_UNIT_DATA_STAGE';
export const SET_UNIT_DETAIL_DATA_STAGE = 'SET_UNIT_DETAIL_DATA_STAGE';
